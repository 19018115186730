import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { ToolbarButton } from '../common/toolbar-button';
import { ThemeProvider, useTheme } from '../themes/theme';

export type LayoutCenterLeftProps = {
    visible?: boolean
    withBorder?: boolean
};

export function LayoutCenterLeft(props: LayoutCenterLeftProps) {
    const {
        visible = true,
        withBorder = true,
        ...otherProps
    } = props;

    const theme = useTheme();

    if (visible) {
        return (
            <View style={[
                styles.layoutLeft,
                !!withBorder && styles.layoutLeftBorder,
                { backgroundColor: theme.primaryBackgroundColor }
            ]}>
                <View>
                    <ToolbarButton icon="appstore-o" />
                </View>
            </View>
        )
    } else {
        return (<View></View>);
    }
}

const styles = StyleSheet.create({
    layoutLeft: {
        display: "flex",
        alignItems: "center",
        width: 8 * 6,
        flexDirection: "column",
        backgroundColor: "#FFF",
        marginTop: 1
    },
    layoutLeftBorder: {
        marginRight: (8 / 2)
    },
})