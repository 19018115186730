import React, { useEffect, useState } from 'react';
import { LayoutRectangle, ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { LATEST_DISPATCH_QUERY, parseDate, parseDateCustom, formatDateTime, getDispatchHub, DISPATCH_HUB_LATEST_DISPATCH, DAILY_DISPATCH_QUERY } from '@energynow/core';
import { useQuery } from '../../../hooks/apollo';
import { Visualisation } from '../../common/visualisation';
import { Select } from '../../common/select';
import { Text } from '../../common/text';
import { ErrorLoading } from '../../common/error-loading';
import { useHubToListen } from '../../../hooks/signalr';
import { PERIODS, REGIONS } from '../constants';
import { formatNumber } from '@energynow/core';
import { ThemeProvider, useTheme } from '../../themes/theme';
// @ts-ignore
import DatePicker from '../../common/datepicker';
import { DEVICE_WIDTH } from '../../common/constants';
import { HorizontalScroll } from '../../common/horizontal-scroll';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedDate } from '../../layout/layout-slice';

export function LatestDispatch(props: any) {
  const { loading, error, data, refetch } = useQuery(DAILY_DISPATCH_QUERY, {
    variables: {
      fromDate: null,
      toDate: null
    }
  });
  const latestDispatch = useHubToListen(getDispatchHub(), DISPATCH_HUB_LATEST_DISPATCH);

  const theme = useTheme();
  const globalSelectedDate = useAppSelector(selectSelectedDate);
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('Live');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [timezone, setTimezone] = useState('+10:00');
  const [smallScreen, setSmallScreen] = useState(false);
  const { width, height } = useWindowDimensions();
  const periods = [
    { label: 'Live' }
  ];

  useEffect(() => {
    if (selectedPeriod === 'Live') {
      refetch({
        fromDate: null,
        toDate: null
      });
    }
  }, [latestDispatch, selectedPeriod]);

  useEffect(() => {
    if (globalSelectedDate) {
      onDatePicked(globalSelectedDate);
    } else if (globalSelectedDate === null) {
      setSelectedPeriod('Live');
    }
  }, [globalSelectedDate]);

  const onDatePicked = (date: string) => {
    let newDate: Date;
    if (timezone != '') {
      newDate = parseDateCustom(`${date} ${timezone}`, 'yyyy/MM/dd HH:mm XXX');
    } else {
      newDate = parseDateCustom(date, 'yyyy/MM/dd HH:mm');
    }

    refetch({
      fromDate: newDate as any,
      toDate: newDate as any
    });

    setSelectedDate(newDate);
    setSelectedPeriod('');
  };



  useEffect(() => {
    setSmallScreen(width <= DEVICE_WIDTH.sm);
  }, [width]);

  if (!!error) {
    return <ErrorLoading onPressReload={() => { refetch() }} />
  }

  const regionRender = (region: any, index: number) => {
    const dispatchPrice = data?.dispatchKeys[data.dispatchKeys.length - 1]?.dispatchPrice.find((x: any) => x.regionId === region.value);
    const dispatchRegionSummary = data?.dispatchKeys[data.dispatchKeys.length - 1]?.dispatchRegionSum.find((x: any) => x.regionId === region.value);
    const lastRegion = REGIONS.length === index + 1;
    return (
      <View
        key={region.value}
        style={[
          styles.regionContainer,
          { borderColor: theme.primaryBorderColor },
          !smallScreen && !lastRegion && { borderRightWidth: 2 },
          !lastRegion && smallScreen && { borderBottomWidth: 2 },
        ]}
      >
        <View style={styles.regionContainerLeft}>
          <View style={styles.regionContainerTopLeft}>
            <Text style={[styles.regionName, { color: theme.primaryColor }]}>{region.label}</Text>
          </View>

        </View>
        <View style={styles.regionContainerCenter}>
          <View style={styles.regionContainerBottomLeft}>
            <View style={styles.smallValueContainer}>
              <Text style={[styles.smallLabel, { color: theme.primaryColor }]}>Price</Text>
              <Text style={[styles.smallValue, { color: theme.primaryColor }]}>${formatNumber(dispatchPrice?.price)}</Text>
            </View>
            <View style={styles.smallValueContainer}>
              <Text style={[styles.smallLabel, { color: theme.primaryColor }]}>Demand (MW)</Text>
              <Text style={[styles.smallValue, { color: theme.primaryColor }]}>{formatNumber(dispatchRegionSummary?.clearedSupply, 0)}</Text>
            </View>
          </View>
        </View>

        {/* <View style={styles.regionContainerFill}>
        </View> */}
        <View style={styles.regionContainerCenter}>
          <View style={styles.regionContainerBottomLeft}>
            <View style={styles.smallValueContainer}>
              <Text style={[styles.smallLabel, { color: theme.primaryColor }]}>Sched Gen (MW)</Text>
              <Text style={[styles.smallValue, { color: theme.primaryColor }]}>{formatNumber(dispatchRegionSummary?.scheduledGeneration, 0)}</Text>
            </View>
            <View style={styles.smallValueContainer}>
              <Text style={[styles.smallLabel, { color: theme.primaryColor }]}>Semi-Sched Gen (MW)</Text>
              <Text style={[styles.smallValue, { color: theme.primaryColor }]}>{formatNumber(dispatchRegionSummary?.semiScheduleClearedMw, 0)} </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  const noData = data?.dispatchKeys?.length === 0;

  return (
    <Visualisation
      allowFullscreen={false}
      aspectRatio={4 / 3}
      border={true}
      utcTime={noData ? undefined : data?.dispatchKeys[data.dispatchKeys.length - 1]?.settlementDate}
      onTimezone={(timezone, description) => setTimezone(timezone)}
      loading={loading && !data?.dispatchKeys}
      noData={noData}
      dateFilters={
        <View
          style={{ flex: -1, flexDirection: 'row' }}>
          <Select
            multiple={false}
            options={periods}
            responsive={false}
            optionValue='label'
            compact={true}
            onChange={(value) => setSelectedPeriod(value)}
            value={selectedPeriod}
            style={{ minWidth: 36 }}
          ></Select>
          <DatePicker style={{ minWidth: 40 }} selected={selectedDate} maximumDate={formatDateTime(latestDispatch?.settlementDate ? parseDate(latestDispatch.settlementDate) : new Date(), 'yyyy/MM/dd HH:mm')} onSelectedChange={onDatePicked} active={selectedPeriod === ''} />
        </View>
      }
    >
      {!smallScreen &&
        <HorizontalScroll>
          {REGIONS.map((x: any, index: number) => regionRender(x, index))}
        </HorizontalScroll>
      }
      {smallScreen &&
        <View>
          {REGIONS.map((x: any, index: number) => regionRender(x, index))}
        </View>
      }

      {/* <Text>
        {data.latestKey.dispatchRegionSum[0].clearedSupply}
      </Text> */}
    </Visualisation>
  )
}

const styles = StyleSheet.create({
  regionsContainer: {
    flex: 1,
    flexDirection: 'row',
  },

  regionContainer: {
    flex: 1,
    paddingVertical: 6,
    paddingHorizontal: 4,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative',
    minWidth: 260
  },

  regionContainerLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    marginHorizontal: 4,
  },

  regionContainerTopLeft: {

  },

  regionName: {
    fontSize: 14
  },

  regionContainerCenterLeft: {
    marginTop: 4,
    marginBottom: 4
  },

  regionPrice: {
    fontSize: 24,
    fontWeight: '600'
  },

  regionContainerBottomLeft: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    alignItems: 'flex-start'
  },

  smallValueContainer: {
    marginRight: 6
  },

  smallLabel: {
    marginBottom: 2,
    fontSize: 10
  },

  smallValue: {
    fontSize: 18,
    fontWeight: '600'
  },

  regionContainerCenter: {
    flex: 1,
    alignItems: 'center',
    marginRight: 0
  },

  regionContainerFill: {
    flex: 1,
  },

  regionContainerRight: {
    flex: 1,
    height: '100%'
  }
})
