import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, GestureResponderEvent } from 'react-native';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { Button } from '../button';
import { Text } from '../text';

export type SettingsMenuItemProps = {
    text: string,
    selected?: boolean,
    children?: React.ReactNode,
    smallScreen?: boolean,
    onPress?: (event: GestureResponderEvent) => void;
};

export const SettingsMenuItem = React.forwardRef((props: SettingsMenuItemProps, ref) => {
    const {
        text,
        selected,
        children,
        smallScreen,
        onPress,
        ...otherProps
    } = props;

    const theme = useTheme();
    const menu = useRef() as any;

    return (
        <View style={[
            styles.fillWidth,
            smallScreen && styles.smallScreen
        ]}>
            <Button text={text} onPress={onPress} selected={selected} fillWidth={true} large={true} textAlign={'flex-start'}></Button>
        </View>
    )
});

const styles = StyleSheet.create({
    fillWidth: {
        flex: 1,
        width: '100%',
        paddingRight: 12,
        paddingBottom: 2
    },
    smallScreen: {
        paddingLeft: 12
    }
})