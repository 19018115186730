import React, { useRef, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-web-hover';
import { LayoutTopLeft } from '../layout/top-left';
import { useTheme } from '../themes/theme';
import { ToolbarButton } from '../common/toolbar-button';

export interface VisualisationContainerProps {
  border?: boolean,
  borderOnHover?: boolean,
  aspectRatio?: number,
  children?: React.ReactNode,
  topLeft?: React.ReactNode,
  topRight?: React.ReactNode,
  bottomLeft?: React.ReactNode,
  bottomRight?: React.ReactNode,
  onBodyLayout?: (event: LayoutChangeEvent) => void
};

export const VisualisationContainer = React.forwardRef((props: VisualisationContainerProps, ref) => {
  const {
    children,
    border = true,
    aspectRatio,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    onBodyLayout,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [showPopover, setShowPopover] = useState(false);
  const menu = useRef() as any;

  const hasTop = topLeft || topRight;
  const hasBottom = bottomLeft || bottomRight

  return (
    <View style={[
      styles.visualisation,
      !!border && styles.borderShadow,
      { backgroundColor: theme.popoverBackgroundColor },
      { borderColor: theme.primaryBorderColor },
      { aspectRatio: aspectRatio }
    ]}
    >
      {hasTop &&
        (
          <View style={[
            styles.top,
            !!border && styles.borderBottom,
            !!border && { borderColor: theme.primaryBorderColor }
          ]}>
            <View style={styles.topLeft}>{topLeft}</View>
            <View style={styles.topRight}>{topRight}</View>

          </View>
        )
      }
      <View style={styles.body} onLayout={onBodyLayout}>{children}</View>

      {hasBottom &&
        (
          <View style={[
            styles.bottom,
            !!border && styles.borderTop,
            !!border && { borderColor: theme.primaryBorderColor }
          ]}>
            <View style={styles.bottomLeft}>{bottomLeft}<View style={styles.spacer} /></View>
            <View style={styles.bottomRight}>{bottomRight}</View>

          </View>
        )
      }
    </View>
  )
});

const styles = StyleSheet.create({
  visualisation: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  borderShadow: {
    borderRadius: 2,
    //shadowColor: '#0003',
    //shadowRadius: 4,
    //shadowOffset: { width: 0, height: 2 },
    borderBottomWidth: 4,
    borderRightWidth: 1,
    borderLeftWidth: 1
  },

  body: {
    flex: 1,
  },

  spacer: {
    flex: 0.4
  },

  top: {
    display: "flex",
    flexDirection: "row",
    height: 8 * 4.5,
    paddingHorizontal: 8,
    width: "100%"
  },

  borderBottom: {
    borderBottomWidth: 1
  },

  topLeft: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },

  topRight: {
    flexDirection: 'column',
    justifyContent: 'center'
  },

  bottom: {
    display: "flex",
    flexDirection: "row",
    height: 8 * 4.5,
    paddingHorizontal: 8
  },

  borderTop: {
    borderTopWidth: 1
  },

  bottomLeft: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  bottomRight: {
    flexDirection: 'column',
    justifyContent: 'center',

  }
})