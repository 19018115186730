import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ThemeProvider, useTheme, themes, withTheme } from '../themes/theme';
import { useAppSelector } from '../../store/hooks';
import { selectTheme } from '../themes/theme-slice';
import { Layout } from '../layout/layout';
import { NotAuthenticated } from '../common/not-authenticated';
import { LayoutCenter } from '../layout/center';
import { LayoutTop } from '../layout/top';
import { LayoutTopLeft } from '../layout/top-left';
import { Notification } from '../common/notification';
import { SettingsModal } from '../modals/settings-modal';

export type LayoutProps = {
};

export function HomeScreen(props: LayoutProps) {
  const {
    ...otherProps
  } = props;

  const theme = useTheme();

  return (
    <View style={[
      styles.container,
      { backgroundColor: theme.primaryBorderColor }
    ]}
    >
      <NotAuthenticated />
      <Notification />
      <SettingsModal />
      <LayoutTopLeft />
      <LayoutTop />
      <LayoutCenter />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
})
