import React, { useEffect, useState, useRef } from 'react';
import { ScrollView, StyleSheet, View, Animated, TouchableWithoutFeedback } from 'react-native';
import { hexToRgb, formatDateTime } from '@energynow/core';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { Select } from '../../common/select';
import LinearGradient from 'react-native-linear-gradient';
import { Text } from '../../common/text';
import { Button } from '../../common/button';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { deleteNoticeAsync } from './user-notice-slice';


interface SingleUserNoticeProps {
  userNotice: any,
  timezone: string
};

export function SingleUserNotice(props: SingleUserNoticeProps) {
  const {
    userNotice,
    timezone
  } = props;


  const theme = useTheme();
  const dispatch = useAppDispatch();

  return (
    <TouchableWithoutFeedback>
      <View style={[styles.noticeParent, { backgroundColor: theme.primaryBackgroundColor, borderBottomColor: theme.primaryBorderColor }]}>
        <Animated.View >
          <View>
            <View style={styles.layoutTop}>
              <View style={styles.title}>
                <Text>{userNotice.title}</Text>
              </View>
              <View style={styles.action}>
                <Button icon='close' withText={false} onPress={() => dispatch(deleteNoticeAsync(userNotice))} />
              </View>

            </View>
            {/* <View>
              <Text style={[styles.reasonText, { color: theme.primaryColor }]}>{userNotice.body}</Text>
            </View> */}
            <View style={styles.date}>
              <View style={styles.noticeIdText}>
                <Text fontWeight='100'>{userNotice.createdAt}</Text>
              </View>
              <Text style={[styles.dateText]}></Text>
            </View>
          </View>
        </Animated.View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  noticeParent: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderBottomWidth: 1,
    marginBottom: 20
  },
  layoutTop: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 2,
    flex: 1
  },
  title: {
    flex: 1,
    justifyContent: 'center'
  },
  titleText: {
    fontSize: 20,
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start'
  },
  date: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 12
  },
  dateText: {
    fontWeight: '100'
  },
  noticeIdText: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    fontWeight: '100'
  },
  reasonText: {
    fontWeight: '100'
  },
  ellipsis: {
    flex: 1,
    textAlign: 'center',
  },
  gradient: {
    backgroundColor: 'transparent',
    height: 40,
    position: 'absolute',
    width: '100%',
    bottom: 25,
  }
});
