import { equals, resolveFieldData, Filter, isEmpty, isNotEmpty } from '@energynow/core';
import React, { useEffect, useState } from 'react';
import { StyleProp } from 'react-native';
import { StyleSheet, View, Pressable, useWindowDimensions, ScrollView } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { ViewStyle } from 'react-native-web';
import { Hoverable } from 'react-native-web-hover';
import { useTheme } from '../../themes/theme';
import { Button } from '../button';
import { Checkbox } from '../checkbox';
import { DEVICE_WIDTH } from '../constants';
import { Modal } from '../modal';
import { ModalSlide } from '../modal-slide';
import { Popover } from '../popover';
import { Text } from '../text';


export type SelectProps = {
  multiple?: boolean,
  responsive?: boolean,
  responsiveSize?: number,
  children?: React.ReactNode,
  options: Array<any>,
  value: any,
  optionLabel?: string,
  optionValue?: string,
  optionStyle?: string,
  dataKey?: string,
  compact?: boolean,
  large?: boolean,
  dropDown?: boolean,
  selectionLimit?: number,
  disabled?: boolean,
  filter?: boolean,
  filterBy?: string,
  selectedItemsLabel?: string,
  maxSelectedLabels?: number,
  placeholder?: string,
  title?: string,
  style?: StyleProp<ViewStyle>,
  onChange: (value: any) => void;
};

export const Select = React.forwardRef((props: SelectProps, ref) => {
  const {
    multiple = false,
    responsive = true,
    responsiveSize = DEVICE_WIDTH.md,
    children,
    options,
    value,
    optionLabel = 'label',
    optionValue = 'value',
    optionStyle,
    dataKey,
    compact = false,
    large = false,
    dropDown = false,
    selectionLimit,
    disabled = false,
    filter = false,
    filterBy,
    selectedItemsLabel = '{0} items selected',
    maxSelectedLabels,
    placeholder,
    title,
    style,
    onChange,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [selectWidth, setSelectWidth] = React.useState(0);
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [filterState, setFilterState] = React.useState('');
  const [focusedState, setFocusedState] = React.useState(false);
  const [overlayVisibleState, setOverlayVisibleState] = React.useState(false);
  const elementRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const labelRef = React.useRef(null);
  const overlayRef = React.useRef(null);
  const hasFilter = filterState && filterState.trim().length > 0;
  const { width } = useWindowDimensions();
  const [smallScreen, setSmallScreen] = useState(false);
  const [isResponsive, setIsResponsive] = useState(responsive);

  const allowOptionSelect = () => {
    return !props.selectionLimit || !props.value || (props.value && props.value.length < props.selectionLimit);
  }

  const onOptionClick = (option: any) => {

    if (props.disabled) {
      return;
    }

    let selected = isSelected(option);

    let optionValue = getOptionValue(option);
    let newValue;

    if (multiple) {
      let currentValue = value ? [...value] : [];

      if (selected)
        newValue = currentValue.filter((val) => !equals(val, optionValue, dataKey));
      else
        newValue = [...currentValue, optionValue];
    }
    else {
      if (selected)
        newValue = null;
      else
        newValue = optionValue;

      setOverlayVisibleState(false);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  const isSelected = (option: any) => {
    let selected = false;
    let optionValue = getOptionValue(option);

    if (multiple) {
      if (value && value.length) {
        for (let val of value) {
          if (equals(val, optionValue, dataKey)) {
            selected = true;
            break;
          }
        }
      }
    }
    else {
      selected = equals(value, optionValue, dataKey);
    }

    return selected;
  }

  const getOptionLabel = (option: any) => {
    return optionLabel ? resolveFieldData(option, optionLabel) : (option && option['label'] !== undefined ? option['label'] : option);
  }

  const getOptionValue = (option: any) => {
    return optionValue ? resolveFieldData(option, optionValue) : (option && option['value'] !== undefined ? option['value'] : option);
  }

  const getOptionStyle = (option: any) => {
    return optionStyle ? resolveFieldData(option, optionStyle) : (option && option['selectedStyle'] !== undefined ? option['selectedStyle'] : null);
  }

  const getOptionColor = (option: any) => {
    return option['selectedColor'] !== undefined ? option['selectedColor'] : null;
  }

  const getVisibleOptions = () => {
    if (hasFilter) {
      const filterValue = filterState.trim().toLocaleLowerCase();
      const searchFields = props.filterBy ? props.filterBy.split(',') : [props.optionLabel || 'label'];
      return Filter.filter(props.options, searchFields, filterValue, 'contains');
    }
    else {
      return props.options;
    }
  }

  const onMultiSelectClick = () => {
    if (!props.disabled) {
      overlayVisibleState ? hide() : show();
    }
  }

  const onSelectAll = (event: any) => {
    let value: any = null;

    if (event.checked) {
      value = [];

      if (visibleOptions) {
        const selectedOptions = visibleOptions.filter(option => isSelected(option));
        value = selectedOptions.map(option => getOptionValue(option));
      }
    }
    else if (visibleOptions) {
      value = visibleOptions.map(option => getOptionValue(option));
    }

    onChange([...value]);
  }

  const onFilterInputChange = (event: any) => {
    const filter = event.query;
    setFilterState(filter);
  }

  const resetFilter = () => {
    setFilterState('');
  }

  const show = () => {
    setOverlayVisibleState(true);
  }

  const hide = () => {
    setOverlayVisibleState(false);
  }

  const onCloseClick = () => {
    hide();
  }

  const getSelectedOptionIndex = () => {
    if (props.value != null && props.options) {
      return props.options.findIndex(item => props.value.some((val: any) => equals(val, getOptionValue(item), null)));
    }

    return -1;
  }

  const getLabelByValue = (val: any) => {
    let option;
    if (props.options) {
      option = props.options.find((option) => equals(getOptionValue(option), val, null));
    }

    return option ? getOptionLabel(option) : null;
  }

  const onFocus = () => {
    setFocusedState(true);
  }

  const onBlur = () => {
    setFocusedState(false);
  }

  const isAllSelected = () => {
    if (isEmpty(visibleOptions)) {
      return false;
    }

    return !(visibleOptions.some((option) => !isSelected(option)));
  }

  const removeChip = (item: any) => {
    const newValue = value.filter((val: any) => !equals(val, item, null));
    onChange(newValue);
  }

  const getSelectedItemsLabel = () => {
    const pattern = /{(.*?)}/;
    if (pattern.test(selectedItemsLabel)) {
      const match = selectedItemsLabel.match(pattern);
      return selectedItemsLabel.replace(match !== null ? match[0] : '', props.value.length + '');
    }

    return props.selectedItemsLabel;
  }

  const getLabel = () => {
    let label;

    if (!isEmpty(value)) {
      if (props.maxSelectedLabels && props.value.length > props.maxSelectedLabels) {
        return getSelectedItemsLabel();
      }
      else if (Array.isArray(value)) {
        return props.value.reduce((acc: any, rvalue: any, index: number) => (acc + (index !== 0 ? ', ' : '') + getLabelByValue(rvalue)), '');
      }
      else {
        return getLabelByValue(value);
      }
    }

    return label;
  }

  /*
// TODO render custom component in getLabelContent function

// if (!isEmpty(value)) {

//   if (Array.isArray(value)) {
//     const newValue = props.value.slice(0, props.maxSelectedLabels || props.value.length);

//     return (
//       newValue.map((val: any) => {
//         const label = getLabelByValue(val);

//         return (
//           <Button text={label} icon="close" onPress={() => removeChip(val)} />
//         )
//       })
//     )
//   } else {
//     return getLabelByValue(value);
//   }
// }*/

  const getLabelContent = () => {
    return getLabel();
  }

  const renderItems = () => {
    if (options && options.length) {
      return options.map((option, index) => {
        const optionLabel = getOptionLabel(option);
        let optionStyle = getOptionStyle(option);
        const optionColor = getOptionColor(option);

        if (optionColor && !optionStyle) {
          optionStyle = { paddingBottom: 3, borderBottomWidth: 2, borderColor: optionColor }
        }
        return <Button key={`${optionLabel}_${index}`} text={optionLabel} compact={compact} selected={isSelected(option)} large={large} selectedStyle={optionStyle} onPress={() => onOptionClick(option)} ></Button>
      });
    }

    return null;
  }

  const createMultiSelectLabel = () => {
    const content = getLabelContent();
    return (
      <View ref={labelRef} className="p-multiselect-label-container">
        <View style={[
          styles.multiSelectLabel,
          isEmpty(value) && props.placeholder && styles.multiSelectPlaceholder,
          isEmpty(value) && !props.placeholder && styles.multiSelectLabelEmpty,
          !isEmpty(value) && value > (maxSelectedLabels as number) && styles.multiSelectitemsLabel,
        ]}><Text>{content || placeholder || 'empty'}</Text></View>
      </View>
    )
  }

  const createMultiSelectItem = (option: any, index: number) => {
    const optionLabel = getOptionLabel(option);
    const optionColor = getOptionColor(option);
    const optionKey = index + '_' + optionLabel;
    const tabIndex = disabled ? null : 0;
    const selected = isSelected(option);

    return (
      <Hoverable key={optionKey}>
        {({ hovered }) => (
          <Pressable style={[
            styles.multiSelectPopoverItem,
            ((!multiple && !!selected) || hovered) && { backgroundColor: theme.primaryBackgroundHoverColor }
          ]} key={optionKey} onPress={() => onOptionClick(option)}>
            {!!multiple && (<Checkbox text={optionLabel} disableBuiltInState={true} fillColor={optionColor} isChecked={selected} onPress={(checked) => onOptionClick(option)} />)}
            {!multiple && (<Text>{optionLabel}</Text>)}
          </Pressable>
        )}
      </Hoverable>
    )
  }

  const createMultiSelectItems = () => {
    if (isNotEmpty(visibleOptions)) {
      return visibleOptions.map(createMultiSelectItem);
    }
    else if (hasFilter) {
      return <Text>No matching items found</Text>;
    }

    return null;
  }

  const createMultiSelectContent = () => {

    const multieSelectItems = createMultiSelectItems();

    return (
      <View style={[styles.multiSelectPopoverItemsContainer]}>
        <View style={[
          styles.multiSelectPopoverItems,
          !smallScreen && { maxHeight: 200 }
        ]}>
          {multieSelectItems}
        </View>
      </View>
    )

  }

  const createMultiSelectInput = () => {
    return (
      <Pressable style={[
        styles.multiSelect,
        !!compact && styles.multiSelectCompact,
        {
          borderColor: theme.primaryBorderColor
        },
        !!focusedState && styles.multiSelectFocused
      ]} onPress={() => onMultiSelectClick()}>
        <View style={
          { flex: 1 }
        }>
          <Text>{multiSelectLabel}</Text>
        </View>
        <View>
          <Icon name='down' size={18} color={theme.primaryColor} />
        </View>
      </Pressable>
    )
  }

  useEffect(() => {
    setSmallScreen(width < responsiveSize);
    setIsResponsive(responsive && width < responsiveSize);
  }, [width]);



  const visibleOptions = getVisibleOptions();
  let items = renderItems();
  const multiSelectLabel = createMultiSelectLabel();
  const multiSelectPopoverContent = createMultiSelectContent();
  const multiSelectInput = createMultiSelectInput();


  return (
    <View style={[
      styles.selectContainer,
      style
    ]}
      onLayout={e => {
        setContainerWidth(e.nativeEvent.layout.width);
      }}

    >
      {!dropDown && <View style={[
        styles.select,
        !!isResponsive && {
          maxHeight: selectWidth >= containerWidth ? 0 : 'unset'
        }
      ]}
        onLayout={e => {
          setSelectWidth(e.nativeEvent.layout.width);
        }}
        accessibilityRole={"list"}
      >
        {items}
      </View>}
      {(!!dropDown || ((selectWidth > containerWidth) && !!isResponsive)) && !smallScreen &&
        <Popover
          visible={overlayVisibleState}
          position='bottom'
          onClose={hide}
          content={(
            <View style={[
              styles.multiSelectPopover,
              {
                backgroundColor: theme.popoverBackgroundColor,
                width: containerWidth
              }
            ]}>
              {multiSelectPopoverContent}
            </View>
          )}
          children={
            multiSelectInput
          }
        ></Popover>
      }
      {(!!dropDown || ((selectWidth > containerWidth) && !!isResponsive)) && smallScreen && (
        <View>
          {multiSelectInput}
          <ModalSlide visible={overlayVisibleState} dismissButton={true} onDismiss={() => hide()}>
            <ScrollView style={[
              styles.multiSelectModal,
              {
                backgroundColor: theme.popoverBackgroundColor
              }
            ]}>
              {multiSelectPopoverContent}
            </ScrollView>
          </ModalSlide>
        </View>
      )
      }
    </View >
  )
});

const styles = StyleSheet.create({
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center'
  },

  select: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    flexGrow: 1
  },

  multiSelect: {
    fontSize: 14,
    borderRadius: 2,
    height: 40,
    borderWidth: 1,
    padding: 10,
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center'
  },

  multiSelectCompact: {
    minWidth: 40,
    height: 30,
    paddingHorizontal: 10,
    paddingVertical: 4,
    flex: 1
  },

  multiSelectFocused: {

  },

  multiSelectLabel: {

  },

  multiSelectPlaceholder: {

  },

  multiSelectLabelEmpty: {

  },

  multiSelectitemsLabel: {

  },

  multiSelectModal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingVertical: 6,
  },

  multiSelectPopover: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: 2,
    paddingVertical: 6,
    shadowColor: '#0003',
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 }
  },

  multiSelectPopoverItemsContainer: {

  },

  multiSelectPopoverItems: {
    overflow: 'scroll'
  },

  multiSelectPopoverItem: {
    padding: 8
  },

})
