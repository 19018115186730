import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import { Popover as P, ArrowContainer } from 'react-tiny-popover';
import { PopoverProps, PopoverPosition, PREFERRED_POSITIONS } from './types';

export const Popover = React.forwardRef((props: PopoverProps, ref) => {
  const {
    visible = false,
    position = 'bottom',
    arrow = false,
    children,
    content,
    popoverStyle = null,
    onClose = () => { },
    ...otherProps
  } = props;

  const popoverRef = useRef() as any;

  // Manually checking for click outside events, react-tiny-popover onClickOutside is 
  // not working when clicking on react native pressable components
  const clickEvent = (event: Event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (visible === true) {
      document.body.addEventListener('click', clickEvent, true);
    }

    return () => {
      document.body.removeEventListener('click', clickEvent, true);
    }

  }, [visible]);

  const containerStyle = {
    zIndex: 999999
  };

  return (
    <P

      isOpen={visible}
      content={({ position: arrowPosition, childRect, popoverRect }) => (
        <ArrowContainer
          position={arrowPosition}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowSize={arrow ? 4 : 0}
          arrowColor={''}
        >
          <View ref={popoverRef}>
            {content as any}
          </View>
        </ArrowContainer>
      )}
      containerStyle={containerStyle as any}
      positions={[position, ...PREFERRED_POSITIONS]}
      align={'start'}
      padding={1}
    >
      {children as any}
    </P>
  )
});

const styles = StyleSheet.create({
  popover: {
  },


})