import { LATEST_DISPATCH_QUERY, parseDate, parseDateCustom, formatDateTime, getDispatchHub, DISPATCH_HUB_LATEST_DISPATCH, formatDateTimeUrl, DAILY_DISPATCH_QUERY, getCurrentDispatchInterval } from '@energynow/core';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { View, Dimensions, LayoutRectangle, useWindowDimensions, Animated, StyleSheet, TouchableWithoutFeedback, Easing } from 'react-native';
import { Text } from '../../common/text';
import { useTheme } from '../../themes/theme';
import { useQuery } from '../../../hooks/apollo';
import { Share } from '../../../utils/share';
import { Select } from '../../common/select';
import { Visualisation } from '../../common/visualisation';
import { ErrorLoading } from '../../common/error-loading';
import { useHubToListen } from '../../../hooks/signalr';
import Svg, { G, Path, Rect } from 'react-native-svg';
import { Text as SvgText } from '../../common/svg';
import { Australia } from '../../common/map/australia';
import { Arrow, ArrowDirection } from '../../common/map/arrow';
// @ts-ignore
import DatePicker from '../../common/datepicker';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedDate } from '../../layout/layout-slice';


export function InterconnectorsMap(props: any) {
  const params = props.route?.params;
  const initialDate = params?.date ? params.date : null;

  const latestDispatch = useHubToListen(getDispatchHub(), DISPATCH_HUB_LATEST_DISPATCH);

  const { loading, error, data, refetch } = useQuery(DAILY_DISPATCH_QUERY, {
    variables: {
      fromDate: initialDate,
      toDate: initialDate
    }
  });

  const filters = [
    { 'label': 'Show Limit', value: true }
  ];

  const theme = useTheme();
  const globalSelectedDate = useAppSelector(selectSelectedDate);
  const [showLimit, setShowLimit] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(initialDate ? '' : 'Live');
  const [visualisationRectangle, setVisualisationRectangle] = useState<LayoutRectangle>({ width: 0, height: 0, x: 0, y: 0 });
  const [selectedDate, setSelectedDate] = useState<Date | null>(initialDate);
  const [timezone, setTimezone] = useState('+10:00');
  const [regions, setRegions] = useState([] as any);
  const [nswRegionSum, setNswRegionSum] = useState(null);
  const [qldRegionSum, setQldRegionSum] = useState(null);
  const [vicRegionSum, setVicRegionSum] = useState(null);
  const [saRegionSum, setSaRegionSum] = useState(null);
  const [tasRegionSum, setTasRegionSum] = useState(null);
  const [interconnectors, setInterconnectors] = useState([] as any);
  const [nqmnspInterconnector, setNqmnspInterconnector] = useState(null as any);
  const [nswqldInterconnector, setNswqldInterconnector] = useState(null as any);
  const [vicnswInterconnector, setVicnswInterconnector] = useState(null as any);
  const [vsmnspInterconnector, setVsmnspInterconnector] = useState(null as any);
  const [vsaInterconnector, setVsaInterconnector] = useState(null as any);
  const [tvmnspInterconnector, setTvmnspInterconnector] = useState(null as any);
  const periods = [
    { label: 'Live' }
  ];

  const generateData = () => {
    if (!data || !data.dispatchKeys || data.dispatchKeys.length === 0) {
      return;
    }

    const tempRegions = data.dispatchKeys[data.dispatchKeys.length - 1]?.dispatchRegionSum;
    const tempInterconnectors = data.dispatchKeys[data.dispatchKeys.length - 1]?.dispatchInterconnectorRes

    setRegions([...tempRegions]);
    setNswRegionSum(tempRegions.find((x: any) => x.regionId === 'NSW1'));
    setQldRegionSum(tempRegions.find((x: any) => x.regionId === 'QLD1'));
    setVicRegionSum(tempRegions.find((x: any) => x.regionId === 'VIC1'));
    setSaRegionSum(tempRegions.find((x: any) => x.regionId === 'SA1'));
    setTasRegionSum(tempRegions.find((x: any) => x.regionId === 'TAS1'));

    setInterconnectors([...tempInterconnectors]);
    setNqmnspInterconnector(tempInterconnectors.find((x: any) => x.interconnectorId === 'N-Q-MNSP1'));
    setNswqldInterconnector(tempInterconnectors.find((x: any) => x.interconnectorId === 'NSW1-QLD1'));
    setVicnswInterconnector(tempInterconnectors.find((x: any) => x.interconnectorId === 'VIC1-NSW1'));
    setVsmnspInterconnector(tempInterconnectors.find((x: any) => x.interconnectorId === 'V-S-MNSP1'));
    setVsaInterconnector(tempInterconnectors.find((x: any) => x.interconnectorId === 'V-SA'));
    setTvmnspInterconnector(tempInterconnectors.find((x: any) => x.interconnectorId === 'T-V-MNSP1'));
  }

  useEffect(() => {
    generateData();
  }, [data]);

  useEffect(() => {
    if (selectedPeriod === 'Live') {
      refetch({
        fromDate: null as any,
        toDate: null as any
      });
    }
  }, [latestDispatch, selectedPeriod]);

  useEffect(() => {
    if (globalSelectedDate) {
      onDatePicked(globalSelectedDate);
    } else if (globalSelectedDate === null) {
      setSelectedPeriod('Live');
    }
  }, [globalSelectedDate]);

  const onDatePicked = (date: string) => {
    let newDate: Date;
    if (timezone != '') {
      newDate = parseDateCustom(`${date} ${timezone}`, 'yyyy/MM/dd HH:mm XXX');
    } else {
      newDate = parseDateCustom(date, 'yyyy/MM/dd HH:mm');
    }

    refetch({
      fromDate: newDate as any,
      toDate: newDate as any
    });

    setSelectedDate(newDate);
    setSelectedPeriod('');
  };


  if (error) {
    return <ErrorLoading onPressReload={() => { refetch() }} />
  }

  const regionColors = (regionId: string) => {
    return theme.secondaryBackgroundColor;
  }

  const { width, height } = visualisationRectangle;

  const regionPanelWidth = 85;
  const regionPanelHeight = 50;

  const qldRegionPosition = {
    x: 190,
    y: 100
  }
  const nswRegionPosition = {
    x: 240,
    y: 240
  }
  const vicRegionPosition = {
    x: 70,
    y: 340
  }
  const saRegionPosition = {
    x: 20,
    y: 150
  }
  const tasRegionPosition = {
    x: 240,
    y: 340
  }


  const regularInterconnectorColor = theme.successBorderColor;
  const limitInterconnectorColor = theme.errorBorderColor;

  const regionPanel = (d: any) => {
    if (!d || !d.dispatchInterconnectorRes) {
      return;
    }

    const exportSum = d.dispatchInterconnectorRes.reduce((a: number, x: any) => {
      if (x.interconnector.regionFrom === d.regionId && x.mwFlow > 0) {
        return a + x.mwFlow;
      } else if (x.interconnector.regionTo === d.regionId && x.mwFlow < 0) {
        return a - x.mwFlow;
      }
      return a;
    }, 0);

    const importSum = d.dispatchInterconnectorRes.reduce((a: number, x: any) => {
      if (x.interconnector.regionFrom === d.regionId && x.mwFlow < 0) {
        return a - x.mwFlow;
      } else if (x.interconnector.regionTo === d.regionId && x.mwFlow > 0) {
        return a + x.mwFlow;
      }
      return a;
    }, 0);

    return (
      <React.Fragment>
        <Rect
          width={regionPanelWidth}
          height={regionPanelHeight}
          fill={regionColors(d.regionId)}
          stroke={theme.primaryBorderColor}
          strokeOpacity="0.8"
          rx="2"
        ></Rect>
        <SvgText dx={regionPanelWidth / 2} dy={regionPanelHeight / 3.5} textAnchor='middle' fontSize="10">{d.regionId.replace('1', '')}</SvgText>
        <SvgText dx={regionPanelWidth * 1 / 4} dy={regionPanelHeight * 2.8 / 5} textAnchor='middle' fontSize="10">Export</SvgText>
        <SvgText dx={regionPanelWidth * 1 / 4} dy={regionPanelHeight * 4 / 5} textAnchor='middle' fontSize="10">{Math.round(exportSum)}</SvgText>
        <SvgText dx={regionPanelWidth * 3 / 4} dy={regionPanelHeight * 2.8 / 5} textAnchor='middle' fontSize="10">Import</SvgText>
        <SvgText dx={regionPanelWidth * 3 / 4} dy={regionPanelHeight * 4 / 5} textAnchor='middle' fontSize="10">{Math.round(importSum)}</SvgText>
      </React.Fragment>
    )
  }

  const findInterconnectorColor = (d: any) => {
    if (d.mwFlow <= d.importLimit || d.mwFlow >= d.exportLimit) {
      return limitInterconnectorColor;
    }

    return regularInterconnectorColor;
  }

  const interconnectorCapacity = (d: any) => {
    // Cover rare scenario when limits are equal
    if (d.mwFlow !== 0 && d.mwFlow === d.exportLimit && d.mwFlow === d.importLimit) {
      return 1;
    }

    if (d.exportLimit < d.importLimit) {
      return 1;
    }

    if (d.mwFlow < 0) {
      //importing
      if (d.mwFlow === d.exportLimit)
        return 0;

      // Both limits negative
      if (d.exportLimit < 0 && d.importLimit < 0) {
        return ((d.mwFlow - d.exportLimit) / (d.importLimit - d.exportLimit));
      }

      return Math.min(d.mwFlow / d.importLimit, 1);
    } else if (d.mwFlow > 0) {
      //exporting
      // On lower limit
      if (d.mwFlow === d.importLimit)
        return 0;

      // Both limits positive
      if (d.exportLimit > 0 && d.importLimit > 0) {
        return ((d.mwFlow - d.importLimit) / (d.exportLimit - d.importLimit));
      }

      return Math.min(d.mwFlow / d.exportLimit, 1);
    }

    return 0;
  }

  const interconnectorLabel = (d: any, limit: number | undefined, vertical: boolean) => {
    if (d.mwFlow === undefined) {
      return;
    }

    const limitString = limit !== undefined ? Math.abs(Math.round(limit)).toString() : '';
    const flowString = Math.abs(Math.round(d.mwFlow)).toString();

    // Crappy mapping for text position, should change this to a dynamic position based on svg text width
    const stringLengthToDx = [
      { flowDxVertical: 0, flowDxHorizontal: 0, limitDxVertical: 0, limitDxHorizontal: 0 },
      { flowDxVertical: -3, flowDxHorizontal: -9, limitDxVertical: 3, limitDxHorizontal: -0.5 },
      { flowDxVertical: -8, flowDxHorizontal: -20, limitDxVertical: 6, limitDxHorizontal: -1 },
      { flowDxVertical: -12, flowDxHorizontal: -22, limitDxVertical: 9, limitDxHorizontal: -1.5 },
      { flowDxVertical: -12, flowDxHorizontal: -39, limitDxVertical: 12, limitDxHorizontal: -2 },
    ];

    const dx = vertical ? stringLengthToDx[flowString === '0' ? 0 : flowString.length].flowDxVertical : stringLengthToDx[flowString === '0' ? 0 : flowString.length].flowDxHorizontal;
    const dxLimit = vertical ? stringLengthToDx[limitString.length].limitDxVertical : stringLengthToDx[limitString.length].limitDxHorizontal;

    return (
      <React.Fragment>
        {/* <SvgText textAnchor='middle' fontSize="10">{d.interconnectorId}</SvgText> */}
        <G>
          {!showLimit && <SvgText dy='12' textAnchor='middle' fontSize="11" fontWeight={500}>{flowString}</SvgText>}
          {showLimit && <SvgText dy='12' dx={dx} textAnchor='middle' fontSize="11" fontWeight={500} >{Math.abs(Math.round(d.mwFlow))}</SvgText>}
          {showLimit && limitString && <SvgText dy='12' dx={dxLimit} textAnchor='middle' fontSize="9" fontWeight={500}> / {limitString}</SvgText>}
        </G>
      </React.Fragment>
    )
  }

  const makeInterconnectorComponent = (interconnector: any, labelPosition: any, arrowPosition: any) => {
    if (!interconnector) {
      return;
    }

    let arrowDirection: any = null;
    let capacity = interconnectorCapacity(interconnector);
    let limit: number | undefined = undefined;
    if (interconnector.mwFlow < 0) {
      arrowDirection = arrowPosition.vertical ? ArrowDirection.down : ArrowDirection.right;
      limit = interconnector.importLimit;
    } else if (interconnector.mwFlow > 0) {
      arrowDirection = arrowPosition.vertical ? ArrowDirection.up : ArrowDirection.left;
      limit = interconnector.exportLimit;
    } else {
      arrowDirection = ArrowDirection.none;
    }

    let interconnectorColor = findInterconnectorColor(interconnector);

    return (
      <G x={arrowPosition.x} y={arrowPosition.y}>
        <G {...labelPosition}>
          {interconnectorLabel(interconnector, limit, arrowDirection === ArrowDirection.up || arrowDirection === ArrowDirection.down)}
        </G>
        {arrowDirection !== null && <Arrow arrowColour={interconnectorColor} direction={arrowDirection} capacity={capacity}></Arrow>}
      </G>
    )
  }

  const nqmnspInterconnectorComponent = () => {
    const interconnector = nqmnspInterconnector;
    const arrowPosition: any = {
      x: 210,
      y: 200,
      vertical: true
    };

    let labelPosition: any = {
      x: 13,
      y: -16
    }

    return makeInterconnectorComponent(interconnector, labelPosition, arrowPosition);
  }

  const nswqldInterconnectorComponent = () => {
    const interconnector = nswqldInterconnector;
    const arrowPosition: any = {
      x: 150,
      y: 200,
      vertical: true
    };

    let labelPosition: any = {
      x: 13,
      y: -16
    }

    return makeInterconnectorComponent(interconnector, labelPosition, arrowPosition);
  }

  const vicnswInterconnectorComponent = () => {
    const interconnector = vicnswInterconnector;
    const arrowPosition: any = {
      x: 180,
      y: 287,
      vertical: true
    };

    let labelPosition: any = {
      x: 12,
      y: -16
    }

    return makeInterconnectorComponent(interconnector, labelPosition, arrowPosition);
  }

  const vsmnspInterconnectorComponent = () => {
    const interconnector = vsmnspInterconnector;
    const arrowPosition: any = {
      x: 118,
      y: 280,
      vertical: false
    };

    let labelPosition: any = {
      x: -15,
      y: 6
    }

    return makeInterconnectorComponent(interconnector, labelPosition, arrowPosition);
  }

  const vsaInterconnectorComponent = () => {
    const interconnector = vsaInterconnector;
    const arrowPosition: any = {
      x: 118,
      y: 310,
      vertical: false
    };

    let labelPosition: any = {
      x: -15,
      y: 6
    }

    return makeInterconnectorComponent(interconnector, labelPosition, arrowPosition);
  }

  const tvmnspInterconnectorComponent = () => {
    const interconnector = tvmnspInterconnector;
    const arrowPosition: any = {
      x: 176,
      y: 355,
      vertical: true
    };

    let labelPosition: any = {
      x: 14,
      y: 27
    }

    return makeInterconnectorComponent(interconnector, labelPosition, arrowPosition);
  }

  const noData = data?.dispatchKeys?.length === 0;

  return (
    <Visualisation
      allowFullscreen={true}
      aspectRatio={4 / 3}
      border={true}
      utcTime={noData ? undefined : data?.dispatchKeys[data.dispatchKeys.length - 1]?.settlementDate}
      loading={loading && !data?.dispatchKeys}
      noData={noData}
      onShare={() => { Share('Interconnectors Map', `https://www.energydash.com.au/visualisation/interconnectors-map/${formatDateTimeUrl(data?.dispatchKeys[0].settlementDate)}`) }}
      onLayout={(layout) => setVisualisationRectangle(layout)}
      onTimezone={(timezone, description) => setTimezone(timezone)}
      filters={
        <Select
          multiple={false}
          compact={true}
          options={filters}
          onChange={(value) => setShowLimit(value === true)}
          value={showLimit}
        ></Select>
      }
      dateFilters={
        <View
          style={{ flex: -1, flexDirection: 'row' }}>
          <Select
            multiple={false}
            options={periods}
            responsive={false}
            optionValue='label'
            compact={true}
            onChange={(value) => setSelectedPeriod(value)}
            value={selectedPeriod}
            style={{ minWidth: 36 }}
          ></Select>
          <DatePicker style={{ minWidth: 40 }} selected={selectedDate} maximumDate={formatDateTime(latestDispatch?.settlementDate ? parseDate(latestDispatch.settlementDate) : new Date(), 'yyyy/MM/dd HH:mm')} onSelectedChange={onDatePicked} active={selectedPeriod === ''} />
        </View>
      }
    >
      <View style={styles.container}>
        <Svg height={visualisationRectangle.height - 40} viewBox="0 0 269 414" fill="none" style={styles.map}>
          <Australia></Australia>
          <G>
            <G {...qldRegionPosition} >
              {regionPanel(qldRegionSum)}
            </G>
            <G {...nswRegionPosition} >
              {regionPanel(nswRegionSum)}
            </G>
            <G {...vicRegionPosition} >
              {regionPanel(vicRegionSum)}
            </G>
            <G {...saRegionPosition} >
              {regionPanel(saRegionSum)}
            </G>
            <G {...tasRegionPosition} >
              {regionPanel(tasRegionSum)}
            </G>
          </G>

          {nqmnspInterconnectorComponent()}
          {nswqldInterconnectorComponent()}
          {vicnswInterconnectorComponent()}
          {vsmnspInterconnectorComponent()}
          {vsaInterconnectorComponent()}
          {tvmnspInterconnectorComponent()}
        </Svg>
      </View>
    </Visualisation>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start'
  },
  child: {
    flex: 1,
    flexDirection: 'row'
  },
  map: {
    overflow: 'visible'
  },
  interconnectorPanel: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  interconnector: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
    backgroundColor: 'brown'
  },
  regionPanel: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  regionBox: {
    borderRadius: 20,
    height: '80%',
    width: '100%',
  },
  regionLabel: {
    textAlign: 'center'
  },
  svg: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
});
