import { StyleProp, ViewStyle } from "react-native";

export type PopoverPosition = 'left' | 'right' | 'top' | 'bottom';

export type PopoverProps = {
  visible?: boolean,
  position?: PopoverPosition,
  arrow?: boolean,
  children?: React.ReactNode,
  content?: React.ReactNode,
  popoverStyle?: StyleProp<ViewStyle> | undefined;
  onClose?: () => void
};


export const PREFERRED_POSITIONS: Array<PopoverPosition> = [
  'bottom',
  'top',
  'right',
  'left'
];