import { style } from 'd3';
import React, { useRef } from 'react'
import { View, Animated, Easing, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { useTheme } from '../themes/theme';

export interface LoadingProps {
  small?: boolean,
};

export const Loading = React.forwardRef((props: LoadingProps, ref) => {
  const {
    small = false
  } = props;
  const rotateAnim = useRef(new Animated.Value(0)).current
  const theme = useTheme();

  Animated.loop(
    Animated.timing(rotateAnim, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
      useNativeDriver: false
    })
  ).start();

  const spin = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg']
  });

  return (
    <View style={styles.container} >
      <Animated.View style={[styles.animate, { transform: [{ rotate: spin }] }]}>
        <Icon style={[styles.loading, { color: theme.primaryColor }, small && styles.small]} name='loading1' />
      </Animated.View>
    </View>
  )
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  animate: {
  },
  loading: {
    fontSize: 64
  },
  small: {
    fontSize: 14
  }

});
