import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Select } from '../common/select';
import { Text } from '../common/text';
import { useTheme } from '../themes/theme';
import { MARKET_NOTICE_TYPES } from '../visualisations/constants';

export type MarketNoticeNotificationProps = {
  onChange: (value: any) => void;
};

export const MarketNoticeNotification = React.forwardRef((props: MarketNoticeNotificationProps, ref) => {
  const {
    onChange,
    ...otherProps
  } = props;

  const theme = useTheme();

  const [selectedNoticeTypes, setSelectedNoticeTypes] = useState([]);

  useEffect(() => {
    if (selectedNoticeTypes.length < 1) {
      onChange(null);
    } else {
      onChange({
        noticeTypes: selectedNoticeTypes,
      });
    }
  }, [selectedNoticeTypes]);

  return (
    <View style={styles.container}>
      <View style={styles.formRowLabel}>
        <Text>Notice types</Text>
      </View>
      <View style={styles.formRow}>
        <Select
          multiple={true}
          dropDown={true}
          options={MARKET_NOTICE_TYPES}
          onChange={(value) => setSelectedNoticeTypes(value)}
          value={selectedNoticeTypes}
        ></Select>
      </View>
    </View >
  )
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    width: "100%",
    paddingleft: 12,
    paddingBottom: 2
  },

  formRow: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 6
  },

  formRowLabel: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 6,
    marginleft: 12,
  },

  input: {

  },
});
