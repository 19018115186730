import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store';
import { keycloak, apolloClientAuth, UPDATE_SETTINGS } from '@energynow/core';


export interface ProfileState {
  profile?: any;
  signIn?: boolean;
}

const initialState: ProfileState = {
  profile: null,
  signIn: false
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setProfile: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.profile = action.payload;
    },

    setSignIn: (state, action: PayloadAction<boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.signIn = action.payload;
    }
  }
});

export const { setProfile, setSignIn } = profileSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProfile = (state: RootState) => state.profile.profile;
export const selectSignIn = (state: RootState) => state.profile.signIn;

export default profileSlice.reducer;
