import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, ViewProps, TouchableWithoutFeedback, Linking, TouchableOpacity, Image } from 'react-native';
import { ToolbarButton } from '../common/toolbar-button';
import { Popover } from '../common/popover';
import { ThemeProvider, useTheme } from '../themes/theme';
import { MenuButton } from '../common/menu-button';
import { MenuItem } from '../common/menu-item';
import { Switch } from '../common/switch';
import { Checkbox } from '../common/checkbox';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectTheme, setTheme } from '../themes/theme-slice';
import { setSettings } from './layout-slice';
import { Platform } from '../../libs/platform';
import { Button } from '../common/button';
import { keycloak } from '@energynow/core';
import { setSignIn } from '../profile/profile-slice';
import { Profile } from '../profile';
import { RoadmapModal } from '../modals/roadmap-modal';
import { Text } from '../common/text';
import nfiniteLogo from '@energynow/components/assets/nfinite_logo.png';

export type LayoutTopProps = {
  visible?: boolean
  withBorder?: boolean
};

export function LayoutTopLeft(props: LayoutTopProps) {
  const {
    visible = true,
    withBorder,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [showPopover, setShowPopover] = useState(false);
  const [showRoadmap, setShowRoadmap] = useState(false);
  const menu = useRef() as any;
  const menuButton = useRef() as any;
  const themeSwitcher = useAppSelector(selectTheme);
  const dispatch = useAppDispatch();
  const [switchEnabled, setSwitchEnabled] = useState(themeSwitcher === 'dark');

  const AuthDelegate = (callback: any) => {
    if (!keycloak?.authenticated) {
      dispatch(setSignIn(true));
      return;
    }

    callback();
  }


  useEffect(() => {
    setSwitchEnabled(themeSwitcher === 'dark');
  }, [themeSwitcher]);

  return (

    <View ref={menu} style={[
      styles.layoutTopLeft,
      { backgroundColor: theme.primaryBackgroundColor, borderBottomWidth: 1, borderColor: theme.primaryBorderColor }
    ]}>

      <MenuButton
        ref={menuButton}
        fill={false}
        icon={!!showPopover ? 'menufold' : 'menuunfold'}
        position={'right'}
        onShow={() => setShowPopover(true)}
        onHide={() => setShowPopover(false)}
        showMenu={showPopover}
        first={true}
      >
        <MenuItem text={"Dark theme"}>
          <Switch value={switchEnabled} onValueChange={(value) => {
            setSwitchEnabled(value);
            dispatch(setTheme(value === true ? 'dark' : 'default'));
          }}></Switch>
        </MenuItem>
        <MenuItem separator={true}></MenuItem>
        {!keycloak?.authenticated && (
          <>
            <MenuItem text={"Sign in"} onPress={() => keycloak?.login()}></MenuItem>
            <MenuItem text={"Register"} onPress={() => keycloak?.login()}></MenuItem>
          </>)
        }
        {keycloak?.authenticated && (
          <>
            <Profile />
            <MenuItem text={"Settings"} onPress={() => { menuButton.current.hide(); dispatch(setSettings(true)); }}></MenuItem>
            <MenuItem text={"Sign out"} onPress={() => keycloak?.logout()}></MenuItem>
          </>
        )
        }
        <MenuItem separator={true}></MenuItem>
        {/* {
          Platform.OS === 'web' && !Platform.isElectron && Platform.isDesktop &&
          <MenuItem text={"Desktop App"}><Button text='Coming soon'  ></Button></MenuItem>
        }
        {
          Platform.OS === 'web' && Platform.realOS === 'android' &&
          <MenuItem text={"Google Play"}><Button text='Coming soon' ></Button></MenuItem>
        }
        {
          Platform.OS === 'web' && Platform.realOS === 'ios' &&
          <MenuItem text={"App Store"}><Button text='Coming soon'  ></Button></MenuItem>
        } */}
        {/* <MenuItem text={"Help"}></MenuItem> */}
        <MenuItem text={"Roadmap"} onPress={() => { menuButton.current.hide(); setShowRoadmap(true); }}></MenuItem>
        <MenuItem text={"Want to support us?"} onPress={() => { Linking.openURL("https://www.buymeacoffee.com/nfiniteaus") }}>
          <Button text='Buy us a coffee' onPress={() => { Linking.openURL("https://www.buymeacoffee.com/nfiniteaus") }}></Button>
        </MenuItem>
        <MenuItem text={"Send us feedback"} onPress={() => { Linking.openURL("https://s.surveyplanet.com/4ylp613e") }}></MenuItem>
        <MenuItem separator={true}></MenuItem>
        <TouchableOpacity onPress={() => Linking.openURL("https://www.nfinite.com.au")} >
          <View style={[styles.nfinite, { backgroundColor: theme.primaryBackgroundColor }]}>
            <Image source={nfiniteLogo} style={[styles.logo]} />
            <Text>Made by Nfinite</Text>
          </View>
        </TouchableOpacity>
      </MenuButton>

      <RoadmapModal visible={showRoadmap} onRequestClose={() => setShowRoadmap(false)}>

      </RoadmapModal>



    </View>

  )
}

const styles = StyleSheet.create({
  layoutTopLeft: {
    position: "absolute",
    width: 8 * 5,
    height: 8 * 4.5,
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  nfinite: {
    paddingLeft: 4,
    paddingRight: 16,
    paddingVertical: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  logo: {
    marginTop: 4,
    marginBottom: 4,
    width: 22,
    height: 22,
    marginLeft: 6,
    marginRight: 4
  }
})
