import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../store/store';
import { keycloak, apolloClientAuth, UPDATE_DEVICES, UPDATE_NOTIFICATION, DELETE_NOTICE } from '@energynow/core';


export interface UserNoticeState {
  notices: Array<UserNotice>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: UserNoticeState = {
  notices: [],
  status: 'idle',
};

export interface UserNotice {
  createdAt: Date | string,
  noticeId: string,
  title: string,
  body: string,
  link: string,
}

export const deleteNoticeAsync = createAsyncThunk(
  'notices/deleteNotice',
  async (notice?: UserNotice) => {
    if (!!keycloak?.authenticated) {
      const { data } = await apolloClientAuth.mutate({
        mutation: DELETE_NOTICE,
        variables: {
          notice: {
            noticeId: notice?.noticeId
          }
        }
      });
    }
    // The value we return becomes the `fulfilled` action payload
    return notice;
  }
);


export const noticesSlice = createSlice({
  name: 'notices',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNotices: (state, action: PayloadAction<Array<UserNotice>>) => {
      state.notices = action.payload;
    },
    addNotice: (state, action: PayloadAction<UserNotice>) => {
      state.notices.unshift(action.payload);
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(deleteNoticeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteNoticeAsync.fulfilled, (state, action: any) => {
        state.status = 'idle';
        let existingIndex = state.notices.findIndex(x => x.noticeId === action.payload?.noticeId);
        if (existingIndex > -1) {
          // Removed one notice
          state.notices.splice(existingIndex, 1);
        }

        if (!action.payload?.noticeId) {
          // Remove all notices
          state.notices = [];
        }
      })
      .addCase(deleteNoticeAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { addNotice, setNotices } = noticesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNotices = (state: RootState) => state.notices.notices;

export default noticesSlice.reducer;
