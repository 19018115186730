import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, useWindowDimensions } from 'react-native';
import Svg, { Circle, G, Line } from 'react-native-svg';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { Path } from '../svg';

export enum ArrowDirection {
  up,
  down,
  right,
  left,
  none
}

export type ArrowProps = {
  arrowColour?: string,
  direction?: ArrowDirection,
  capacity?: number
};

export const Arrow = React.forwardRef((props: ArrowProps, ref) => {
  const {
    arrowColour,
    direction = ArrowDirection.up,
    capacity = 0
  } = props;
  const theme = useTheme();
  const [rotation, setRotation] = useState(0);

  const updateRotation = (direction: ArrowDirection) => {
    if (direction === ArrowDirection.down) {
      setRotation(180);
    } else if (direction === ArrowDirection.right) {
      setRotation(90);
    } else if (direction === ArrowDirection.left) {
      setRotation(275);
    } else if (direction == ArrowDirection.up) {
      setRotation(0);
    }
  }

  useEffect(() => {
    updateRotation(direction);
  }, [direction]);

  const arc = () => {

    if (capacity > 0)
      return generateArc(14, 14, 13, 0, 360 * capacity - 1);

    return '';
  }


  const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  const generateArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return [
      "M", start.x, start.y,
      "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
  }

  return (
    <G>
      <G stroke="none" fill="none" rotation={rotation} originX={14} originY={14} >
        <Circle cx="14" cy="14" r="14" fill={theme.primaryBackgroundHoverColor} />
        <Circle cx="14" cy="14" r="12" fill={theme.primaryBackgroundColor} />


        {direction !== ArrowDirection.none && <Path d="M13.1936 8.97464C13.5931 8.42979 14.4068 8.42979 14.8064 8.97464L20.9724 17.3828C21.6045 18.2447 20.6255 19.3639 19.6871 18.8521L14.4789 16.0112C14.1804 15.8484 13.8196 15.8484 13.5211 16.0112L8.31286 18.8521C7.37455 19.3639 6.39554 18.2447 7.0276 17.3828L13.1936 8.97464Z" fill={arrowColour ? arrowColour : theme.primaryBorderColor} />}
        {direction === ArrowDirection.none && <Circle cx="14" cy="14" r="4" fill={theme.primaryBorderColor} />}
      </G>
      <Path d={arc()} stroke={arrowColour ? arrowColour : theme.primaryBorderColor} strokeWidth={1.8} />
    </G>
  );
});


const styles = StyleSheet.create({
  map: {

  },

  australia: {

  }
});