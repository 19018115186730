import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useAppDispatch } from '../../store/hooks';
import { Select } from '../common/select';
import { Text } from '../common/text';
import { TextInput } from '../common/text-input';
import { useTheme } from '../themes/theme';
import { REGIONS, FUEL_TYPES } from '../visualisations/constants';
import { CONDITIONS } from './constants';

export type GenerationFuelNotificationProps = {
  onChange: (value: any) => void;
};

export const GenerationFuelNotification = React.forwardRef((props: GenerationFuelNotificationProps, ref) => {
  const {
    onChange,
    ...otherProps
  } = props;

  const theme = useTheme();

  const [selectedFuelTypes, setSelectedFuelTypes] = useState(FUEL_TYPES.map(x => x.value) as any);
  const [selectedRegions, setSelectedRegions] = useState(REGIONS.map(x => x.value) as any);
  const [selectedCondition, setSelectedCondition] = useState('>' as any);
  const [number, onChangeNumber] = React.useState("");

  useEffect(() => {
    const amount = Number.parseFloat(number);

    if (selectedRegions.length < 1 || selectedFuelTypes.length < 1 || Number.isNaN(amount)) {
      onChange(null);
    } else {
      onChange({
        regions: selectedRegions,
        fuelTypes: selectedFuelTypes,
        condition: selectedCondition,
        amount: amount
      });
    }
  }, [selectedRegions, selectedCondition, selectedFuelTypes, number]);

  return (
    <View style={styles.container}>
      <View style={styles.formRowLabel}>
        <Text>Regions</Text>
      </View>
      <View style={styles.formRow}>
        <Select
          multiple={true}
          options={REGIONS}
          onChange={(value) => setSelectedRegions(value)}
          value={selectedRegions}
        ></Select>
      </View>
      <View style={styles.formRowLabel}>
        <Text>Fuel Types</Text>
      </View>
      <View style={styles.formRow}>
        <Select
          multiple={true}
          options={FUEL_TYPES}
          onChange={(value) => setSelectedFuelTypes(value)}
          value={selectedFuelTypes}
        ></Select>
      </View>
      <View style={styles.formRowLabel}>
        <Text>Condition</Text>
      </View>
      <View style={styles.formRow}>
        <Select
          multiple={false}
          options={CONDITIONS}
          onChange={(value) => setSelectedCondition(value)}
          value={selectedCondition}
        ></Select>
      </View>
      <View style={styles.formRowLabel}>
        <Text>Amount</Text>
      </View>
      <View style={styles.formRow}>
        <TextInput
          onChangeText={(value) => onChangeNumber(value)}
          value={number}
          placeholder="Generation amount"
          keyboardType="numeric"
          autoComplete="off"
        />
      </View>
    </View >
  )
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    width: "100%",
    paddingleft: 12,
    paddingBottom: 2
  },

  formRow: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 6
  },

  formRowLabel: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 6,
    marginleft: 12,
  },

  input: {

  },
});
