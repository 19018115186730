import { formatDateTime, onMessageListener, onBackgroundMessageListener } from '@energynow/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, useWindowDimensions } from 'react-native';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addNotice, UserNotice } from '../../visualisations/user-notice/user-notice-slice';
import { Text } from '../text';
import { Button } from '../button';
import { DEVICE_WIDTH } from '../constants';

export type NotificationProps = {
};

export const Notification = React.forwardRef((props: NotificationProps, ref) => {
    const {
        ...otherProps
    } = props;

    const theme = useTheme();
    const { height, scale, width } = useWindowDimensions();
    const [notifications, setNotifications] = useState([] as Array<UserNotice>);
    const dispatch = useAppDispatch();
    const deleteTime = 5000;

    onMessageListener().then((payload: any) => {
        addNoticeToStore(payload);
    }).catch(err => console.log('failed: ', err));

    onBackgroundMessageListener().then((payload: any) => {
        addNoticeToStore(payload);
    }).catch(err => console.log('failed: ', err));

    const addNoticeToStore = (payload: any) => {
        console.log(payload);
        const userNotice: UserNotice = {
            noticeId: payload.data.noticeId,
            title: payload.notification.title,
            body: payload.notification.body,
            createdAt: formatDateTime(new Date(), 'dd/MM/yyyy HH:mm:ss'),
            link: ""
        };

        dispatch(addNotice(userNotice));
        setNotifications(oldNotices => [...oldNotices, userNotice]);
    };

    const removeFirstNotification = () => {
        if (notifications.length > 0) {
            setNotifications(oldNotices => {
                oldNotices.splice(0, 1);
                return [...oldNotices];
            });
        }
    };

    const removeAllNotifications = () => {
        if (notifications.length > 0) {
            setNotifications([]);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            removeFirstNotification();
        }, deleteTime * notifications.length);
    }, [notifications]);


    return (
        <View style={styles.container}>
            {notifications.length > 0 &&
                <View style={[
                    styles.notification,
                    { backgroundColor: theme.popoverBackgroundColor },
                    { width: width < DEVICE_WIDTH.sm ? width - 28 : 360 }
                ]}>
                    <View style={styles.titleContainer}>
                        <View style={styles.title}>
                            <Text>New Notification</Text>
                        </View>
                        <View style={styles.titleActions}>
                            <Button withText={false} icon='switcher' onPress={removeAllNotifications} />
                            <Button withText={false} icon='close' onPress={removeFirstNotification} />
                        </View>
                    </View>
                    <View style={styles.body}>
                        <Text h3={true}>{notifications.at(0)?.title}</Text>
                        <Text>{notifications.at(0)?.body}</Text>
                    </View>
                </View>
            }
        </View>
    );

});

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 14,
        right: 14,
        zIndex: 999999
    },

    notification: {
        position: 'relative',
        overflow: 'hidden',
        padding: 20,
        paddingRight: 10,
        borderRadius: 4,
        width: 300,
        height: 150,
        maxHeight: 150,
        shadowColor: '#0003',
        shadowRadius: 8,
        shadowOffset: { width: 0, height: 2 }
    },

    titleContainer: {
        flexDirection: 'row'
    },

    title: {
        flex: 1
    },

    titleActions: {
        flexDirection: 'row'
    },

    body: {
        flex: 1,
        marginTop: 10
    }
})
