import React, { useRef, useState } from 'react'
import { View, StyleSheet, TouchableWithoutFeedback, Linking } from 'react-native';
import { Text } from './text';
import Icon from 'react-native-vector-icons/AntDesign';
import { useTheme } from '../themes/theme';
import { Modal } from './modal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSignIn, setSignIn } from '../profile/profile-slice';
import { useKeycloak } from '../../hooks/keycloak';
import { Button } from './button';

export type NotAuthenticatedProps = {
  text?: string,
}

export function NotAuthenticated(props: NotAuthenticatedProps) {
  const {
    text = 'Sign in',
    ...otherProps
  } = props;
  const theme = useTheme();
  const signIn = useAppSelector(selectSignIn);
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();

  return (
    <Modal visible={signIn} onRequestClose={() => dispatch(setSignIn(false))}>
      <View style={styles.container} >
        <View style={styles.padding}>
          <View>
            <Text h1={true}>{text}</Text>
            <Text>Want to receive custom notifications such as when the price is over $X amount?</Text>
            <Text>Just create an account.</Text>
          </View>
          <View style={styles.row}>
            <Button style={styles.rowSpacer} large={true} fillWidth={true} withBorder={true} textAlign={'center'} icon='mail' text='Email' onPress={() => { keycloak?.login() }} />
            <Button style={styles.rowSpacer} large={true} fillWidth={true} withBorder={true} textAlign={'center'} icon='google' text='Google' onPress={() => { keycloak?.login({ idpHint: 'google' }) }} />
            <Text onPress={() => Linking.openURL(keycloak?.createLoginUrl() as string)}>New user? Register for free</Text>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },

  padding: {
    minWidth: 300,
    textAlign: 'center'
  },

  row: {
    flexDirection: 'column',
    margin: 30
  },

  rowSpacer: {
    marginBottom: 8
  },

  reload: {
    fontSize: 64
  }
});
