import React, { useRef, useState } from 'react';
import { StyleSheet, View, Image, Pressable, Linking, Animated } from 'react-native';
import { LayoutTop } from "./top";
import { LayoutTopLeft } from "./top-left";
import { LayoutCenter } from './center';
import { Notification } from '../common/notification';
import { NotAuthenticated } from '../common/not-authenticated';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { LayoutVisualisation } from './visualisation';
import { Button } from '../common/button';
import { Text } from '../common/text';
import { GenerationSplit } from '../visualisations/dispatch/generation-split';
import { parseDateTimeUrl } from '@energynow/core';
import { ThemeProvider, useTheme, themes, withTheme } from '../themes/theme';
import { useAppSelector } from '../../store/hooks';
import { selectTheme } from '../themes/theme-slice';
import { ThemedLayout } from './themed-layout';
import { HomeScreen } from '../screens/home';
import logoDark from '@energynow/components/assets/logo_small_dark.png';
import logoLight from '@energynow/components/assets/logo_small_light.png';
import logoTextDark from '@energynow/components/assets/logo_text_dark.png'
import logoTextLight from '@energynow/components/assets/logo_text_light.png'
import { Hoverable } from 'react-native-web-hover';
import { InterconnectorsMap } from '../visualisations/dispatch/interconnectors-map';
import { RangeDispatch } from '../visualisations/dispatch/range-dispatch';

export type LayoutProps = {
};

export function Layout(props: LayoutProps) {
  const {
    ...otherProps
  } = props;

  const Stack = createNativeStackNavigator();
  const linking = {
    prefixes: [
      'energydash://',
      'https://*.energydash.com.au',
      'https://www.energydash.com.au',
    ],
    config: {

      screens: {
        Visualisation: {
          path: 'visualisation',
          screens: {
            GenerationSplit: {
              path: 'visualisation/generation-split/:date/:groupBy/:region',
              parse: {
                date: (date: string) => parseDateTimeUrl(date),
              },
              exact: true,
            },
            InterconnectorsMap: {
              path: 'visualisation/interconnectors-map/:date',
              parse: {
                date: (date: string) => parseDateTimeUrl(date),
              },
              exact: true,
            },
            Dispatch: {
              path: 'visualisation/dispatch/:date/:category/:region',
              parse: {
                date: (date: string) => parseDateTimeUrl(date),
                region: (region: string) => region.split(','),
                category: (category: string) => category.split(',')
              },
              exact: true,
            }
          }
        },
        Home: '',
        NotFound: '*',
      },
    },
  };

  function NotFound() {
    return <Text>Something went wrong, are you sure you have the correct link</Text>
  }


  function Home() {
    return (
      <ThemedLayout>
        <HomeScreen />
      </ThemedLayout>
    );
  }

  function Visualisation() {

    return (
      <ThemedLayout>
        <ThemedVisualisation></ThemedVisualisation>
      </ThemedLayout>
    );
  }

  function ThemedVisualisation() {
    const theme = useTheme();
    const currentTheme = useAppSelector(selectTheme);
    const animatedValue = useRef(new Animated.Value(32)).current;
    const animatedValueText = useRef(new Animated.Value(0)).current;
    const [hoveredLogo, setHoveredLogo] = useState(false);
    const animateView = (toValue: number) => {
      Animated.timing(animatedValue, {
        toValue: toValue,
        duration: 200,
        useNativeDriver: false
      }).start();

      Animated.timing(animatedValueText, {
        toValue: toValue - 32,
        duration: 300,
        useNativeDriver: false
      }).start();

      return true;
    };



    return (<View style={[
      styles.container,
      { backgroundColor: theme.primaryBorderColor }
    ]}>
      <Stack.Navigator screenOptions={{ headerShown: false, }}>
        <Stack.Screen name="GenerationSplit" options={{ title: 'Generation Split' }} component={GenerationSplit} />
        <Stack.Screen name="InterconnectorsMap" options={{ title: 'Interconnectors Map' }} component={InterconnectorsMap} />
        <Stack.Screen name="Dispatch" options={{ title: 'Dispatch' }} component={RangeDispatch} />
      </Stack.Navigator>
      <Hoverable>
        {({ hovered }) =>

        (
          <Pressable
            style={[
              styles.logoPosition
            ]}
            onPress={() => {
              const link = "https://www.energydash.com.au";
              Linking.canOpenURL(link).then(supported => {
                if (supported) {
                  Linking.openURL(link);
                } else {
                  console.log("Unable to open URI: " + link);
                }
              });
            }}
          >
            <Animated.View style={[
              styles.logoContainer,
              { backgroundColor: theme.primaryBackgroundColor, borderColor: theme.primaryBorderColor },
              !!hovered && animateView(112) && { width: animatedValue },
              !hovered && animateView(32) && { width: animatedValue }
            ]}
            >
              <Image resizeMode="contain" source={currentTheme === 'dark' ? logoLight : logoDark} style={styles.logo} />
              {!!hovered && <Animated.View style={[
                styles.logoText,
                { width: animatedValueText }
              ]}>
                <Image resizeMode="contain" source={currentTheme === 'dark' ? logoTextLight : logoTextDark} style={styles.logoTextImage} />
              </Animated.View>}
            </Animated.View>
          </Pressable>
        )

        }
      </Hoverable>
    </View>)
  }

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={{ headerShown: false, title: 'EnergyDash' }}>
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="Visualisation" component={Visualisation} />
        <Stack.Screen name="NotFound" component={NotFound} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },

  logoPosition: {
    position: 'absolute',
    bottom: 50,
    left: 10,
  },

  logoContainer: {
    position: 'relative',
    borderRadius: 16,
    borderWidth: 1,
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    overflow: 'hidden'
  },

  logoContainerActive: {
    width: 112,
  },

  logo: {
    position: 'absolute',
    top: 6,
    left: 6,
    alignSelf: 'center',
    height: 18,
    marginBottom: 2,
    width: 18,
  },

  logoText: {
    position: 'absolute',
    left: 24,
    top: 6,
    alignSelf: 'center',
    marginHorizontal: 3,
  },

  logoTextImage: {
    height: 20,
    width: 70,
  },
})
