import React from 'react';
import { Provider as ReduxProvider } from 'react-redux'
import { Layout } from "./layout/layout";
import { keycloak, apolloClient } from '@energynow/core';
import { ApolloProvider } from '@apollo/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { store } from '../store/store';
import { View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';

export function App(props: any) {
  return (

    <ReactKeycloakProvider
      authClient={keycloak as any}
      initOptions={{
        onLoad: 'check-sso',
        redirectUrl: 'myapp://Homepage'
      }}
      LoadingComponent={<View><Text>Loading</Text></View>}
    >
      <ApolloProvider client={apolloClient as any}>
        <ReduxProvider store={store}>
          <Layout />
        </ReduxProvider>
      </ApolloProvider>
    </ReactKeycloakProvider>

  )
}
