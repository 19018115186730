
import React from 'react';

export async function Share(message: string, url: string) {
  const shareData = {
    title: 'EnergyDash',
    text: message,
    url: url
  };

  try {
    await navigator.share(shareData)

  } catch (err) {
    console.log('Error: ' + err);
  }
};