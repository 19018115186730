import React, { useImperativeHandle, useRef, useState } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useTheme } from '../themes/theme';
import { DEVICE_WIDTH } from './constants';
import { Menu } from './menu';
import { ModalSlide } from './modal-slide';
import { Popover } from './popover';
import { PopoverPosition } from './popover/types';
import { ToolbarButton, ToolbarButtonProps } from './toolbar-button';
export * from './popover/types';

export type MenuButtonProps = ToolbarButtonProps & {
  responsive?: boolean,
  position?: PopoverPosition,
  showMenu?: boolean,
  onHide?: () => void,
  onShow?: () => void,
  children?: React.ReactNode
};

export const MenuButton = React.forwardRef((props: MenuButtonProps, ref: any) => {
  const {
    responsive = true,
    position = 'bottom',
    showMenu = false,
    onHide,
    onShow,
    children,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [showPopover, setShowPopover] = useState(false);
  const menu = useRef(null) as any;
  const { height, scale, width } = useWindowDimensions();

  useImperativeHandle(ref, () => ({
    hide(event: any) {
      hide();
    }
  }));

  const hide = () => {
    setShowPopover(false);
    if (onHide)
      onHide();
  };

  const show = () => {
    setShowPopover(true);
    if (onShow)
      onShow();
  };

  return (
    <View ref={ref}>
      {responsive && width < DEVICE_WIDTH.sm ?
        <View style={styles.buttonContainer}>
          <ToolbarButton {...props} onPress={show} />
          <ModalSlide visible={showPopover || showMenu} onDismiss={hide}>
            <View>
              {children}
            </View>
          </ModalSlide>
        </View>
        :
        <Popover
          visible={showPopover || showMenu}
          position={position}
          onClose={hide}
          content={(
            <Menu >
              {children}
            </Menu>
          )}
          children={(
            <ToolbarButton {...props} onPress={show} />
          )}
        ></Popover>

      }
    </View>
  )
});

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
})