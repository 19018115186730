import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { Popover } from '../popover';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { PopoverPosition } from '../popover/types';
import * as d3 from 'd3';
import { ScaleBand } from 'd3';
import { isFunction, parseDate, resolveFieldData } from '@energynow/core';
import { Path } from 'react-native-svg';

export type LineProps = {
  xScale: any,
  yScale: any,
  data: any,
  color?: string,
  disableAnimation?: boolean,
  valueField: ((item: any) => any) | string,
  categoryField: string,
  transform?: string,
  dash?: boolean,
  step?: boolean,
  strokeWidth?: number
};

export const Line = React.forwardRef((props: LineProps, ref) => {
  const {
    xScale,
    yScale,
    data = [],
    color = "black",
    disableAnimation = false,
    valueField,
    categoryField,
    transform,
    dash,
    step = false,
    strokeWidth = 1.5,
    ...otherProps
  } = props;

  const theme = useTheme();
  const svgRef = useRef() as any;

  const line = d3
    .line()
    .x((item: any) => xScale(resolveFieldData(item, categoryField)))
    .y((item: any) => yScale(isFunction(valueField) ? (valueField as any)(item) : resolveFieldData(item, valueField)));

  if (step === true) {
    line.curve(d3.curveStep);
  }

  const d = line(data) as any;

  return (
    <Path
      ref={svgRef}
      d={d?.match(/NaN|undefined/) ? "" : d}
      stroke={color}
      strokeWidth={strokeWidth}
      fill="none"
      opacity={1}
      transform={transform}
      strokeDasharray={dash ? 3 : 0}
    />
  )
});

const styles = StyleSheet.create({
})