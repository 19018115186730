import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet, View, Dimensions } from 'react-native'
import { ThemeProvider, useTheme } from '../../themes/theme'
import Svg, { G, Rect } from 'react-native-svg'
import { addMinutes } from 'date-fns';
import { hexToRgb } from '@energynow/core'
import * as d3 from 'd3';
import {
  formatDateTime,
  useMax,
  useMin,
  useScaleBand,
  useScaleLinear,
  useScaleTime,
  Area,
  Line,
  Axis,
  Overlay,
  formatNumber
} from '../../common/chart'
import { CursorPosition } from '../../common/chart/overlay/cursor'
import { Cursor } from '../../common/chart/cursor'

export type RangeChartProps = {
  width: number
  height: number
  data: any[]
  valueField: string
  stackField: string
  categoryField: string
  stackFieldColorMapping: (e: any) => string
  useXScale?: (minX: any, minY: any, width: number) => any
  useXCursor?: boolean,
  xTickFormatter?: (e: any) => any
  yTickFormatter?: (e: any) => any
}

export const RangeStackedBarChart = React.forwardRef(
  (props: RangeChartProps, ref) => {
    const {
      width,
      height,
      data = [],
      valueField,
      categoryField,
      stackField,
      useXScale,
      useXCursor = true,
      xTickFormatter = (e: any) => e.toString(),
      yTickFormatter = (e: any) => e.toString(),
      stackFieldColorMapping,
      ...otherProps
    } = props

    const theme = useTheme()
    const overlayRef = useRef<Rect>();
    const [cursorPosition, setCursorPosition] = useState<CursorPosition>();

    const colors = theme.chartColors;

    const processData = () => {
      const intervalDict: any = {};
      let minInterval = new Date(9999, 1, 1);
      let minInterval2 = new Date(9999, 1, 1);
      for(let row of data) {
        if (row[categoryField] < minInterval) {
          minInterval2 = minInterval;
          minInterval = row[categoryField];
        }
        if (row[categoryField] > minInterval && row[categoryField] < minInterval2) {
          minInterval2 = row[categoryField];
        }

        if (!intervalDict[row[categoryField]]) {
          intervalDict[row[categoryField]] = {};
        }

        if (row[valueField] > 0) {
          intervalDict[row[categoryField]][row[stackField]] = row[valueField]
        }
      }

      const intervalLength = Math.abs((minInterval.getTime() - minInterval2.getTime())/1000/60)

      return { data: intervalDict, intervalLength: intervalLength };
    }

    const processedData = useMemo(() => processData(), [data, valueField, categoryField, stackField]);

    const barWidth = width / (Object.keys(processedData.data).length + 1);

    const xMin = addMinutes(useMin(data, categoryField), -processedData.intervalLength)
    const xMax = addMinutes(xMin, (Object.keys(processedData.data).length + 1) * processedData.intervalLength)
    const defaultXScale = useScaleTime(xMin, xMax, width);
    const xScale = useXScale ? useXScale(xMin, xMax, width) : defaultXScale;
    /*const xMin = -1;
    const xMax = 7; 
    const xScale = d3
      .scaleLinear()
      .domain([xMin, xMax])
      .range([0, width]);
    const newxTickFormatter = (e: any) => {
      while (e < 0) {
        e += 12;
      }

      const mappings = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
      return mappings[e];
    }*/

    let yMin = 0;
    let yMax = 0;
    for (let interval in processedData.data) {
      let sumValue = 0;
      for (let stackType in processedData.data[interval]) {
        sumValue += processedData.data[interval][stackType];
      }

      if (sumValue < yMin) {
        yMin = sumValue;
      }
      if (sumValue > yMax) {
        yMax = sumValue;
      }
    }

    const yScale = useScaleLinear(yMin, yMax, height)
    const yScaleForAxis = useScaleBand(yMin, yMax, height)

    const stackedBar = (interval: any, fuelTypeData: any, index: number) => {
      let stackedValues = [0]
      for(let key in fuelTypeData) {
        stackedValues.push(stackedValues[stackedValues.length - 1] + fuelTypeData[key])
      }
      
      return (Object.entries(fuelTypeData).map(([fuelType, value], innerIndex: number) => {
        const rgbColor = hexToRgb(stackFieldColorMapping ? stackFieldColorMapping(fuelType) : colors[innerIndex])
        const yHeight = yScale(0) - yScale(value as any)
        //don't know why I need the 45. it seems the axis is moved up so I just added this as a hack to line it up properly
        const yPosition = height - (yScale(0) - yScale(stackedValues[innerIndex] as any)) - yHeight - 45;

        return (<Rect
          x={index * barWidth + barWidth * 2 / 3}
          y={yPosition}
          width={barWidth * 2 / 3}
          height={yHeight}
          key={`${index}-${innerIndex}`}
          fill={`rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 1)`}
        />)
      }))
    }

    return (
      <Svg width={width} height={height}>
        <G>

          <Overlay width={width} height={height} ref={overlayRef as any} onCursor={(event: CursorPosition) => setCursorPosition(event)}>

            <Axis
              orient="bottom"
              scale={xScale}
              transform={`translate(0, ${height - 30})`}
              ticks={100 > (width/(Object.keys(processedData.data).length + 2)) ? width/100 : (Object.keys(processedData.data).length + 2)}
              tickFormat={xTickFormatter}
              domain={false}
              tickSize={height}
              anchorEl={overlayRef}
            />

            { Object.entries(processedData.data).map(([interval, fuelTypeData], index: number) => stackedBar(interval, fuelTypeData, index)) }

            <Cursor
              cursorPosition={cursorPosition}
              data={data}
              xScale={xScale}
              yScale={yScale}
              valueField={valueField}
              categoryField={categoryField}
              height={height}
              width={width}
              useXTick={useXCursor}
              axisTooltipWidth={110}
              xTickFormatter={xTickFormatter}
              yTickFormatter={yTickFormatter}
            ></Cursor>
          </Overlay>

          <Axis
            orient="left"
            scale={yScale}
            transform="translate(10, 0)"
            ticks={height / 100}
            domain={false}
            tickFormat={formatNumber}
            tickSize={width} />

        </G>
      </Svg>
    )
  },
)

const styles = StyleSheet.create({})
