import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { Popover } from '../../popover';
import { ThemeProvider, useTheme } from '../../../themes/theme';
import { PopoverPosition } from '../../popover/types';
import * as d3 from 'd3';
import { ScaleBand } from 'd3';
import { isFunction, parseDate, resolveFieldData } from '@energynow/core';
import { CursorPosition } from "./cursor";

export type OverlayProps = {
  width: number,
  height: number,
  onCursor?: (event: CursorPosition) => void,
  children?: React.ReactNode
};

export const Overlay = React.forwardRef<any, OverlayProps>((props: OverlayProps, ref) => {
  const {
    width,
    height,
    onCursor,
    children
  } = props;

  const theme = useTheme();
  const svgRef = useRef() as any;
  let lastCallback = Date.now();
  const throttle = 20;

  const onMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    event.preventDefault();

    let currentTime = Date.now();
    if (currentTime - lastCallback > throttle) {
      lastCallback = currentTime;
      const pagePosition = event.currentTarget.getBoundingClientRect();
      if (onCursor) {
        onCursor({
          x: event.clientX - pagePosition.x,
          y: event.clientY - pagePosition.y
        });
      }
    }
  };

  const onMouseOut = (event: React.MouseEvent<SVGRectElement>) => {
    event.preventDefault();
    if (onCursor) {
      onCursor({
        x: undefined,
        y: undefined
      });
    }
  };


  return (
    <g>
      {children}
      <rect ref={ref as any} width={width} height={height} fill={"#ffffff"} opacity={0} onMouseMove={onMouseMove} onMouseOut={onMouseOut} />
    </g>
  )
});

const styles = StyleSheet.create({
})