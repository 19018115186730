import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { ToolbarButton } from '../common/toolbar-button';
import { ThemeProvider, useTheme } from '../themes/theme';
import { CurrentMarketNotices } from '../visualisations/market-notice/current-market-notices';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { Panel, selectRightPanel, setRightPanel, setSettings } from './layout-slice';
import { ScrollView } from 'react-native-web-hover';
import { Text } from '../common/text';
import { Button } from '../common/button';
import { UserNotices } from '../visualisations/user-notice/user-notices';

export type LayoutCenterRightProps = {
  visible?: boolean
  withBorder?: boolean
  width?: string | number
};

export function LayoutCenterRight(props: LayoutCenterRightProps) {
  const {
    visible = true,
    withBorder = true,
    width,
    ...otherProps
  } = props;

  const theme = useTheme();
  const rightPanel = useAppSelector(selectRightPanel);
  const dispatch = useAppDispatch();

  if (visible) {
    return (
      <View style={[
        styles.layoutRight,
        !!withBorder && styles.layoutRightBorder,
        { backgroundColor: theme.primaryBackgroundColor },
        !!width && { width: width }
      ]}>
        <View style={[
          styles.headerContainer
        ]}>
          <View style={[styles.leftHeader]}>
            <Text h3={true}>
              {rightPanel}
            </Text>
          </View>
          <View style={[styles.rightHeader]}>
            <View style={[styles.rightActions]}>
              {rightPanel === Panel.notifications && <Button icon="setting" withText={false} onPress={() => dispatch(setSettings('Notifications'))} ></Button>}
              <Button icon="close" withText={false} onPress={() => dispatch(setRightPanel(undefined))} ></Button>
            </View>
          </View>
        </View>
        <View style={styles.bodyContainer}>
          {rightPanel === Panel.marketNotices && <CurrentMarketNotices />}
          {rightPanel === Panel.notifications && <UserNotices />}
        </View>
      </View>
    )
  } else {
    return (<View></View>);
  }
}

const styles = StyleSheet.create({
  layoutRight: {
    display: "flex",
    alignItems: "center",
    width: 360,
    flexDirection: "column",
    backgroundColor: "#FFF",
    marginTop: 1,
    overflow: 'hidden',
    fontVariant: ''
  },

  layoutRightBorder: {
    marginLeft: (8 / 2)
  },

  headerContainer: {
    display: "flex",
    flexDirection: 'row',
    paddingHorizontal: 12,
    minHeight: 40,
    width: "100%"
  },

  leftHeader: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },

  rightHeader: {
    flexDirection: "column",
    justifyContent: "center",
  },

  rightActions: {
    flexDirection: 'row'
  },

  bodyContainer: {
    flex: 1,
    width: "100%"
  }
})