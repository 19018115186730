// Security precaution
; (window as any).eval = global.eval = (payload: string) => {
  const error = new Error(`This app does not allow window.eval().`)
  Object.assign(error, { payload })

  throw error
}

import 'react-app-polyfill/stable'
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

import localforage from 'localforage'
import { ComponentType } from 'react'
import { AppRegistry } from 'react-native-web'

// Generate required css
import Icon from 'react-native-vector-icons/dist/AntDesign';
import iconFont from 'react-native-vector-icons/Fonts/AntDesign.ttf';

import './index.css'

import { App } from '@energynow/components/src/components/app'
import { getDispatchHub } from '@energynow/core'

localforage.config({
  name: 'energynow',
  size: 100 * 1024 * 1024,
})

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: AntDesign;
}`;

// Create stylesheet
const style: any = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

// Reload when page sleeps 
document.addEventListener('visibilitychange', function () {
  if (document.visibilityState == 'visible' && getDispatchHub().state === 'Disconnected') {
    this.location.reload();
  }
});

const render = (AppComponent: ComponentType) => {
  AppRegistry.registerComponent('energynow', () => AppComponent)
  AppRegistry.runApplication('energynow', {
    rootTag: document.getElementById('root'),
  })
}

render(App)

/*
if (typeof module !== 'undefined' && (module as any).hot) {
  ;(module as any).hot.accept('@energynow/components/src/components/App', () => {
    const NewApp = require('@energynow/components/src/components/App').App
    render(NewApp)
  })
}
*/
