import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Animated } from 'react-native';
import { ThemeProvider, useTheme } from '../themes/theme';
import { Button } from '../common/button';
import { Text } from '../common/text';
import { MenuItem } from '../common/menu-item';
import { Switch } from '../common/switch';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setNotificationAsync, selectNotification, setNotification } from '../modals/settings-slice';
import { Select } from '../common/select';
import { NotificationType, NOTIFICATION_TYPE_OPTIONS } from './constants';
import { GenerationNotification } from './generation';
import { GenerationFuelNotification } from './generation-fuel';
import { DemandNotification } from './demand';
import { PriceNotification } from './price';
import { MarketNoticeNotification } from './market-notice';
import { Menu } from '../common/menu';

export type NotificationsProps = {

};

export const Notifications = React.forwardRef((props: NotificationsProps, ref) => {
  const {
    ...otherProps
  } = props;

  const theme = useTheme();
  const menu = useRef() as any;

  const startingHeight = 0;
  const notificationSettings = useAppSelector(selectNotification);
  const [switchEnabled, setSwitchEnabled] = useState(false);
  const [addNotification, setAddNotification] = useState(notificationSettings?.length > 0 ? false : true);
  const [selectedItem, setSelectedItem] = useState(NotificationType.Generation);
  const [selectedItemForm, setSelectedItemForm] = useState(null);
  const [fullHeight, setFullHeight] = useState(startingHeight);
  const animatedHeight = useRef(new Animated.Value(startingHeight)).current;

  const dispatch = useAppDispatch();

  const createNotification = () => {
    setAddNotification(false);

    dispatch(setNotificationAsync({
      enabled: true,
      notificationType: selectedItem,
      subscribedOn: new Date(),
      parameters: JSON.stringify(selectedItemForm)
    }));
  };

  useEffect(() => {
    setAddNotification(notificationSettings?.length > 0 ? false : true);
  }, [notificationSettings]);

  useEffect(() => {
    Animated.spring(animatedHeight, {

      speed: 200,
      toValue: addNotification ? fullHeight : startingHeight,
      useNativeDriver: false
    }).start();
  }, [addNotification]);

  const onLayout = (e: any) => {
    let { x, y, width, height } = e.nativeEvent.layout;
    height = Math.floor(height) + 40;
    if (height > startingHeight) {
      setFullHeight(height);
    }
  };

  const renderNotifications = () => {
    if (notificationSettings && notificationSettings.length) {
      return notificationSettings.map((notificationSetting, index) => {
        const notificationType = NOTIFICATION_TYPE_OPTIONS.find(x => x.value === notificationSetting.notificationType);
        const notificationLabel = `${notificationType?.label}     ${notificationType?.toParamString(JSON.parse(notificationSetting.parameters))}`;
        return <MenuItem key={`${notificationLabel}_${index}`} text={notificationLabel}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Switch value={notificationSetting.enabled} onValueChange={async (value) => {
              dispatch(setNotificationAsync({
                notificationId: notificationSetting.notificationId,
                enabled: value,
                notificationType: notificationSetting.notificationType,
                subscribedOn: notificationSetting.subscribedOn,
                parameters: notificationSetting.parameters
              }));

            }}></Switch>
            <Button icon='close' withText={false} onPress={() => {
              dispatch(setNotificationAsync({
                notificationId: notificationSetting.notificationId,
                enabled: notificationSetting.enabled,
                remove: true,
                notificationType: notificationSetting.notificationType,
                subscribedOn: notificationSetting.subscribedOn,
                parameters: notificationSetting.parameters
              }));
            }}></Button>
          </View>
        </MenuItem>

      });
    }

    return null;
  }

  const renderedNotifications = renderNotifications();

  return (
    <View style={styles.container}>
      <View style={styles.addFormRow}>
        <View>
          <Text h1={true} fontWeight="400">Notifications</Text>
        </View>
      </View>
      <View style={styles.addFormRow}>
        <Text>Get notifications you care about by creating custom notifications. Use the devices menu to enable/disable which devices receive notifications.</Text>
      </View>
      <View style={styles.headerRow}>
        {!addNotification && <View style={styles.addContainer}>
          <Button text='Create Notification' icon='pluscircleo' large={true} onPress={() => {
            setAddNotification(true);
            setSelectedItemForm(null);
          }}></Button>
        </View>}
      </View>
      {!!addNotification && <Animated.View style={[styles.addForm, { height: animatedHeight, borderColor: theme.primaryBorderColor, borderWidth: !!addNotification ? 1 : 0 }]}>

        <View style={{}} onLayout={e => { onLayout(e) }}>
          {/* <View>
              <Text h2={true}>Create Notification</Text>
            </View> */}
          <View style={styles.formRowLabel}>
            <Text>Notification Type</Text>
          </View>
          <View style={styles.addFormRow}>
            <Select
              multiple={false}
              large={true}
              dropDown={true}
              options={NOTIFICATION_TYPE_OPTIONS}
              onChange={(value) => {
                setSelectedItem(value);
                setSelectedItemForm(null);
              }}
              value={selectedItem}
              title='Notification Type'
            ></Select>
          </View>
          {selectedItem === NotificationType.Generation && <GenerationNotification onChange={(value) => setSelectedItemForm(value)}></GenerationNotification>}
          {selectedItem === NotificationType.GenerationFuel && <GenerationFuelNotification onChange={(value) => setSelectedItemForm(value)}></GenerationFuelNotification>}
          {selectedItem === NotificationType.Demand && <DemandNotification onChange={(value) => setSelectedItemForm(value)}></DemandNotification>}
          {selectedItem === NotificationType.Price && <PriceNotification onChange={(value) => setSelectedItemForm(value)}></PriceNotification>}
          {selectedItem === NotificationType.MarketNotice && <MarketNoticeNotification onChange={(value) => setSelectedItemForm(value)}></MarketNoticeNotification>}
          <View style={styles.addFormActions}>
            <Button text='Create Notification' branded={true} onPress={() => createNotification()} disabled={selectedItemForm === null}></Button>
            <Button text='Cancel' onPress={() => setAddNotification(false)}></Button>
          </View>
        </View>

      </Animated.View>}
      {notificationSettings && notificationSettings.length > 0 &&
        < View style={[styles.notifications, { borderColor: theme.primaryBorderColor }]} >
          {renderedNotifications}
        </View >}
    </View >
  )
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    width: "100%",
    paddingleft: 12,
    paddingBottom: 2
  },

  headerRow: {
    flexDirection: "row",
    flex: 1,
    alignItems: 'flex-start',
    paddingBottom: 6
  },

  addContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: "flex-end",
    marginTop: 30
  },

  addForm: {
    flexDirection: "column",
    flex: 0,
    flexShrink: 1,
    overflow: 'hidden',
    borderWidth: 1,
    borderRadius: 4,
    padding: 10,
    marginTop: 30
  },

  addFormRow: {
    flexDirection: "row",
    flex: 1,
    paddingBottom: 20
  },

  addFormActions: {
    flexDirection: "row",
    flex: 1,
    alignSelf: "flex-end",
    marginTop: 20,
    paddingBottom: 20
  },

  notifications: {
    flexDirection: "column",
    flex: 1,
    borderTopWidth: 1,
    paddingTop: 12
  },

  formRowLabel: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 6,
    marginleft: 12,
  },
})
