import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store';
import { keycloak, apolloClientAuth, UPDATE_DEVICES, UPDATE_NOTIFICATION, storage } from '@energynow/core';
import { NotificationType } from '../notifications/constants';

export interface SettingsState {
  devices: Array<DeviceDetails>;
  notifications: Array<Notification>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SettingsState = {
  devices: [],
  notifications: [],
  status: 'idle',
};

export interface DeviceDetails {
  notificationsEnabled: boolean,
  deviceToken: string,
  deviceId: string,
  device: string,
  remove?: boolean
}

export interface Notification {
  notificationId?: string,
  enabled: boolean,
  notificationType: NotificationType,
  subscribedOn: Date,
  parameters: string,
  remove?: boolean
}

export const setDeviceAsync = createAsyncThunk(
  'settings/setDevice',
  async (device: DeviceDetails) => {
    if (!!keycloak?.authenticated) {
      const { data } = await apolloClientAuth.mutate({
        mutation: UPDATE_DEVICES,
        variables: {
          device: {
            device: device
          }
        }
      });

      if (data) {
        device.deviceId = data.updateDevice?.deviceId;
        storage.set("deviceId", device.deviceId);
      }
    }
    // The value we return becomes the `fulfilled` action payload
    return device;
  }
);

export const setNotificationAsync = createAsyncThunk(
  'settings/setNotification',
  async (notification: Notification) => {
    if (!!keycloak?.authenticated) {
      const { data } = await apolloClientAuth.mutate({
        mutation: UPDATE_NOTIFICATION,
        variables: {
          notification: {
            notification: notification
          }
        }
      });

      if (data) {
        notification.notificationId = data.updateNotification?.notificationId;
      }
    }

    // The value we return becomes the `fulfilled` action payload
    return notification;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDevice: (state, action: PayloadAction<Array<any>>) => {
      state.devices = action.payload;
    },
    setNotification: (state, action: PayloadAction<Array<any>>) => {
      state.notifications = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(setDeviceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setDeviceAsync.fulfilled, (state, action: any) => {
        state.status = 'idle';
        let existingDeviceIndex = state.devices.findIndex(x => x.deviceId === action.payload.deviceId);
        if (existingDeviceIndex > -1) {
          if (action.payload.remove) {
            state.devices.splice(existingDeviceIndex, 1);
          } else {
            state.devices[existingDeviceIndex] = action.payload;
          }
        } else {
          state.devices.push(action.payload);
        }
      })
      .addCase(setDeviceAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(setNotificationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setNotificationAsync.fulfilled, (state, action: any) => {
        state.status = 'idle';
        let existingNotificationIndex = state.notifications.findIndex(x => x.notificationId === action.payload.notificationId);
        if (existingNotificationIndex > -1) {
          if (action.payload.remove) {
            state.notifications.splice(existingNotificationIndex, 1);
          } else {
            state.notifications[existingNotificationIndex] = action.payload;
          }
        } else {
          state.notifications.push(action.payload);
        }
      })
      .addCase(setNotificationAsync.rejected, (state) => {
        state.status = 'failed';
      });;
  },
});

export const { setDevice, setNotification } = settingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDevice = (state: RootState) => state.settings.devices
export const selectNotification = (state: RootState) => state.settings.notifications

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// Kept in as an example for now but not required
export const trySetDevice = (devices: Array<any>): AppThunk => (
  dispatch,
  getState
) => {
  const currentDevices = selectDevice(getState());
  if (currentDevices !== devices) {
    dispatch(setDevice(devices));
  }
};

export default settingsSlice.reducer;
