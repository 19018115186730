import React, { useRef } from 'react'
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { Text } from './text';
import Icon from 'react-native-vector-icons/AntDesign';
import { useTheme } from '../themes/theme';

export type NoDataProps = {
  text?: string,
}

export function NoData(props: NoDataProps) {
  const {
    text = 'No data found for the seleceted date',
    ...otherProps
  } = props;
  const theme = useTheme();

  return (
    <View style={styles.container} >
      <View style={styles.padding}>

      </View>
      <View style={[styles.animate]}>
        <Icon style={[styles.icon, { color: theme.primaryColor }]} name='search1' />
      </View>
      <View style={styles.padding}>
        <Text>{text}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  animate: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  padding: {
    flex: 1.5
  },
  icon: {
    fontSize: 42
  }
});
