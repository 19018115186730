import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, useWindowDimensions } from 'react-native';
import Svg, { Circle, G, Line } from 'react-native-svg';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { Path } from '../svg';

export const EnergyDashIcon = React.forwardRef((props: any, ref) => {
  const theme = useTheme();

  return (
    <Svg width="100" height="22" viewBox="0 0 180 36" fill="none">
      <Path d="M40.517 27.8182C40.087 27.8182 39.7547 27.7102 39.5202 27.4942C39.3052 27.2585 39.1977 26.9247 39.1977 26.4927V8.37818C39.1977 7.94618 39.3052 7.62218 39.5202 7.40618C39.7547 7.17054 40.087 7.05273 40.517 7.05273H51.4231C51.7554 7.05273 52.0095 7.14109 52.1854 7.31782C52.3613 7.49454 52.4493 7.74 52.4493 8.05418C52.4493 8.388 52.3613 8.64327 52.1854 8.82C52.0095 8.99673 51.7554 9.08509 51.4231 9.08509H41.5431V16.272H50.8075C51.1397 16.272 51.3938 16.3604 51.5697 16.5371C51.7456 16.6942 51.8336 16.9396 51.8336 17.2735C51.8336 17.6073 51.7456 17.8625 51.5697 18.0393C51.3938 18.216 51.1397 18.3044 50.8075 18.3044H41.5431V25.7858H51.4231C51.7554 25.7858 52.0095 25.8742 52.1854 26.0509C52.3613 26.2276 52.4493 26.4731 52.4493 26.7873C52.4493 27.1211 52.3613 27.3764 52.1854 27.5531C52.0095 27.7298 51.7554 27.8182 51.4231 27.8182H40.517Z" fill={theme.primaryColor} />
      <Path d="M55.5278 28.0244C55.1369 28.0244 54.8438 27.9164 54.6483 27.7004C54.4529 27.4844 54.3551 27.18 54.3551 26.7873V14.5047C54.3551 14.112 54.4529 13.8175 54.6483 13.6211C54.8438 13.4051 55.1272 13.2971 55.4985 13.2971C55.8699 13.2971 56.1533 13.4051 56.3487 13.6211C56.5637 13.8175 56.6712 14.112 56.6712 14.5047V17.0378L56.3487 16.6844C56.7592 15.5455 57.4237 14.6913 58.3423 14.1218C59.2805 13.5327 60.3555 13.2382 61.5673 13.2382C62.7009 13.2382 63.6391 13.4444 64.3818 13.8567C65.144 14.2691 65.7108 14.8975 66.0822 15.7418C66.4535 16.5665 66.6392 17.6171 66.6392 18.8935V26.7873C66.6392 27.18 66.5317 27.4844 66.3167 27.7004C66.1213 27.9164 65.8379 28.0244 65.4665 28.0244C65.0756 28.0244 64.7727 27.9164 64.5577 27.7004C64.3622 27.4844 64.2645 27.18 64.2645 26.7873V19.0407C64.2645 17.7055 64.0006 16.7335 63.4729 16.1247C62.9647 15.4964 62.1439 15.1822 61.0102 15.1822C59.7007 15.1822 58.6551 15.5945 57.8733 16.4193C57.111 17.2244 56.7299 18.3044 56.7299 19.6593V26.7873C56.7299 27.612 56.3292 28.0244 55.5278 28.0244Z" fill={theme.primaryColor} />
      <Path d="M75.8262 28.0833C74.3212 28.0833 73.0215 27.7887 71.9269 27.1996C70.852 26.5909 70.0115 25.7367 69.4056 24.6371C68.8193 23.5375 68.5261 22.2218 68.5261 20.6902C68.5261 19.1978 68.8193 17.9018 69.4056 16.8022C69.992 15.6829 70.7933 14.8091 71.8097 14.1807C72.8456 13.5524 74.0378 13.2382 75.3864 13.2382C76.3441 13.2382 77.2041 13.4051 77.9664 13.7389C78.7286 14.0531 79.3736 14.5145 79.9013 15.1233C80.4486 15.732 80.859 16.4684 81.1327 17.3324C81.4258 18.1964 81.5724 19.1684 81.5724 20.2484C81.5724 20.5625 81.4845 20.7982 81.3086 20.9553C81.1327 21.0927 80.8786 21.1615 80.5463 21.1615H70.3438V19.6004H79.9893L79.5202 19.9833C79.5202 18.9229 79.3638 18.0295 79.0511 17.3029C78.7384 16.5567 78.2791 15.9873 77.6732 15.5945C77.0868 15.2018 76.3441 15.0055 75.4451 15.0055C74.4482 15.0055 73.598 15.2411 72.8944 15.7124C72.2103 16.164 71.6924 16.8022 71.3406 17.6269C70.9888 18.432 70.8129 19.3745 70.8129 20.4545V20.6313C70.8129 22.4378 71.2429 23.8124 72.1028 24.7549C72.9824 25.6975 74.2235 26.1687 75.8262 26.1687C76.4907 26.1687 77.1455 26.0804 77.7905 25.9036C78.455 25.7269 79.1 25.4324 79.7254 25.02C79.9991 24.8433 80.2434 24.7647 80.4584 24.7844C80.6929 24.7844 80.8786 24.8531 81.0154 24.9905C81.1522 25.1084 81.2402 25.2655 81.2793 25.4618C81.3379 25.6385 81.3184 25.8349 81.2206 26.0509C81.1424 26.2669 80.9763 26.4535 80.7222 26.6105C80.0772 27.0818 79.315 27.4451 78.4354 27.7004C77.5559 27.9556 76.6862 28.0833 75.8262 28.0833Z" fill={theme.primaryColor} />
      <Path d="M84.5988 28.0244C84.2079 28.0244 83.9049 27.9164 83.6899 27.7004C83.4945 27.4844 83.3968 27.18 83.3968 26.7873V14.5047C83.3968 14.112 83.4945 13.8175 83.6899 13.6211C83.8854 13.4051 84.1688 13.2971 84.5402 13.2971C84.9115 13.2971 85.1949 13.4051 85.3904 13.6211C85.6054 13.8175 85.7129 14.112 85.7129 14.5047V16.8905H85.4197C85.7324 15.732 86.3285 14.8385 87.2081 14.2102C88.0876 13.5818 89.1723 13.248 90.4623 13.2087C90.7555 13.1891 90.99 13.2578 91.1659 13.4149C91.3419 13.5524 91.4396 13.7978 91.4591 14.1513C91.4787 14.4851 91.4005 14.7502 91.2246 14.9465C91.0487 15.1429 90.775 15.2607 90.4037 15.3L89.9346 15.3589C88.6055 15.4767 87.5794 15.9087 86.8563 16.6549C86.1526 17.3815 85.8008 18.3829 85.8008 19.6593V26.7873C85.8008 27.18 85.6933 27.4844 85.4783 27.7004C85.2829 27.9164 84.9897 28.0244 84.5988 28.0244Z" fill={theme.primaryColor} />
      <Path d="M98.4232 33.3851C97.3873 33.3851 96.371 33.2575 95.3742 33.0022C94.3969 32.7665 93.5076 32.3836 92.7063 31.8535C92.4717 31.716 92.3154 31.5491 92.2372 31.3527C92.1786 31.1564 92.1688 30.96 92.2079 30.7636C92.247 30.5869 92.3349 30.42 92.4717 30.2629C92.6085 30.1255 92.7649 30.0371 92.9408 29.9978C93.1363 29.9585 93.3317 29.988 93.5272 30.0862C94.3871 30.6164 95.208 30.9796 95.9898 31.176C96.7717 31.3724 97.5437 31.4705 98.3059 31.4705C99.6936 31.4705 100.739 31.0975 101.443 30.3513C102.166 29.6051 102.528 28.5251 102.528 27.1113V23.7829H102.821C102.528 24.9218 101.902 25.8251 100.945 26.4927C100.006 27.1604 98.9021 27.4942 97.6316 27.4942C96.3221 27.4942 95.1885 27.1996 94.2308 26.6105C93.2731 26.0018 92.5304 25.1575 92.0026 24.0775C91.4945 22.9975 91.2404 21.7505 91.2404 20.3367C91.2404 19.2764 91.387 18.3142 91.6802 17.4502C91.9733 16.5665 92.3935 15.8204 92.9408 15.2116C93.5076 14.5833 94.1819 14.1022 94.9637 13.7684C95.7651 13.4149 96.6544 13.2382 97.6316 13.2382C98.9216 13.2382 100.036 13.572 100.974 14.2396C101.912 14.8876 102.518 15.7713 102.792 16.8905L102.498 17.244V14.5047C102.498 14.112 102.596 13.8175 102.792 13.6211C103.007 13.4051 103.3 13.2971 103.671 13.2971C104.062 13.2971 104.355 13.4051 104.551 13.6211C104.746 13.8175 104.844 14.112 104.844 14.5047V26.7873C104.844 28.9669 104.297 30.6065 103.202 31.7062C102.127 32.8255 100.534 33.3851 98.4232 33.3851ZM98.0714 25.5502C98.99 25.5502 99.7816 25.344 100.446 24.9316C101.111 24.4996 101.619 23.8909 101.971 23.1055C102.342 22.32 102.528 21.3971 102.528 20.3367C102.528 18.7462 102.127 17.4895 101.326 16.5665C100.524 15.624 99.4396 15.1527 98.0714 15.1527C97.1723 15.1527 96.3905 15.3687 95.726 15.8007C95.0615 16.2131 94.5435 16.812 94.1721 17.5975C93.8203 18.3633 93.6444 19.2764 93.6444 20.3367C93.6444 21.9273 94.0451 23.1938 94.8465 24.1364C95.6478 25.0789 96.7228 25.5502 98.0714 25.5502Z" fill={theme.primaryColor} />
      <Path d="M110.852 33.3262C110.559 33.3262 110.324 33.2476 110.148 33.0905C109.973 32.9335 109.865 32.7175 109.826 32.4425C109.806 32.1873 109.855 31.9222 109.973 31.6473L111.995 27.0818V28.1127L106.366 14.976C106.249 14.6815 106.21 14.4065 106.249 14.1513C106.288 13.896 106.406 13.6898 106.601 13.5327C106.796 13.3756 107.07 13.2971 107.422 13.2971C107.735 13.2971 107.979 13.3658 108.155 13.5033C108.331 13.6407 108.487 13.8862 108.624 14.2396L113.432 26.0804H112.64L117.507 14.2396C117.664 13.8862 117.83 13.6407 118.006 13.5033C118.181 13.3658 118.436 13.2971 118.768 13.2971C119.081 13.2971 119.315 13.3756 119.471 13.5327C119.647 13.6898 119.745 13.896 119.765 14.1513C119.804 14.3869 119.765 14.652 119.647 14.9465L112.142 32.3836C111.986 32.7371 111.81 32.9825 111.614 33.12C111.438 33.2575 111.184 33.3262 110.852 33.3262Z" fill={theme.primaryColor} />
      <Path d="M122.775 27.8182C122.345 27.8182 122.012 27.7102 121.778 27.4942C121.563 27.2585 121.455 26.9247 121.455 26.4927V8.37818C121.455 7.94618 121.563 7.62218 121.778 7.40618C122.012 7.17054 122.345 7.05273 122.775 7.05273H128.433C131.775 7.05273 134.355 7.93636 136.173 9.70364C137.991 11.4709 138.899 14.0433 138.899 17.4207C138.899 19.1095 138.665 20.6018 138.196 21.8978C137.746 23.1742 137.072 24.2542 136.173 25.1378C135.274 26.0215 134.179 26.6891 132.889 27.1407C131.599 27.5924 130.114 27.8182 128.433 27.8182H122.775ZM123.859 25.7564H128.257C129.625 25.7564 130.808 25.5796 131.805 25.2262C132.821 24.8727 133.661 24.3524 134.326 23.6651C135.01 22.9778 135.518 22.1138 135.85 21.0731C136.183 20.0127 136.349 18.7953 136.349 17.4207C136.349 14.652 135.674 12.5804 134.326 11.2058C132.977 9.81164 130.954 9.11454 128.257 9.11454H123.859V25.7564Z" fill={theme.primaryColor} />
      <Path d="M145.765 28.0833C144.788 28.0833 143.908 27.8967 143.127 27.5236C142.364 27.1309 141.758 26.6007 141.309 25.9331C140.859 25.2655 140.635 24.5193 140.635 23.6945C140.635 22.6342 140.899 21.7996 141.426 21.1909C141.973 20.5822 142.863 20.1502 144.094 19.8949C145.345 19.6396 147.045 19.512 149.195 19.512H150.515V21.0731H149.225C147.642 21.0731 146.391 21.1516 145.472 21.3087C144.573 21.4462 143.938 21.6916 143.566 22.0451C143.215 22.3985 143.039 22.8993 143.039 23.5473C143.039 24.3524 143.312 25.0102 143.86 25.5207C144.426 26.0313 145.189 26.2865 146.146 26.2865C146.928 26.2865 147.612 26.1 148.199 25.7269C148.804 25.3538 149.274 24.8433 149.606 24.1953C149.958 23.5473 150.134 22.8011 150.134 21.9567V18.5989C150.134 17.3815 149.889 16.5076 149.401 15.9775C148.912 15.4276 148.111 15.1527 146.997 15.1527C146.312 15.1527 145.628 15.2411 144.944 15.4178C144.26 15.5945 143.537 15.8793 142.775 16.272C142.501 16.4095 142.267 16.4585 142.071 16.4193C141.876 16.3604 141.719 16.2524 141.602 16.0953C141.485 15.9382 141.416 15.7615 141.397 15.5651C141.377 15.3491 141.416 15.1429 141.514 14.9465C141.631 14.7502 141.807 14.5931 142.042 14.4753C142.902 14.0433 143.752 13.7291 144.593 13.5327C145.433 13.3364 146.234 13.2382 146.997 13.2382C148.228 13.2382 149.244 13.4444 150.046 13.8567C150.847 14.2495 151.443 14.8582 151.834 15.6829C152.225 16.488 152.42 17.5287 152.42 18.8051V26.7873C152.42 27.18 152.323 27.4844 152.127 27.7004C151.951 27.9164 151.687 28.0244 151.336 28.0244C150.964 28.0244 150.681 27.9164 150.485 27.7004C150.29 27.4844 150.192 27.18 150.192 26.7873V24.4898H150.456C150.3 25.236 149.997 25.8742 149.547 26.4044C149.117 26.9345 148.58 27.3469 147.935 27.6415C147.29 27.936 146.567 28.0833 145.765 28.0833Z" fill={theme.primaryColor} />
      <Path d="M160.022 28.0833C159.142 28.0833 158.253 27.9753 157.354 27.7593C156.474 27.5433 155.653 27.1702 154.891 26.64C154.696 26.5025 154.559 26.3455 154.481 26.1687C154.402 25.9724 154.373 25.7858 154.393 25.6091C154.432 25.4127 154.51 25.2458 154.627 25.1084C154.764 24.9709 154.92 24.8924 155.096 24.8727C155.292 24.8335 155.507 24.8727 155.741 24.9905C156.504 25.4618 157.236 25.7956 157.94 25.992C158.663 26.1687 159.377 26.2571 160.08 26.2571C161.194 26.2571 162.035 26.0509 162.602 25.6385C163.168 25.2262 163.452 24.6665 163.452 23.9596C163.452 23.4098 163.266 22.9778 162.895 22.6636C162.523 22.3298 161.937 22.0745 161.136 21.8978L158.468 21.3087C157.236 21.0535 156.318 20.6116 155.712 19.9833C155.126 19.3549 154.832 18.54 154.832 17.5385C154.832 16.6549 155.057 15.8989 155.507 15.2705C155.976 14.6225 156.631 14.1218 157.471 13.7684C158.311 13.4149 159.289 13.2382 160.403 13.2382C161.263 13.2382 162.074 13.356 162.836 13.5916C163.618 13.8076 164.322 14.1611 164.947 14.652C165.142 14.7895 165.269 14.9564 165.328 15.1527C165.406 15.3295 165.416 15.516 165.357 15.7124C165.318 15.8891 165.23 16.0462 165.094 16.1836C164.957 16.3015 164.791 16.3702 164.595 16.3898C164.4 16.4095 164.195 16.3505 163.98 16.2131C163.393 15.8204 162.797 15.5356 162.191 15.3589C161.585 15.1625 160.989 15.0644 160.403 15.0644C159.308 15.0644 158.478 15.2804 157.911 15.7124C157.344 16.1444 157.061 16.7138 157.061 17.4207C157.061 17.9705 157.236 18.4222 157.588 18.7756C157.94 19.1291 158.487 19.3844 159.23 19.5415L161.898 20.1011C163.168 20.376 164.116 20.8178 164.742 21.4265C165.387 22.0156 165.709 22.8207 165.709 23.8418C165.709 25.1378 165.191 26.1687 164.155 26.9345C163.12 27.7004 161.742 28.0833 160.022 28.0833Z" fill={theme.primaryColor} />
      <Path d="M168.889 28.0244C168.498 28.0244 168.205 27.9164 168.009 27.7004C167.814 27.4844 167.716 27.18 167.716 26.7873V8.05418C167.716 7.66145 167.814 7.36691 168.009 7.17054C168.205 6.95455 168.498 6.84655 168.889 6.84655C169.26 6.84655 169.553 6.95455 169.768 7.17054C169.983 7.36691 170.091 7.66145 170.091 8.05418V16.6844H169.71C170.12 15.5455 170.784 14.6913 171.703 14.1218C172.641 13.5327 173.716 13.2382 174.928 13.2382C176.062 13.2382 177 13.4444 177.743 13.8567C178.505 14.2691 179.072 14.8975 179.443 15.7418C179.814 16.5665 180 17.6171 180 18.8935V26.7873C180 27.18 179.892 27.4844 179.677 27.7004C179.482 27.9164 179.199 28.0244 178.827 28.0244C178.436 28.0244 178.133 27.9164 177.918 27.7004C177.723 27.4844 177.625 27.18 177.625 26.7873V19.0407C177.625 17.7055 177.361 16.7335 176.834 16.1247C176.326 15.4964 175.505 15.1822 174.371 15.1822C173.061 15.1822 172.016 15.5945 171.234 16.4193C170.472 17.2244 170.091 18.3044 170.091 19.6593V26.7873C170.091 27.612 169.69 28.0244 168.889 28.0244Z" fill={theme.primaryColor} />
      <Path d="M2.27889 36C1.53614 36 0.962199 35.8128 0.557063 35.4383C0.185688 35.0298 0 34.4511 0 33.7021V2.29787C0 1.54894 0.185688 0.987234 0.557063 0.612767C0.962199 0.204256 1.53614 0 2.27889 0H12.0528C17.826 0 22.2825 1.53192 25.4223 4.59575C28.5621 7.65957 30.132 12.1191 30.132 17.9745C30.132 20.9021 29.7269 23.4894 28.9166 25.7362C28.1401 27.9489 26.9753 29.8213 25.4223 31.3532C23.8693 32.8851 21.9786 34.0426 19.7504 34.8255C17.5221 35.6085 14.9563 36 12.0528 36H2.27889ZM4.15265 32.4255H11.749C14.1123 32.4255 16.1548 32.1191 17.8766 31.5064C19.6322 30.8936 21.084 29.9915 22.2319 28.8C23.4135 27.6085 24.2913 26.1106 24.8652 24.3064C25.4392 22.4681 25.7262 20.3574 25.7262 17.9745C25.7262 13.1745 24.5614 9.58298 22.2319 7.2C19.9023 4.78298 16.408 3.57447 11.749 3.57447H4.15265V32.4255Z" fill={theme.primaryColor} />
      <Path d="M6.44601 9.0091H16.695C17.6939 9.0091 18.5036 9.82561 18.5036 10.8328C18.5036 11.84 17.6939 12.6565 16.695 12.6565H6.44601V9.0091Z" fill={theme.primaryColor} />
      <Path d="M6.44601 16.3039H16.695C17.6939 16.3039 18.5036 17.1204 18.5036 18.1276C18.5036 19.1348 17.6939 19.9514 16.695 19.9514H6.44601V16.3039Z" fill={theme.primaryColor} />
      <Path d="M6.44601 23.5988H16.695C17.6939 23.5988 18.5036 24.4153 18.5036 25.4225C18.5036 26.4297 17.6939 27.2462 16.695 27.2462H6.44601V23.5988Z" fill={theme.primaryColor} />
    </Svg>
  );
});


const styles = StyleSheet.create({
  map: {

  },

  australia: {

  }
});