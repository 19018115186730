import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ScrollView, StyleSheet, View, Dimensions, TouchableWithoutFeedback } from 'react-native'
import { ThemeProvider, useTheme } from '../../themes/theme'
import * as d3 from 'd3'
import Svg, { G, Polyline, Line, TextPath } from 'react-native-svg'
import { Path, Text } from '../svg';
import { Text as StandardText } from '../text';

export type PieProps = {
  width: number
  height: number
  data: any[]
  valueField: string
  valueFieldLabel?: string
  labelField: string
  colorMapping?: (d: any) => string
  onPress?: (d: any) => void
}

export function PieChart(props: PieProps) {
  const {
    width,
    height,
    data = [],
    valueField,
    valueFieldLabel = 'MW',
    labelField,
    onPress,
    colorMapping,
    ...otherProps
  } = props

  const theme = useTheme()
  const svgRef = useRef() as any
  const [scales, setScales] = useState(data.map((x: any) => 1));

  useEffect(() => {
    setScales(data.map((x: any) => 1));
  }, [data]);

  const svgWidth = width > 700 ? width * 2 / 3 : width;
  const svgHeight = height;

  const color = theme.chartColors;

  const radius = Math.min(svgWidth, svgHeight) / 2;

  const pie = d3.pie()
    .sort(null)
    .value((d: any) => d[valueField]);

  const arc = d3.arc()
    .outerRadius(radius * 0.80)
    .innerRadius(radius * 0.0);

  const lineArc = d3.arc()
    .outerRadius(radius * 0.75)
    .innerRadius(radius * 0.75);

  const outerArc = d3.arc()
    .innerRadius(radius * 0.85)
    .outerRadius(radius * 0.85);

  const pieData = pie(data.filter(x => x[valueField] !== 0));
  const midAngle = (d: any) => {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }

  const tableElementPadding = 4;
  const minRowHeight = 20;
  const tableRowHeight = Math.min(height / pieData.length - tableElementPadding, minRowHeight);
  const isMobile = width < 700;

  const getElement = (d: any, index: number) => {
    const pos = outerArc.centroid(d);
    const flip = pos[1] > 0;

    return (
      <G key={index}>
        <Path
          id={`${d.data[labelField]}-${index}`}
          d={outerArc(d) as string}
        />
        <Path
          fill={colorMapping ? colorMapping(d.data) : color[index % (color.length)]}
          d={arc(d) as string}
          onPress={() => { if (onPress) onPress(d.data) }}
          transform={`scale(${scales[index] || 1})`}
          onMouseOver={() => { 
            if (onPress) {
              const newScales = [...scales];
              newScales[index] = 1.05;
              setScales(newScales);
            }
          }}
          onMouseOut={() => {
            if (onPress) {
              const newScales = [...scales];
              newScales[index] = 1;
              setScales(newScales);
            }
          }}
        />
        {d.data.percentage >= 4 &&
          <Text
            key={index}
            fontSize='10'
            style={{ fontWeight: scales[index] > 1 ? 'bold' : 'normal' }}
          >
            <TextPath href={`#${d.data[labelField]}-${index}`} textAnchor='middle' startOffset='25%'>{d.data[labelField]}</TextPath>
          </Text>
        }
      </G>
    );
  }

  const getTableElement = (d: any, index: number) => {
    return (
      <TouchableWithoutFeedback
        key={index}
        onPress={() => { if (onPress) onPress(d.data) }}
      >
        <View
          style={[styles.tableElement, { height: tableRowHeight, marginBottom: tableElementPadding, transform: [{ scaleX: scales[index], scaleY: scales[index] }] }]}
        >
          <View style={[{ width: tableRowHeight, backgroundColor: colorMapping ? colorMapping(d.data) : color[index] }]}>
          </View>
          <View style={!isMobile ? styles.tableLabel : styles.tableLabelMobile}>
            <StandardText style={{ fontWeight: scales[index] > 1 ? 'bold' : 'normal' }}>
              {d.data[labelField]}
            </StandardText>
          </View>
          <View style={!isMobile ? styles.tableValue : styles.tableValueMobile}>
            <StandardText style={{ fontWeight: scales[index] > 1 ? 'bold' : 'normal' }}>
              {Math.round(d.data[valueField])}{valueFieldLabel}
            </StandardText>
          </View>
          <View style={!isMobile ? styles.tablePercentage : styles.tablePercentageMobile}>
            <StandardText style={{ fontWeight: scales[index] > 1 ? 'bold' : 'normal' }}>
              {Math.round(d.data.percentage)}%
            </StandardText>
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  return (
    <ScrollView>
      <View style={!isMobile ? styles.container : styles.containerMobile}>
        <View style={styles.svgContainer}>
          <Svg width={svgWidth} height={svgHeight}>
            <G transform={`translate(${svgWidth / 2}, ${radius})`}>
              {pieData.map((d, index) => getElement(d, index))}
            </G>
          </Svg>
        </View>
        <View style={[!isMobile ? styles.tableContainer : styles.tableContainerMobile]}>
          {pieData.map((d, index) => getTableElement(d, index))}
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  containerMobile: {
    alignItems: 'center'
  },
  svgContainer: {
    flex: 1.75
  },
  tableContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  tableContainerMobile: {
  },
  tableElement: {
    flexDirection: 'row',
  },
  tableLabel: {
    paddingLeft: 10,
    flex: 1,
    minWidth: 90
  },
  tableValue: {
    flex: 1,
    minWidth: 90
  },
  tablePercentage: {
    flex: 1
  },
  tableLabelMobile: {
    paddingLeft: 10,
    width: 100,
  },
  tableValueMobile: {
    width: 100,
  },
  tablePercentageMobile: {
    width: 40
  },
})
