import React, { useState } from 'react';
import { Modal as NativeModal, ModalProps as NativeModalProps, StyleSheet, View, ScrollView, useWindowDimensions, TouchableOpacity, Linking, Image } from 'react-native';
import { useTheme } from '../themes/theme';
import { EnergyDashIcon } from './icons/energydash';
import { ToolbarButton } from './toolbar-button';
import { Text } from './text';
import stellateBadge from '@energynow/components/assets/stellate_badge.png';
import nfiniteLogo from '@energynow/components/assets/nfinite_logo.png';

export interface ModalProps extends NativeModalProps {
  responsive?: boolean,
  allowClose?: boolean,
  title?: string,
  header?: React.ReactNode,
  footer?: React.ReactNode
};

export const Modal = React.forwardRef((props: ModalProps, ref) => {
  const {
    visible = false,
    responsive = true,
    allowClose = true,
    children,
    title,
    header,
    footer,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [showModal, setShowModal] = useState(visible);
  const { width } = useWindowDimensions();

  return (
    <NativeModal
      visible={visible}
      animationType='fade'
      transparent={true}
      presentationStyle={'fullScreen'}
      {...otherProps}
    >
      <View style={[
        styles.modal,
        styles.modalDesktop,
        { backgroundColor: theme.popoverBackgroundColor }
      ]}
      >
        <View style={[
          styles.wrapper,
          { width: width }
        ]}>
          {(title != null || allowClose) &&
            <View style={[
              styles.titleBar
            ]}>
              <View style={[
                styles.title
              ]}>
                {header}
                <Text style={[
                  styles.titleText,
                  { color: theme.popoverColor }
                ]}>{title}</Text>
              </View>
              <View style={[
                styles.actions
              ]}>
                <ToolbarButton withBackground={false} icon="close" onPress={otherProps.onRequestClose}></ToolbarButton>
              </View>
            </View>
          }
          <View style={[
            styles.body,
            title != null && {
              borderTopWidth: 1,
              borderStyle: 'solid',
              borderTopColor: theme.primaryBorderColor
            }
          ]}>
            <ScrollView
              style={styles.content}
              contentContainerStyle={styles.content}
              scrollEnabled={true}
            //onContentSizeChange={this.onContentSizeChange}
            >{children}

            </ScrollView>

          </View>
          {footer != null && <View style={[
            styles.footer,
            { borderTopColor: theme.primaryBorderColor }
          ]}>
            {footer}
          </View>
          }

          {footer == null && <View style={[
            styles.footer,
            { borderTopColor: theme.primaryBorderColor }
          ]}>
            <View style={[styles.footerLogo]}>
              <View style={[styles.nfinite]}>
                <TouchableOpacity onPress={() => Linking.openURL("https://www.nfinite.com.au")} >
                  <View style={[styles.nfinite, { backgroundColor: theme.primaryBackgroundColor }]}>
                    <Image source={nfiniteLogo} style={[styles.logo]} />
                    <Text>Made by Nfinite</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL("https://stellate.co/?ref=powered-by")} >
                  <View style={[styles.nfinite]}>
                    <Image source={stellateBadge} style={[styles.stellateBadge]} />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          }
        </View>
      </View>
    </NativeModal >
  )
});

const styles = StyleSheet.create({
  modal: {
    borderRadius: 6,
    margin: 'auto',
  },

  modalDesktop: {
    flex: 1,

    width: '100%',
    // maxWidth: 750,
    // minWidth: 610,
    shadowColor: '#0003',
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    alignItems: "center"
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: 950,
  },

  titleBar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    paddingHorizontal: 20
  },

  title: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },

  titleText: {
    fontSize: 16,
    paddingVertical: 16,
    fontWeight: "700"
  },

  actions: {
    display: 'flex',
    flexDirection: 'row',
    width: 36
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',

  },

  content: {
    flex: 1,
    minHeight: 150
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0,
    borderTopWidth: 1,
    borderStyle: 'solid',
    minHeight: 60,
    paddingVertical: 0,
    paddingHorizontal: 0
  },

  footerLogo: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: "flex-end"
  },

  nfinite: {
    paddingLeft: 4,
    paddingRight: 16,
    paddingVertical: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },

  stellateBadge: {
    marginTop: 4,
    marginBottom: 4,
    width: 100,
    height: 44,
    marginLeft: 6,
    marginRight: 4
  },

  logo: {
    marginTop: 4,
    marginBottom: 4,
    width: 22,
    height: 22,
    marginLeft: 6,
    marginRight: 4
  }
})