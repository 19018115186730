import React, { useEffect, useState, useMemo } from 'react';
import { LayoutRectangle, ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { FUEL_TYPE_AGGREGATION, parseDate, parseDateCustom, formatDateTime, getDispatchHub, DISPATCH_HUB_LATEST_DISPATCH } from '@energynow/core';
import { useQuery } from '../../../hooks/apollo';
import { Visualisation } from '../../common/visualisation';
import { Select } from '../../common/select';
import { Text } from '../../common/text';
import { ErrorLoading } from '../../common/error-loading';
import { useHubToListen } from '../../../hooks/signalr';
import { PERIODS, REGIONS } from '../constants';
import { formatNumber } from '@energynow/core';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { formatMW, formatMWh, formatDateTimeWithTimezone as cFormatDateTime, formatDateOnlyWithTimezone, formatEndIntervalDateAsMonthWithTimezone } from '../../common/chart';
// @ts-ignore
import DatePicker from '../../common/datepicker';
import { DEVICE_WIDTH } from '../../common/constants';
import { HorizontalScroll } from '../../common/horizontal-scroll';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedDate } from '../../layout/layout-slice';
import { RangeStackedBarChart } from '../../common/chart/range-stacked-bar-chart';
import * as d3 from 'd3';

export function FuelMixRange(props: any) {
  const params = props.route?.params;

  const { loading, error, data, refetch } = useQuery(FUEL_TYPE_AGGREGATION, {
    variables: {
      aggregationType: '5MIN',
      fromDate: null,
      toDate: null
    }
  });
  const latestDispatch = useHubToListen(getDispatchHub(), DISPATCH_HUB_LATEST_DISPATCH);

  const theme = useTheme();
  const globalSelectedDate = useAppSelector(selectSelectedDate);
  const [selectedRegion, setSelectedRegion] = useState(params?.region || 'NEM');
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<any[]>(['Brown coal', 'Black coal', 'Gas', 'Hydro', 'Wind', 'Battery', 'Solar', 'Biofuel', 'Diesel', 'Liquid fuel']);
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('Live');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [timezone, setTimezone] = useState('+10:00');
  const [smallScreen, setSmallScreen] = useState(false);
  const [visualisationRectangle, setVisualisationRectangle] = useState<LayoutRectangle>({ width: 0, height: 0, x: 0, y: 0 });

  const regions = [{ label: 'NEM', value: 'NEM' }, ...REGIONS];

  const periods = [
    { label: 'Live', value: 'Live' },
    { label: '5 Min', value: '5MIN' },
    { label: 'Hourly', value: 'HOUR' },
    { label: 'Daily', value: 'DAY' },
    { label: 'Weekly', value: 'WEEK' },
    { label: 'Monthly', value: 'MONTH' },
  ];

  let fuelTypes = [
    { label: 'Brown coal', value: 'Brown coal', selectedColor: '' as any },
    { label: 'Black coal', value: 'Black coal' },
    { label: 'Gas', value: 'Gas' },
    { label: 'Hydro', value: 'Hydro' },
    { label: 'Wind', value: 'Wind' },
    { label: 'Battery', value: 'Battery' },
    { label: 'Solar', value: 'Solar' },
    { label: 'Biofuel', value: 'Biofuel' },
    { label: 'Diesel', value: 'Diesel' },
    { label: 'Liquid fuel', value: 'Liquid fuel' },
  ]

  const fuelColorMapping = (d: any) => {
    switch (d) {
      case 'Brown coal':
        return theme.brownCoalColor;
      case 'Black coal':
        return theme.blackCoalColor;
      case 'Coal':
        return theme.coalColor;
      case 'Gas':
        return theme.gasColor;
      case 'Hydro':
        return theme.hydroColor;
      case 'Wind':
        return theme.windColor;
      case 'Battery':
        return theme.batteryColor;
      case 'Solar':
        return theme.solarColor;
      case 'Biofuel':
        return theme.biofuelColor;
      case 'Diesel':
        return theme.dieselColor;
      case 'Liquid fuel':
        return theme.liquidFuelColor;
      default:
        return theme.unknownColor;
    }
  }

  for (const fuelType of fuelTypes) {
    fuelType.selectedColor = fuelColorMapping(fuelType.value)
  }

  useEffect(() => {
    let aggregationType: string = '5MIN';
    let toDate: Date | null = null;
    
    if (selectedPeriod != null) {
      if (selectedPeriod !== 'Live') {
        aggregationType = selectedPeriod;
      }
    }

    if (selectedPeriod !== 'Live' && selectedDate != null) {
      toDate = selectedDate;
    }

    refetch({
      aggregationType: aggregationType,
      fromDate: null,
      toDate: (toDate as any)
    });
  }, [latestDispatch, selectedPeriod, selectedDate]);

  useEffect(() => {
    if (globalSelectedDate) {
      if (selectedPeriod === 'Live') {
        setSelectedPeriod('5MIN');
      }
      onDatePicked(globalSelectedDate);
    } else if (globalSelectedDate === null) {
      setSelectedPeriod('Live');
    }
  }, [globalSelectedDate]);

  const onDatePicked = (date: string) => {
    let newDate: Date;
    if (timezone != '') {
      newDate = parseDateCustom(`${date} ${timezone}`, 'yyyy/MM/dd HH:mm XXX');
    } else {
      newDate = parseDateCustom(date, 'yyyy/MM/dd HH:mm');
    }

    setSelectedDate(newDate);
  };

  useEffect(() => {
    setSmallScreen(visualisationRectangle.width <= DEVICE_WIDTH.sm);
  }, [visualisationRectangle.width]);

  const noData = data?.fuelTypeAggregation?.length === 0;

  const filteredData = useMemo(() => {
    return data?.fuelTypeAggregation.filter((x: any) => x.regionId === selectedRegion && (selectedFuelTypes.length == 0 || selectedFuelTypes.includes(x.fuelType)))
  }, [selectedRegion, selectedFuelTypes, data]);

  const xTickFormatter = useMemo(() => {
    if (selectedPeriod === 'Live' || selectedPeriod === '5MIN' || selectedPeriod === 'HOUR') {
      return cFormatDateTime(timezone);
    } else if (selectedPeriod === 'MONTH') {
      return (e: any) => {
        while (e < 0) {
          e += 12;
        }

        const mappings = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        return mappings[e % 12];
      }
    } else {
      return formatDateOnlyWithTimezone(timezone);
    }
  }, [selectedPeriod, timezone]);

  const yTickFormatter = useMemo(() => {
    if (selectedPeriod === 'Live' || selectedPeriod === '5MIN') {
      return formatMW;
    } else {
      return formatMWh;
    }
  }, [selectedPeriod, timezone]);

  const xScale = useMemo(() => {
    if (selectedPeriod !== 'MONTH')
      return undefined;

    return (xMin: any, xMax: any, width: number) => {
      let scaledXMin = (xMin as Date).getMonth() + 1;
      let scaledXMax = (xMax as Date).getMonth() - 1;
      
      if (scaledXMax > 12) {
        scaledXMax -= 12;
      }

      while (scaledXMin > scaledXMax) {
        scaledXMin = scaledXMin - 12;
      }

      return d3
        .scaleLinear()
        .domain([scaledXMin - 2, scaledXMax + 1])
        .range([0, width]);
    }
  }, [selectedPeriod]);

  if (!!error) {
    return <ErrorLoading onPressReload={() => { refetch({
      aggregationType: 'Live',
      fromDate: null,
      toDate: null
    }) }} />
  }

  return (
    <Visualisation
      allowFullscreen={false}
      aspectRatio={4 / 3}
      border={true}
      utcTime={noData ? undefined : data?.fuelTypeAggregation[data.fuelTypeAggregation.length - 1]?.interval}
      onTimezone={(timezone, description) => setTimezone(timezone)}
      loading={loading && !data?.dispatchKeys}
      onLayout={(layout) => setVisualisationRectangle(layout)}
      noData={noData}
      filters={
        <View style={{ flex: -1, flexDirection: 'row' }}>
          <Select
            multiple={false}
            compact={true}
            options={regions}
            onChange={(value) => setSelectedRegion(value)}
            value={selectedRegion}
          ></Select>
          <Select
            multiple={true}
            compact={true}
            options={fuelTypes}
            onChange={(value) => setSelectedFuelTypes(value)}
            maxSelectedLabels={1}
            selectedItemsLabel="{0} fuels selected"
            value={selectedFuelTypes}
          ></Select>
        </View>
      }
      dateFilters={
        <View
          style={{ flex: -1, flexDirection: 'row' }}>
          <Select
            multiple={false}
            options={periods}
            responsive={true}
            optionLabel='label'
            optionValue='value'
            compact={true}
            onChange={(value) => setSelectedPeriod(value)}
            value={selectedPeriod}
            style={{ minWidth: 36 }}
          ></Select>
          <DatePicker style={{ minWidth: 40 }} selected={selectedDate} maximumDate={formatDateTime(latestDispatch?.settlementDate ? parseDate(latestDispatch.settlementDate) : new Date(), 'yyyy/MM/dd HH:mm')} onSelectedChange={onDatePicked} active={selectedPeriod !== 'Live'} />
        </View>
      }
    >
      <RangeStackedBarChart useXScale={xScale} useXCursor={selectedPeriod === 'MONTH' ? false : true} width={visualisationRectangle.width} height={visualisationRectangle.height} xTickFormatter={xTickFormatter} yTickFormatter={yTickFormatter} categoryField='interval' valueField='value' stackField='fuelType' data={filteredData} stackFieldColorMapping={fuelColorMapping} />
      {/* <Text>
        {data.latestKey.dispatchRegionSum[0].clearedSupply}
      </Text> */}
    </Visualisation>
  )
}

const styles = StyleSheet.create({
})
