import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store';
import { setThemeApi } from './theme-api';
import { keycloak, apolloClientAuth, UPDATE_SETTINGS, storage } from '@energynow/core';

export interface ThemeState {
  theme: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ThemeState = {
  theme: storage.getString("theme") || "default",
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const setThemeAsync = createAsyncThunk(
  'theme/setTheme',
  async (theme: string) => {
    if (!!keycloak?.authenticated) {
      apolloClientAuth.mutate({
        mutation: UPDATE_SETTINGS,
        variables: {
          settings: {
            settings: {
              theme: theme
            }
          }
        }
      });
    }
    // The value we return becomes the `fulfilled` action payload
    return theme;
  }
);

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      storage.set("theme", action.payload);
      state.theme = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(setThemeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setThemeAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.theme = action.payload;
      })
      .addCase(setThemeAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setTheme } = themeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTheme = (state: RootState) => state.theme.theme;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// Kept in as an example for now but not required
export const trySetTheme = (theme: string): AppThunk => (
  dispatch,
  getState
) => {
  const currentTheme = selectTheme(getState());
  if (currentTheme !== theme) {
    dispatch(setTheme(theme));
  }
};

export default themeSlice.reducer;
