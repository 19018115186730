import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { Popover } from './popover';
import { ThemeProvider, useTheme } from '../themes/theme';
import { PopoverPosition } from './popover/types';

export type MenuProps = {
    responsive?: boolean,
    position?: PopoverPosition,
    children?: React.ReactNode,
};

export const Menu = React.forwardRef((props: MenuProps, ref) => {
    const {
        responsive = true,
        position = 'bottom',
        children,
        ...otherProps
    } = props;

    const theme = useTheme();
    const [showPopover, setShowPopover] = useState(false);
    const menu = useRef() as any;

    return (
        <View style={[
            styles.menu,
            { backgroundColor: theme.popoverBackgroundColor }
        ]}
        >
            {children}
        </View>
    )
});

const styles = StyleSheet.create({
    menu: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        borderRadius: 2,
        minWidth: 250,
        maxWidth: 340,
        paddingVertical: 6,
        shadowColor: '#0003',
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 2 }
        //box-shadow: 0 2px 4px #0003;
    },
})