import React from 'react';
import { StyleSheet } from 'react-native';
import { ThemeProvider, useTheme, themes, withTheme } from '../themes/theme';
import { useAppSelector } from '../../store/hooks';
import { selectTheme } from '../themes/theme-slice';
import { Layout } from './layout';

export type LayoutProps = {
  children: React.ReactNode
};

export function ThemedLayout(props: LayoutProps) {
  const {
    children,
    ...otherProps
  } = props;

  const currentTheme = useAppSelector(selectTheme);
  const theme = useTheme();
  //const layout = withTheme(Layout);


  return (
    <ThemeProvider theme={themes[currentTheme]}>
      {children}
    </ThemeProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
})
