import { useState, useEffect } from 'react';
import { HubConnection } from '@microsoft/signalr';

export function useHubToListen(hub: HubConnection, listenOn: string): any {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    hub.on(listenOn, (message: any) => {
      setMessage(message);
    });
  })

  return message;
}
