import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, TextInputProps as ReactTextInputProps, TextInput as ReactTextInput, StyleProp } from 'react-native';
import { Popover } from './popover';
import { ThemeProvider, useTheme } from '../themes/theme';
import { PopoverPosition } from './popover/types';
import { radius } from '../../styles/variables';
import { TextStyle } from 'react-native-web';

export interface TextInputProps extends ReactTextInputProps {
  error?: boolean,
  style?: StyleProp<TextStyle>
};

export const TextInput = React.forwardRef<ReactTextInput, TextInputProps>((props: TextInputProps, ref) => {
  const {
    error,
    style,
    ...otherProps
  } = props;
  const theme = useTheme();

  return (
    <ReactTextInput
      ref={ref}
      style={[
        styles.text,
        {
          color: theme.primaryColor,
          borderColor: theme.primaryBorderColor
        },
        !!error && { borderColor: theme.errorBorderColor },
        style
      ]}
      {...otherProps}
    />
  )
});

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    borderRadius: 2,
    height: 40,
    borderWidth: 1,
    padding: 10,
    flexDirection: 'row',
    flex: 1
  },
})