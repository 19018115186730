import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Animated, Alert } from 'react-native';
import { ThemeProvider, useTheme } from '../themes/theme';
import { Button } from '../common/button';
import { Text } from '../common/text';
import { MenuItem } from '../common/menu-item';
import { Switch } from '../common/switch';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setNotificationAsync, selectNotification, setNotification, selectDevice, setDeviceAsync } from '../modals/settings-slice';
import { Select } from '../common/select';
import { Menu } from '../common/menu';
import { getToken, getDeviceId, getDeviceName } from '@energynow/core';

export type DevicesProps = {

};

export const Devices = React.forwardRef((props: DevicesProps, ref) => {
  const {
    ...otherProps
  } = props;

  const theme = useTheme();
  const [deviceId, setDeviceId] = useState("");
  const [currentDevice, setCurrentDevice] = useState(undefined as any);
  const devices = useAppSelector(selectDevice);
  const dispatch = useAppDispatch();

  let deviceToken = "";
  let deviceName = "";

  getDeviceId().then(x => setDeviceId(x.toString()));
  getDeviceName().then(x => deviceName = x);

  useEffect(() => {
    setCurrentDevice(devices.find(x => x.deviceId === deviceId));
  }, [devices, deviceId]);

  const addDevice = () => {
    getToken().then((token) => {
      deviceToken = token;
      if (deviceId && deviceToken) {
        dispatch(setDeviceAsync({
          deviceId: deviceId,
          notificationsEnabled: true,
          device: deviceName,
          deviceToken: deviceToken,
        }));
      } else {
        Alert.alert("Failed adding device");
      }
    }).catch((reason) => {
      Alert.alert("Failed adding device", reason);
    });
  };

  const renderDevices = () => {
    if (devices && devices.length) {
      return devices.map((device, index) => {
        return <MenuItem key={`${device.deviceId}_${index}`} text={`${deviceId === device.deviceId ? 'Current Device' : device.device}`}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Switch value={device.notificationsEnabled} onValueChange={async (value) => {
              dispatch(setDeviceAsync({
                deviceId: device.deviceId,
                notificationsEnabled: value,
                deviceToken: device.deviceToken,
                device: device.device
              }));

            }}></Switch>
            <Button icon='close' withText={false} onPress={() => {
              dispatch(setDeviceAsync({
                deviceId: device.deviceId,
                notificationsEnabled: device.notificationsEnabled,
                deviceToken: device.deviceToken,
                remove: true,
                device: device.device
              }));
            }}></Button>
          </View>
        </MenuItem>

      });
    }

    return null;
  }

  const renderedDevices = renderDevices();

  return (
    <View style={styles.container}>
      <View style={styles.addFormRow}>
        <View >
          <Text h1={true} fontWeight="400">Devices</Text>
        </View>
      </View>
      <View style={styles.addFormRow}>
        <Text>Get notifications where you want to see them. Add your current device or enable/disable which devices receive notifications.</Text>
      </View>

      {!currentDevice && <View style={styles.addContainer}>
        <Button text='Add Current Device' icon='pluscircleo' large={true} onPress={() => {
          addDevice();
        }}></Button>
      </View>}
      {devices && devices.length > 0 &&
        < View style={[styles.notifications, { borderColor: theme.primaryBorderColor }]} >
          {renderedDevices}
        </View >}
    </View >
  )
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    width: "100%",
    paddingleft: 12,
    paddingBottom: 2
  },

  addContainer: {
    flexDirection: "row",
    flex: 1,
    alignSelf: "flex-end",
    paddingBottom: 6
  },

  addForm: {
    flexDirection: "column",
    flex: 0,
    flexShrink: 1,
    overflow: 'hidden'
  },

  addFormRow: {
    flexDirection: "row",
    flex: 1,
    paddingBottom: 20
  },

  addFormActions: {
    flexDirection: "row",
    flex: 1,
    alignSelf: "flex-end",
    marginTop: 20,
    paddingBottom: 24
  },

  notifications: {
    flexDirection: "column",
    flex: 1,
    borderTopWidth: 1,
    paddingTop: 12
  },

  formRowLabel: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 6,
    marginleft: 12,
  },
})