import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store/store';
import { keycloak, apolloClientAuth, UPDATE_SETTINGS } from '@energynow/core';

export enum Panel {
  notifications = "Notifications",
  marketNotices = "Market Notices"
}

export interface LayoutState {
  rightPanel: Panel | undefined;
  settings: boolean | string | undefined;
  layout: any;
  status: 'idle' | 'loading' | 'failed';
  selectedDate: string | null | undefined;
}

const initialState: LayoutState = {
  rightPanel: undefined,
  settings: undefined,
  layout: null,
  status: 'idle',
  selectedDate: undefined,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const setLayoutAsync = createAsyncThunk(
  'theme/setTheme',
  async (theme: string) => {
    if (!!keycloak?.authenticated) {
      apolloClientAuth.mutate({
        mutation: UPDATE_SETTINGS,
        variables: {
          settings: {
            settings: {
              theme: theme
            }
          }
        }
      });
    }
    // The value we return becomes the `fulfilled` action payload
    return theme;
  }
);

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRightPanel: (state, action: PayloadAction<Panel | undefined>) => {
      state.rightPanel = action.payload;
    },
    setSettings: (state, action: PayloadAction<boolean | string | undefined>) => {
      state.settings = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<string | null>) => {
      state.selectedDate = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(setLayoutAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setLayoutAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.layout = action.payload;
      })
      .addCase(setLayoutAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setRightPanel, setSettings, setSelectedDate } = layoutSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRightPanel = (state: RootState) => state.layout.rightPanel;
export const selectSettings = (state: RootState) => state.layout.settings;
export const selectSelectedDate = (state: RootState) => state.layout.selectedDate;

export default layoutSlice.reducer;
