import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, PixelRatio } from 'react-native';
import { Popover } from '../popover';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { PopoverPosition } from '../popover/types';
import * as d3 from 'd3';
import { ScaleBand } from 'd3';
import { G, Text, Line, Path, Rect } from 'react-native-svg';
import { CursorPosition } from './overlay/cursor';

export type AxisType = 'left' | 'right' | 'top' | 'bottom';

export type AxisProps = {
  orient: AxisType,
  scale: any,
  ticks: (() => void) | number,
  transform: string,
  tickFormat: ((d: any) => void) | string,
  disableAnimation?: boolean,
  domain?: boolean,
  tickSize?: number,
  labels?: boolean,
  anchorEl?: any
};

export const Axis = React.forwardRef<any, AxisProps>((props: AxisProps, ref) => {
  const {
    orient,
    scale,
    ticks = 0,
    tickFormat = null,
    transform,
    disableAnimation = true,
    anchorEl = null,
    domain = false,
    tickSize = 0,
    labels = true,
    ...otherProps
  } = props;

  const theme = useTheme();
  const offset = PixelRatio.get() > 1 ? 0 : 0.5;
  let tickArguments: Array<any> = [];
  let tickValues: any = null;
  let tickSizeInner = tickSize * -1;
  let tickSizeOuter = 1;
  let tickPadding = 3;
  let k: number;
  let x: string;
  let transformTick: (x: number) => string;
  let values: Array<any> = [];
  let format: any;
  let spacing: number;
  let position: any;
  let domainPath: string = "";


  const translateX = (x: number) => {
    return "translate(" + x + ",0)";
  }

  const translateY = (y: number) => {
    return "translate(0," + y + ")";
  }

  const number = (scale: any) => {
    return (d: any) => +scale(d);
  }

  const center = (scale: any, offset: number) => {
    offset = Math.max(0, scale.bandwidth() - offset * 2) / 2;
    if (scale.round()) offset = Math.round(offset);
    return (d: any) => +scale(d) + offset;
  }

  const getAxis = () => {
    k = orient === "top" || orient === "left" ? -1 : 1;
    x = orient === "left" || orient === "right" ? "x" : "y";
    transformTick = orient === "top" || orient === "bottom" ? translateX : translateY;
    values = tickValues == null ? (scale.ticks ? scale.ticks.apply(scale, [ticks]) : scale.domain()) : tickValues;
    format = tickFormat == null ? (scale.tickFormat ? scale.tickFormat.apply(scale, [ticks]) : ((x: any) => x)) : tickFormat;
    spacing = Math.max(tickSizeInner, 0) + tickPadding;
    const range = scale.range();
    const range0 = +range[0] + offset;
    const range1 = +range[range.length - 1] + offset;
    position = (scale.bandwidth ? center : number)(scale.copy(), offset);

    domainPath = orient === "left" || orient === "right"
      ? (tickSizeOuter ? "M" + k * tickSizeOuter + "," + range0 + "H" + offset + "V" + range1 + "H" + k * tickSizeOuter : "M" + offset + "," + range0 + "V" + range1)
      : (tickSizeOuter ? "M" + range0 + "," + k * tickSizeOuter + "V" + offset + "H" + range1 + "V" + k * tickSizeOuter : "M" + range0 + "," + offset + "H" + range1);
  }

  useEffect(() => {
    getAxis();
  }, [scale, ticks, tickFormat, disableAnimation]);

  getAxis();

  return (
    <G ref={ref} transform={transform} >
      {!!domain && <Path className="domain" stroke={theme.gridLineColor} strokeWidth={1} opacity={0.5} d={domainPath}></Path>}
      {values.map((d: any, index: number) =>
        <G className='tick' transform={isFinite(position(d)) ? transformTick(position(d) + offset) : ""} key={index}>
          <Line
            stroke={theme.gridLineColor}
            opacity={0.5}
            x2={x === "x" ? k * tickSizeInner : undefined}
            y2={x === "y" ? k * tickSizeInner : undefined}>

          </Line>
          {!!labels &&
            <Text
              textAnchor={orient === "bottom" ? "middle" : orient === "right" ? "end" : "start"}
              fontSize={13}
              fill={theme.gridLabelColor}
              fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
              x={x === "x" ? k * spacing : undefined}
              y={x === "y" ? k * spacing : undefined}

              dy={orient === "top" ? "0em" : orient === "bottom" ? "1em" : "-0.3em"}
              opacity={1}
            >
              {format(d)}
            </Text>}
        </G>
      )}
    </G>
  )
});

const styles = StyleSheet.create({

})
