import React, { useEffect, useState, useRef } from 'react';
import { ScrollView, StyleSheet, View, Animated, TouchableWithoutFeedback } from 'react-native';
import { hexToRgb, formatDateTime } from '@energynow/core';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { Select } from '../../common/select';
import LinearGradient from 'react-native-linear-gradient';
import { Text } from '../../common/text';


interface SingleMarketNoticeProps {
  marketNotice: any,
  timezone: string
};

export function SingleMarketNotice(props: SingleMarketNoticeProps) {
  const {
    marketNotice,
    timezone
  } = props;

  const startingHeight = 135;
  const theme = useTheme();
  const [expander, setExpander] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [fullHeight, setFullHeight] = useState(startingHeight);
  const animatedHeight = useRef(new Animated.Value(startingHeight)).current;

  useEffect(() => {
    Animated.spring(animatedHeight, {
      friction: 100,
      toValue: expanded ? fullHeight : startingHeight,
      useNativeDriver: false
    }).start();
  }, [expanded])

  const onLayout = (e: any) => {
    let { x, y, width, height } = e.nativeEvent.layout;
    height = Math.floor(height) + 40;
    if (height > startingHeight) {
      setFullHeight(height);
      setExpander(true);
    }
  };

  const cleanNoticeReason = (reason: string) => {
    reason = reason.replace('AEMO ELECTRICITY MARKET NOTICE', '');
    // Remove line when notice start with .
    if (reason.startsWith(".")) {
      reason = reason.substring(1, reason.length);
    }
    return reason.trim();
  }

  const toRGBColor = hexToRgb(theme.primaryBackgroundColor);

  return (
    <TouchableWithoutFeedback onPress={() => { setExpanded(!expanded) }}>
      <View style={[styles.marketNoticeParent, { backgroundColor: theme.primaryBackgroundColor }]}>
        <Animated.View style={[{ overflow: 'hidden', height: animatedHeight }]}>
          <View onLayout={e => { onLayout(e) }}>
            <View style={styles.layoutTop}>
              <View style={styles.title}>
                <Text>[{marketNotice.noticeId}] {marketNotice.externalReference}</Text>
              </View>

            </View>
            <View>
              <Text style={[styles.reasonText, { color: theme.primaryColor }]}>{cleanNoticeReason(marketNotice.reason)}</Text>
            </View>
            <View style={styles.date}>
              <Text style={[styles.noticeIdText]}></Text>
              <Text fontWeight='100'>{formatDateTime(marketNotice.effectiveDate, 'dd/MM/yyyy HH:mm:ss', timezone)}</Text>
            </View>
          </View>
        </Animated.View>

        {expander &&
          <React.Fragment>
            <LinearGradient
              colors={[
                // Change this gradient to match BG
                `rgba(${toRGBColor.r}, ${toRGBColor.g}, ${toRGBColor.b}, 0.0)`,
                `rgba(${toRGBColor.r}, ${toRGBColor.g}, ${toRGBColor.b}, 0.7)`,
                `rgba(${toRGBColor.r}, ${toRGBColor.g}, ${toRGBColor.b}, 0.9)`,
              ]}
              style={styles.gradient} />
            <Text style={[styles.ellipsis, { color: theme.primaryColor }]}>{expanded ? '^' : '.   .   .'}</Text>
          </React.Fragment>
        }
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  marketNoticeParent: {
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 8,
    borderRadius: 8,
    padding: 8,
  },
  layoutTop: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8
  },
  title: {
    flex: 1,
    justifyContent: 'center'
  },
  titleText: {
    fontSize: 20,
  },
  date: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: 12
  },
  dateText: {
    fontWeight: '100'
  },
  noticeIdText: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    fontWeight: '100'
  },
  reasonText: {
    fontWeight: '100'
  },
  ellipsis: {
    flex: 1,
    textAlign: 'center',
  },
  gradient: {
    backgroundColor: 'transparent',
    height: 40,
    position: 'absolute',
    width: '100%',
    bottom: 25,
  }
});
