import React, { useEffect } from 'react';
import { useKeycloak } from '../../hooks/keycloak';
import { ToolbarButton } from '../common/toolbar-button';
import { useAppDispatch } from '../../store/hooks';
import { setTheme } from '../themes/theme-slice';
import { GET_SETTINGS, apolloClientAuth } from '@energynow/core';
import { MenuButton } from '../common/menu-button';
import { MenuItem } from '../common/menu-item';
import { Profile } from '../profile';
import { setDevice, setNotification } from '../modals/settings-slice';
import { setProfile } from '../profile/profile-slice';

export function Login(props: any) {
  const { keycloak } = useKeycloak();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!keycloak?.authenticated) {

      keycloak?.loadUserProfile().then(() => {
        dispatch(setProfile(keycloak?.profile as any));
      });

      apolloClientAuth.query({
        query: GET_SETTINGS
      }).then(x => {
        if (x.data?.userSettings?.settings?.theme) {
          // Theme is now only a client setting
          //dispatch(setTheme(x.data.userSettings.settings.theme));
        }

        if (x.data?.userSettings?.devices) {
          dispatch(setDevice(x.data.userSettings.devices));
        }

        if (x.data?.userSettings?.notifications) {
          dispatch(setNotification(x.data.userSettings.notifications));
        }
      });
    }
  }, [keycloak?.authenticated]);

  if (!keycloak?.authenticated) {
    return <ToolbarButton branded={true} fill={true} text='Sign In' onPress={() => { keycloak?.login() }} />
  }

  return (<></>
    // <MenuButton branded={false} fill={false} icon={"user"} >
    //   <Profile />
    //   <MenuItem text={"Sign out"} onPress={() => { keycloak?.logout() }}></MenuItem>
    // </MenuButton>
  )
}
