// Fix typescript issues
import * as React from 'react';
import {
  Pressable as NativePressable,
  PressableProps as NativePressableProps,
  View
} from 'react-native';

export type PressableProps = NativePressableProps & {
  // Web properties
  onHoverIn?: (e: MouseEvent) => void;
  onHoverOut?: (e: MouseEvent) => void;
}

export const Pressable = React.forwardRef<View, PressableProps>((props: PressableProps, ref) => {
  return (
    <NativePressable ref={ref} {...props} />
  );
});