import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, TextProps as ReactTextProps, Text as ReactText } from 'react-native';
import { Popover } from './popover';
import { ThemeProvider, useTheme } from '../themes/theme';
import { PopoverPosition } from './popover/types';
import { StyleProp, TextStyle } from 'react-native-web';

export interface TextProps extends ReactTextProps {
  h1?: boolean,
  h2?: boolean,
  h3?: boolean,
  fontWeight?: any,
  style?: StyleProp<TextStyle>,
};

export const Text = React.forwardRef<ReactText, TextProps>((props: TextProps, ref) => {
  const {
    h1,
    h2,
    h3,
    fontWeight,
    style,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [showPopover, setShowPopover] = useState(false);
  const menu = useRef() as any;

  return (
    <ReactText
      ref={ref}
      style={[
        styles.text,
        !!h3 && styles.h3,
        !!h2 && styles.h2,
        !!h1 && styles.h1,
        { color: theme.primaryColor, fontWeight: fontWeight },
        style
      ]}
      {...otherProps}
    />
  )
});

const styles = StyleSheet.create({
  text: {
    fontSize: 14
  },

  h1: {
    fontSize: 22,
    ontWeight: '600'
  },

  h2: {
    fontSize: 18,
    ontWeight: '600'
  },

  h3: {
    fontSize: 14,
    fontWeight: '600'
  },
})
