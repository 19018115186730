
export enum NotificationType {
  // All time Maximum demand by region
  // AllTimeMaximumDemand = "ALL_TIME_MAXIMUM_DEMAND",
  // // All time Maximum generation by region
  // AllTimeMaximumGeneration = "ALL_TIME_MAXIMUM_GENERATION",
  // // All time Maximum generation by fuel type and region
  // AllTimeMaximumGenerationFuel = "ALL_TIME_MAXIMUM_GENERATION_FUEL",
  // // All time Maximum generation by % semi-scheduled and region
  // AllTimeMaximumGenerationSemiScheduled = "ALL_TIME_MAXIMUM_GENERATION_SEMI_SCHEDULED",
  // // All time Maximum generation by % scheduled and region
  // AllTimeMaximumGenerationScheduled = "ALL_TIME_MAXIMUM_GENERATION_SCHEDULED",
  //Demand over/under custom amount by region
  Demand = "DEMAND",
  //Generation over/under custom amount by region
  Generation = "GENERATION",
  //Generation by fuel type over/under custom amount by region
  GenerationFuel = "GENERATION_FUEL",
  //Price over/under custom amount by region
  Price = "PRICE",
  //Market notice by type(s)
  MarketNotice = "MARKET_NOTICE"
};

export const NOTIFICATION_TYPE_OPTIONS = [
  // { value: NotificationType.AllTimeMaximumDemand, label: "All Time Maximum Demand", toParamString: (parameters: any) => `${parameters.regions.join(',')}` },
  // { value: NotificationType.AllTimeMaximumGeneration, label: "All Time Maximum Generation", toParamString: (parameters: any) => `${parameters.regions.join(',')}` },
  // { value: NotificationType.AllTimeMaximumGenerationFuel, label: "All Time Maximum Generation Fuel", toParamString: (parameters: any) => `${parameters.regions.join(',')}` },
  // { value: NotificationType.AllTimeMaximumGenerationSemiScheduled, label: "All Time Maximum Generation Semi-Scheduled", toParamString: (parameters: any) => `${parameters.regions.join(',')}` },
  // { value: NotificationType.AllTimeMaximumGenerationScheduled, label: "All Time Maximum Generation Scheduled", toParamString: (parameters: any) => `${parameters.regions.join(',')}` },
  { value: NotificationType.Demand, label: "Demand", toParamString: (parameters: any) => `${parameters.regions.join(',')} ${parameters.condition} ${parameters.amount}` },
  { value: NotificationType.Generation, label: "Generation", toParamString: (parameters: any) => `${parameters.regions.join(',')} ${parameters.condition} ${parameters.amount}` },
  { value: NotificationType.GenerationFuel, label: "Generation Fuel", toParamString: (parameters: any) => `${parameters.regions.join(',')} - ${parameters.fuelTypes.join(',')} ${parameters.condition} ${parameters.amount}` },
  { value: NotificationType.Price, label: "Price", toParamString: (parameters: any) => `${parameters.regions.join(',')} ${parameters.condition} ${parameters.amount}` },
  { value: NotificationType.MarketNotice, label: "Market Notice", toParamString: (parameters: any) => `${parameters.noticeTypes.join(',')}` }
];


export const CONDITIONS = [
  { value: '>', label: 'Greater Than' },
  { value: '<', label: 'Less Than' },
];
