import React, { useEffect, useState } from 'react';
import { Linking, StyleSheet, TouchableOpacity, View, Image } from 'react-native';
import { Modal, ModalProps } from '../common/modal';
import { Text } from '../common/text';

export function RoadmapModal(props: ModalProps) {
  return (
    <Modal
      title='Roadmap'
      {...props}
    >
      <View style={styles.textContainer}>
        <View style={styles.item}>
          <Text style={styles.bullet}>Q3-Q4 2022</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>Android release</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>iOS release</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>Desktop release</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>Custom user dashboard arrangements</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>Gas market information</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>More notification options</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>Custom layouts</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>Additional views of the market</Text>
        </View>
        <View style={styles.item}>
          <Text style={styles.bullet}>{'\u2B24'}</Text><Text style={styles.bullet}>And more</Text>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  textContainer: {
    padding: 20
  },

  item: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 14
  },

  bullet: {
    paddingRight: 20
  },


})
