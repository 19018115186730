import { isFunction, resolveFieldData, formatDate } from "@energynow/core";
import * as d3 from "d3";
import { useMemo } from "react";
import { addHours } from "date-fns";


export const useMin = (data: any, field: ((item: any) => any) | string, ...watchers: any[]) => {
  return useMemo(
    () => d3.min(data, (item) => isFunction(field) ? (field as any)(item) : resolveFieldData(item, field)) as any,
    [data, ...watchers]
  );
};

export const useMax = (data: any, field: ((item: any) => any) | string, ...watchers: any[]) => {
  return useMemo(
    () => d3.max(data, (item) => isFunction(field) ? (field as any)(item) : resolveFieldData(item, field)) as any,
    [data, ...watchers]
  );
};

export const useScaleTime = (min: number | Date, max: number | Date, pixels: number) => {
  return useMemo(
    () => d3.scaleTime().domain([min, max]).range([0, pixels]),
    [min, max, pixels]
  ) as any;
};

export const useScaleLinear = (min: number, max: number, pixels: number) => {
  return useMemo(() => {
    const indention = (max - min) * 0.2;
    return d3
      .scaleLinear()
      .domain([min - indention, max + indention])
      .range([pixels, 0]);
  }, [pixels, min, max])
};

export const useScaleBand = (min: any, max: any, pixels: number) => {
  return useMemo(
    () => d3.scaleBand().domain([min, max]).range([pixels, 0]),
    [pixels, min, max]
  )
};

export const formatMW = (d: any) => {
  return `${parseFloat(d) > 0 ? "" : "-"}${d3.format(",.2r")(d)} MW`;
}

export const formatMWh = (d: any) => {
  return `${parseFloat(d) > 0 ? "" : "-"}${d3.format(",.2r")(d)} MWh`;
}

export const formatNumber = (d: any) => {
  return `${parseFloat(d) > 0 ? "" : "-"}${d3.format(",.2r")(d)}`;
}

export const formatPrice = (d: any) => {
  return `${d3.format("-$,.2f")(d)}`;
}

export const formatIntegerPrice = (d: any) => {
  return `${d3.format("-$,.0f")(d)}`;
}

export const formatDateTime = (d: any) => {
  /*if (d3.timeFormat("%b")(d) === "Jan") {
    return d3.timeFormat("%Y")(d);
  }*/
  if (d instanceof Date)
    return formatDate(d, "HH:mm")
  else 
    return '';
};

export const formatDateTimeWithTimezone = (timezone: string) => (d: any) => {
  /*if (d3.timeFormat("%b")(d) === "Jan") {
    return d3.timeFormat("%Y")(d);
  }*/
  if (d instanceof Date)
    return formatDate(d, "HH:mm", timezone)
  else 
    return '';
};

export const formatDateOnlyWithTimezone = (timezone: string) => (d: any) => {
  if (d instanceof Date)
    return formatDate(d, "dd/MM/yyyy", timezone)
  else
    return '';
};

export const formatEndIntervalDateAsMonthWithTimezone = (timezone: string) => (d: any) => {
  if (d instanceof Date) 
    return formatDate(addHours(d, -1), 'MMM', timezone);
  else
    return '';
}
