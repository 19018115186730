
export const REGIONS = [
  { value: 'QLD1', label: 'QLD', selectedColor: '' as any },
  { value: 'NSW1', label: 'NSW' },
  { value: 'VIC1', label: 'VIC' },
  { value: 'SA1', label: 'SA' },
  { value: 'TAS1', label: 'TAS' }
];

export const FUEL_TYPES = [
  { value: 'Coal', label: 'Coal' },
  { value: 'Gas', label: 'Gas' },
  { value: 'Wind', label: 'Wind' },
  { value: 'Solar', label: 'Solar' },
  { value: 'Battery', label: 'Battery' },
  { value: 'Hydro', label: 'Hydro' },
  { value: 'Diesel', label: 'Diesel' },
  { value: 'Liquid fuel', label: 'Liquid Fuel' },
  { value: 'Biofuel', label: 'Biofuel' },
  { value: 'Mixed', label: 'Mixed' },
  { value: 'Other', label: 'Other' }
];

export const PERIODS = [
  { label: 'Live' },
  { label: '1D' },
  //{ label: '3D' },
  // { label: '1M' },
  // { label: '3M' },
  // { label: '6M' },
  // { label: 'YTD' },
  // { label: '1Y' },
  // { label: '5Y' },
];

export const MARKET_NOTICE_TYPES = [
  { value: 'ACTUAL LOR1', label: 'LOR1' },
  { value: 'ACTUAL LOR2', label: 'LOR2' },
  { value: 'ACTUAL LOR3', label: 'LOR3' },
  { value: 'ADMINISTERED PRICE CAP', label: 'Administered Price Cap' },
  { value: 'CONSTRAINTS', label: 'Constraints' },
  { value: 'FORCED MAJEURE', label: 'Forced Majeure' },
  { value: 'FORECAST LOR1', label: 'Forecast LOR1' },
  { value: 'FORECAST LOR2', label: 'Forecast LOR2' },
  { value: 'FORECAST LOR3', label: 'Forecast LOR3' },
  { value: 'GENERAL NOTICE', label: 'General Notice' },
  { value: 'INTER-REGIONAL TRANSFER', label: 'Inter-regional Transfer' },
  { value: 'LOAD RESTORE', label: 'Load Restore' },
  { value: 'LOAD SHED', label: 'Load Shed' },
  { value: 'MANDATORY RESTRICTIONS', label: 'Mandatory Restrictions' },
  { value: 'MARKET DIRECTION', label: 'Market Direction' },
  { value: 'MARKET INTERVENTION', label: 'Market Intervention' },
  { value: 'MARKET SUSPENSION', label: 'Market Suspension' },
  { value: 'MARKET SYSTEMS', label: 'Market Systems' },
  { value: 'MPDI', label: 'MPDI' },
  { value: 'NEM SYSTEMS', label: 'NEM Systems' },
  { value: 'NON-CONFORMANCE', label: 'Non-conformance' },
  { value: 'POWER SYSTEM EVENTS', label: 'Power System Events' },
  { value: 'POWER SYSTEM SECURITY', label: 'Power System Security' },
  { value: 'PRICE ADJUSTMENT', label: 'Price Adjustment' },
  { value: 'PRICES SUBJECT TO REVIEW', label: 'Prices Subject To Review' },
  { value: 'PRICES UNCHANGED', label: 'Prices Unchanged' },
  { value: 'PROCESS INVESTIGATION', label: 'Process Investigation' },
  { value: 'PROCESS REVIEW', label: 'Process Review' },
  { value: 'PROTECTED EVENT', label: 'Protected Event' },
  { value: 'RECALL GEN CAPACITY', label: 'Recall Gen Capacity' },
  { value: 'RECLASSIFY CONTINGENCY', label: 'Reclassify Contingency' },
  { value: 'RESERVE NOTICE', label: 'Reserve Notice' },
  { value: 'SETTLEMENTS RESIDUE', label: 'Settlements Residue' },
  { value: 'SYSTEM RECONFIGURATION', label: 'System Reconfiguration' },
  { value: 'SYSTEM RESTART', label: 'System Restart' },
  { value: 'VOLL', label: 'VOLL' },
];

export const DISPATCH_CATEGORIES = [
  { value: 'totalDemand', label: 'Demand' },
  { value: 'initialSupply', label: 'Generation' },
  { value: 'price', label: 'Price' }
];
