import { getToken } from '@energynow/core';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { MenuItem } from '../common/menu-item';
import { ModalProps } from '../common/modal';
import { SettingsPanel } from '../common/settings';
import { Switch } from '../common/switch';
import { Text } from '../common/text';
import { Devices } from '../devices';
import { selectSettings, setSettings } from '../layout/layout-slice';
import { Notifications } from '../notifications';
import { Account } from '../profile/account';
import { setDeviceAsync, selectDevice } from './settings-slice';


export function SettingsModal(props: ModalProps) {

  const menuItems = [
    { text: 'Notifications', children: (<Notifications></Notifications>) },
    { text: 'Devices', children: (<Devices></Devices>) },
    { text: 'Account', children: (<Account></Account>) },
    // { text: 'Appearance', children: (<View><Text>Blah2</Text></View>) },
    // { text: 'Keybindings', children: (<View><Text>Blah2</Text></View>) },
    // { text: 'Help', children: (<View><Text>Blah2</Text></View>) }
  ];

  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectSettings);

  const activeMenuItem = () => {
    return settings === true || settings === undefined ? menuItems[0] : menuItems.find(x => x.text === settings);
  }

  const [selectedMenuItem, setSelectedMenuItem] = useState(activeMenuItem());

  useEffect(() => {
    setSelectedMenuItem(activeMenuItem())
  }, [settings]);

  return (
    <SettingsPanel
      menuItems={menuItems}
      onMenuItemChange={(value) => setSelectedMenuItem(value)}
      menuItemValue={selectedMenuItem}
      visible={settings !== undefined}
      onRequestClose={() => dispatch(setSettings(undefined))}
      {...props}>
    </SettingsPanel>
  )
}

const styles = StyleSheet.create({
  settingsModal: {
    display: "flex",
    flexDirection: "row",
    height: 400
  },
})
