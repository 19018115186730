import { DAILY_DISPATCH_QUERY, parseDate, parseDateCustom, formatDateTime as cFormatDateTime, getDispatchHub, DISPATCH_HUB_LATEST_DISPATCH, getCurrentDispatchInterval, RANGE_DISPATCH_QUERY, formatDateTimeUrl } from '@energynow/core';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Dimensions, LayoutRectangle, Text, View, useWindowDimensions } from 'react-native';
import { useQuery } from '../../../hooks/apollo';
import { Share } from '../../../utils/share';
import { Select } from '../../common/select';
import { Loading } from '../../common/loading';
import { ErrorLoading } from '../../common/error-loading';
import { Visualisation } from '../../common/visualisation';
import { useTheme } from '../../themes/theme';
import { DISPATCH_CATEGORIES } from '../constants';
import {
  formatPrice,
  formatMW,
  formatDateTimeWithTimezone,
  useMin,
  useMax,
  useScaleTime,
  useScaleLinear,
  useScaleBand,
  formatNumber,
  Axis,
  Line,
  Overlay,
  Area,
  formatIntegerPrice
} from '../../common/chart';
import { RangeChart } from '../../common/chart/range-chart';
// @ts-ignore
import DatePicker from '../../common/datepicker';
import { useHubToListen } from '../../../hooks/signalr';
import { addDays } from 'date-fns';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedDate } from '../../layout/layout-slice';
import { CursorPosition } from '../../common/chart/overlay/cursor';
import Svg, { G, Rect, Line as SvgLine } from 'react-native-svg';
import { Cursor } from '../../common/chart/cursor';

export function RangeDispatch(props: any) {
  const params = props.route?.params;
  const initialDate = params?.date ? params.date : null;
  const initialFromDate = initialDate ? addDays(initialDate, -1) : null;
  const theme = useTheme();

  const globalSelectedDate = useAppSelector(selectSelectedDate);
  const latestDispatch = useHubToListen(getDispatchHub(), DISPATCH_HUB_LATEST_DISPATCH);
  const { loading, error, data, refetch } = useQuery(RANGE_DISPATCH_QUERY, {
    variables: {
      fromDate: initialFromDate,
      toDate: initialDate,
      period: 'P1D'
    }
  });

  const [selectedCategory, setSelectedCategory] = useState<Array<string>>(params?.category || props.category || ['totalDemand']);
  const [selectedRegions, setSelectedRegions] = useState(params?.region || ['QLD1'] as any);
  const [selectedPeriod, setSelectedPeriod] = useState(initialDate ? '' : 'Live');
  const [timezone, setTimezone] = useState('+10:00');
  const [visualisationRectangle, setVisualisationRectangle] = useState<LayoutRectangle>({ width: 0, height: 0, x: 0, y: 0 });
  //const { x, y, height, width } = ;
  const onPeriodChange = (value: any) => {
    setSelectedPeriod(value);
  }

  const categoryField = 'settlementDate';
  const [selectedDate, setSelectedDate] = useState<Date | null>(initialDate);
  const overlayRef = useRef<Rect>();
  const [cursorPosition, setCursorPosition] = useState<CursorPosition>();


  const processedData = useMemo(() => {
    const results: any[] = [];
    if (!loading && !error) {
      for (let dispatchInterval of data.dispatchKeys) {
        const { settlementDate } = dispatchInterval;
        const dict: any = {};
        for (let dispatchRegionSum of dispatchInterval.dispatchRegionSum) {
          let { regionId, totalDemand, initialSupply } = dispatchRegionSum;
          dict[regionId] = { settlementDate, regionId, totalDemand, initialSupply };
        }
        for (let dispatchPrice of dispatchInterval.dispatchPrice) {
          let { regionId, price } = dispatchPrice;
          dict[regionId].price = price;
        }

        for (let entry in dict) {
          results.push(dict[entry]);
        }
      }
    }
    return results;
  }, [data, loading, error]);

  const xMin = useMin(processedData, categoryField);
  const xMax = useMax(processedData, categoryField);
  const xScale = useScaleTime(xMin, xMax, visualisationRectangle.width);
  const defaultMin = 9999;
  const defaultMax = 0;
  const yMin = useMin(processedData, (item: any) => {
    if (selectedRegions.includes(item.regionId)) {
      const demand = selectedCategory.includes('totalDemand') ? item.totalDemand : defaultMin;
      const generation = selectedCategory.includes('initialSupply') ? item.initialSupply : defaultMin;
      return Math.min(demand, generation) - 200;
    }

    return defaultMin;
  }, [selectedRegions]);

  const yMax = useMax(processedData, (item: any) => {
    if (selectedRegions.includes(item.regionId)) {
      const demand = selectedCategory.includes('totalDemand') ? item.totalDemand : defaultMax;
      const generation = selectedCategory.includes('initialSupply') ? item.initialSupply : defaultMax;
      return Math.max(demand, generation);
    }

    return defaultMax;
  }, [selectedRegions]);

  const yScale = useScaleLinear(yMin, yMax, visualisationRectangle.height);
  const yScaleForAxis = useScaleBand(yMin, yMax, visualisationRectangle.height);

  const yMinPrice = useMin(processedData, (item: any) =>
    selectedRegions.includes(item.regionId) ? item.price : 9999, [selectedRegions]
  );
  const yMaxPrice = useMax(processedData, (item: any) =>
    selectedRegions.includes(item.regionId) ? item.price : 0, [selectedRegions]
  );
  const yScalePrice = useScaleLinear(yMinPrice, yMaxPrice, visualisationRectangle.height);

  const regionLine = (region: any, index: number) => {
    return (
      <G key={index}>
        {selectedCategory.includes('totalDemand') && < Line
          data={processedData.filter((x: any) => x.regionId === region)}
          xScale={xScale}
          yScale={yScale}
          color={(theme as any)[`${region.toLowerCase()}Color`]
          }
          valueField={'totalDemand'}
          categoryField={categoryField}
          key={`RegionLineDemand-${index}`
          }
        />
        }

        {selectedCategory.includes('initialSupply') && < Area
          data={processedData.filter((x: any) => x.regionId === region)}
          xScale={xScale}
          yScale={yScale}
          color={(theme as any)[`${region.toLowerCase()}Color`]
          }
          valueField={'initialSupply'}
          categoryField={categoryField}
          key={`RegionLineSupply-${index}`
          }
        />
        }

        {selectedCategory.includes('price') && < Line
          data={processedData.filter((x: any) => x.regionId === region)}
          xScale={xScale}
          yScale={yScalePrice}
          color={(theme as any)[`${region.toLowerCase()}DarkColor`]
          }
          valueField={'price'}
          categoryField={categoryField}
          dash={false}
          step={true}
          strokeWidth={0.8}
          key={`RegionLinePrice-${index}`
          }
        />
        }
      </G>
    )
  }

  useEffect(() => {
    let toDate: Date | null = null;
    let fromDate: Date | null = null;
    let period: string | null = null;
    if (selectedPeriod !== '') {
      if (selectedPeriod === '1D') {
        period = 'P1D';
      } else if (selectedPeriod === '2D') {
        period = 'P2D';
      } else if (selectedPeriod === '3D') {
        period = 'P3D';
      } else if (selectedPeriod === 'Live') {
        fromDate = null;
        toDate = null;
        period = 'P1D';
      }
    } else {
      toDate = selectedDate as Date;
      fromDate = addDays(selectedDate as Date, -1);
      period = 'P1D';
    }
    refetch({
      fromDate: fromDate?.toISOString() as any || null,
      toDate: toDate?.toISOString() as any || null,
      period: period as any
    })
  }, [selectedPeriod, selectedDate]);

  useEffect(() => {
    if (selectedPeriod === 'Live') {
      refetch({
        fromDate: null,
        toDate: null,
        period: 'P1D'
      });
    }
  }, [latestDispatch]);

  useEffect(() => {
    if (globalSelectedDate) {
      onDatePicked(globalSelectedDate);
    } else if (globalSelectedDate === null) {
      setSelectedPeriod('Live');
    }
  }, [globalSelectedDate]);

  const onDatePicked = (date: string) => {
    if (timezone != '') {
      setSelectedDate(parseDateCustom(`${date} ${timezone}`, 'yyyy/MM/dd HH:mm XXX'));
    } else {
      setSelectedDate(parseDateCustom(date, 'yyyy/MM/dd HH:mm'));
    }
    setSelectedPeriod('');
  };

  const { height, scale, width } = useWindowDimensions();

  const periods = [
    { label: 'Live' },
    { label: '2D' },
    { label: '3D' },
  ];

  let regions = [
    { value: 'QLD1', label: 'QLD', selectedColor: '' as any },
    { value: 'NSW1', label: 'NSW' },
    { value: 'VIC1', label: 'VIC' },
    { value: 'SA1', label: 'SA' },
    { value: 'TAS1', label: 'TAS' }
  ];

  for (const region of regions) {
    region.selectedColor = (theme as any)[`${region.value.toLowerCase()}Color`];
  }

  if (!!error) {
    return <ErrorLoading onPressReload={() => { refetch() }} />
  }


  const noData = data?.dispatchKeys?.length === 0;

  return (
    <Visualisation
      allowFullscreen={true}
      aspectRatio={4 / 3}
      border={true}
      utcTime={noData ? undefined : data?.dispatchKeys[data.dispatchKeys.length - 1].settlementDate}
      loading={loading && !data?.dispatchKeys}
      noData={noData}
      refreshing={loading}
      onShare={() => { Share('Dispatch', `https://www.energydash.com.au/visualisation/dispatch/${formatDateTimeUrl(data?.dispatchKeys[0].settlementDate)}/${selectedCategory.join(',')}/${selectedRegions.join(',')}`) }}
      onLayout={(layout) => setVisualisationRectangle(layout)}
      onTimezone={(timezone, description) => setTimezone(timezone)}
      filters={
        <View style={{ flex: -1, flexDirection: 'row' }}>
          <Select
            multiple={true}
            options={regions}
            compact={true}
            onChange={(value) => setSelectedRegions(value)}
            value={selectedRegions}
          ></Select>
          <Select
            style={{ marginLeft: 36 }}
            multiple={true}
            compact={true}
            options={DISPATCH_CATEGORIES}
            onChange={(value) => setSelectedCategory(value)}
            maxSelectedLabels={1}
            value={selectedCategory}
          ></Select>
        </View>
      }
      dateFilters={
        <View style={{ flexDirection: 'row', alignContent: 'flex-start' }}>
          <Select
            multiple={false}
            options={periods}
            optionValue='label'
            responsive={false}
            compact={true}
            onChange={(value) => onPeriodChange(value)}
            value={selectedPeriod}
            style={{ width: 99 }}
          ></Select>
          <DatePicker style={{ minWidth: 40 }} selected={selectedDate} maximumDate={cFormatDateTime(latestDispatch?.settlementDate ? parseDate(latestDispatch.settlementDate) : new Date(), 'yyyy/MM/dd HH:mm')} active={selectedPeriod === ''} onSelectedChange={onDatePicked} />
        </View>
      }
    >
      {selectedRegions.length > 0 && selectedCategory.length > 0 && <Svg width={visualisationRectangle.width} height={visualisationRectangle.height}>
        <G>
          {selectedRegions.map((x: any, index: number) => regionLine(x, index))}
          {/* {regions.map((x: any, index: number) => regionArea(x, index))} */}

          <Axis
            orient="left"
            scale={yScale}
            transform={`translate(10, 0)`}
            ticks={visualisationRectangle.height / 100}
            domain={false}
            tickFormat={formatNumber}
            tickSize={visualisationRectangle.width} />

          {selectedCategory.includes('price') &&
            <G>
              <Axis
                orient="right"
                scale={yScalePrice}
                transform={`translate(${visualisationRectangle.width - 10}, 0)`}
                ticks={visualisationRectangle.height / 100}
                domain={false}
                tickFormat={formatIntegerPrice}
                tickSize={visualisationRectangle.width} />
            </G>
          }

          <Overlay width={visualisationRectangle.width} height={visualisationRectangle.height} ref={overlayRef as any} onCursor={(event: CursorPosition) => setCursorPosition(event)}>
            <Axis
              orient="bottom"
              scale={xScale}
              transform={`translate(0, ${visualisationRectangle.height - 30})`}
              ticks={visualisationRectangle.width / 100}
              tickFormat={formatDateTimeWithTimezone(timezone)}
              domain={false}
              tickSize={visualisationRectangle.height}
              anchorEl={overlayRef}

            />

            <Cursor
              cursorPosition={cursorPosition}
              data={processedData}
              xScale={xScale}
              yScale={yScale}
              valueField={(item) => item.find((x: any) => x.regionId === "NSW1")}
              categoryField={categoryField}
              height={visualisationRectangle.height}
              width={visualisationRectangle.width}
              xTickFormatter={formatDateTimeWithTimezone(timezone)}
              yTickFormatter={formatMW}
            ></Cursor>
          </Overlay>

        </G>
      </Svg>
      }
      {/* <RangeChart
        data={processedData}
        width={visualisationRectangle.width}
        height={visualisationRectangle.height}
        categoryField='settlementDate'
        valueField={categoryToValueField[selectedCategory]}
        regions={selectedRegions}
        xTickFormatter={formatDateTime}
        yTickFormatter={selectedCategory === 'PRICE' ? formatPrice : formatMW}
      /> */}
      {/* <ExampleChart2></ExampleChart2> */}
    </Visualisation>
  )
}
