import React, { createContext, useReducer, useContext, useState, useEffect } from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import { Button } from '../../button';
import { ModalSlide } from '../../modal-slide';
import { Popover } from '../../popover';
import PropTypes from 'prop-types';
import { useTheme } from '../../../themes/theme';
import { DEVICE_WIDTH } from '../../constants';

import { Calendar, SelectMonth, SelectTime } from './components';
import { utils } from '../utils';

const options = {
  backgroundColor: '#fff',
  textHeaderColor: '#212c35',
  textDefaultColor: '#2d4150',
  selectedTextColor: '#fff',
  mainColor: '#61dafb',
  textSecondaryColor: '#7a92a5',
  borderColor: 'rgba(122, 146, 165, 0.1)',
  defaultFont: 'System',
  headerFont: 'System',
  textFontSize: 15,
  textHeaderFontSize: 17,
  headerAnimationDistance: 100,
  daysAnimationDistance: 200,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action };
    case 'toggleMonth':
      return { ...state, monthOpen: !state.monthOpen };
    case 'toggleTime':
      return { ...state, timeOpen: !state.timeOpen };
    default:
      throw new Error('Unexpected action');
  }
};

const CalendarContext = createContext();

const useCalendar = () => {
  const contextValue = useContext(CalendarContext);
  return contextValue;
};

const DatePicker = props => {
  const calendarUtils = new utils(props);
  const contextValue = {
    ...props,
    reverse: props.reverse === 'unset' ? !props.isGregorian : props.reverse,
    options: { ...options, ...props.options },
    utils: calendarUtils,
    state: useReducer(reducer, {
      activeDate: props.current || calendarUtils.getToday(),
      selectedDate: '',
      monthOpen: props.mode === 'monthYear',
      timeOpen: props.mode === 'time',
    }),
    show: (visible = true) => setOverlayVisibleState(visible)
  };

  const theme = useTheme();
  const [minHeight, setMinHeight] = useState(300);
  const [smallScreen, setSmallScreen] = useState(false);
  const [overlayVisibleState, setOverlayVisibleState] = React.useState(false);
  const { width, height } = useWindowDimensions();
  const style = styles(contextValue.options);

  // useEffect(() => {
  //   contextValue.state.selectedDate = props.selected ? calendarUtils.getFormated(props.selected) : '';
  // }, [props.selected]);

  const renderBody = () => {
    switch (contextValue.mode) {
      default:
      case 'datepicker':
        return (
          <React.Fragment>
            <Calendar />
            <SelectMonth />
            <SelectTime />
          </React.Fragment>
        );
      case 'calendar':
        return (
          <React.Fragment>
            <Calendar />
            <SelectMonth />
          </React.Fragment>
        );
      case 'monthYear':
        return <SelectMonth />;
      case 'time':
        return <SelectTime />;
    }
  };

  useEffect(() => {
    setSmallScreen(width < DEVICE_WIDTH.sm);
  }, [width]);

  return (
    <CalendarContext.Provider value={contextValue}>
      <View style={[props.style, style.pickerContainer]}>
        {!smallScreen &&
          <Popover
            visible={overlayVisibleState}
            position='bottom'
            onClose={() => setOverlayVisibleState(false)}
            content={(
              <View style={[
                style.pickerPopover,
                {
                  backgroundColor: theme.popoverBackgroundColor,
                  width: 340
                }
              ]}>
                {renderBody()}
                {props.onLivePress &&
                  <Button
                    text='Live'
                    fillWidth={true}
                    textAlign='center'
                    selected={true}
                    onPress={() => { setOverlayVisibleState(false); props.onLivePress(); }}>
                  </Button>}
              </View>
            )}
          >
            <Button width={100} withText={false} icon='calendar' onPress={() => setOverlayVisibleState(true)} selected={props.active} />
          </Popover>
        }
        {!!smallScreen && (
          <View>
            <Button withText={false} icon='calendar' onPress={() => setOverlayVisibleState(true)} selected={props.active} />
            <ModalSlide title='Select Date' visible={overlayVisibleState} animationType="fade" onDismiss={() => setOverlayVisibleState(false)}>
              <View style={[
                styles.multiSelectModal,
                { backgroundColor: theme.popoverBackgroundColor },
              ]}>

                {renderBody()}
                {props.onLivePress &&
                  <Button
                    style={{ flex: 0, minHeight: 40 }}
                    text='Live' fillWidth={true}
                    textAlign='center'
                    selected={true}
                    onPress={() => { setOverlayVisibleState(false); props.onLivePress(); }}>
                  </Button>}
              </View>
            </ModalSlide>
          </View>
        )
        }
        {/* <CalendarContext.Provider value={contextValue}>

        <View
          style={[style.container, { minHeight }, props.style]}
          onLayout={({ nativeEvent }) => setMinHeight(nativeEvent.layout.width * 0.9 + 55)}>
          {renderBody()}
        </View>
      </CalendarContext.Provider> */}
      </View >
    </CalendarContext.Provider >
  );
};

const styles = theme =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.backgroundColor,
      position: 'relative',
      //width: '100%',
      overflow: 'hidden',
    },

    pickerContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      flex: 0,
      justifyContent: 'center'
    },

    pickerPopover: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      borderRadius: 2,
      paddingVertical: 6,
      shadowColor: '#0003',
      shadowRadius: 4,
      shadowOffset: { width: 0, height: 2 }
    },
  });

const optionsShape = {
  backgroundColor: PropTypes.string,
  textHeaderColor: PropTypes.string,
  textDefaultColor: PropTypes.string,
  selectedTextColor: PropTypes.string,
  mainColor: PropTypes.string,
  textSecondaryColor: PropTypes.string,
  borderColor: PropTypes.string,
  defaultFont: PropTypes.string,
  headerFont: PropTypes.string,
  textFontSize: PropTypes.number,
  textHeaderFontSize: PropTypes.number,
  headerAnimationDistance: PropTypes.number,
  daysAnimationDistance: PropTypes.number,
};
const modeArray = ['datepicker', 'calendar', 'monthYear', 'time'];
const minuteIntervalArray = [1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30, 60];

DatePicker.defaultProps = {
  onSelectedChange: () => null,
  onMonthYearChange: () => null,
  onTimeChange: () => null,
  onDateChange: () => null,
  current: '',
  selected: null,
  minimumDate: '',
  maximumDate: '',
  selectorStartingYear: 0,
  selectorEndingYear: 3000,
  disableDateChange: false,
  isGregorian: true,
  configs: {},
  reverse: 'unset',
  options: {},
  mode: 'datepicker',
  minuteInterval: 5,
  style: {},
  active: false,
  onLivePress: null,
};

DatePicker.propTypes = {
  onSelectedChange: PropTypes.func,
  onMonthYearChange: PropTypes.func,
  onTimeChange: PropTypes.func,
  onDateChange: PropTypes.func,
  current: PropTypes.string,
  selected: PropTypes.any,
  minimumDate: PropTypes.string,
  maximumDate: PropTypes.string,
  selectorStartingYear: PropTypes.number,
  selectorEndingYear: PropTypes.number,
  disableDateChange: PropTypes.bool,
  isGregorian: PropTypes.bool,
  configs: PropTypes.object,
  reverse: PropTypes.oneOf([true, false, 'unset']),
  options: PropTypes.shape(optionsShape),
  mode: PropTypes.oneOf(modeArray),
  minuteInterval: PropTypes.oneOf(minuteIntervalArray),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  active: PropTypes.bool,
  onLivePress: PropTypes.func,
};

export { DatePicker, CalendarContext, useCalendar };
