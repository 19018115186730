import React, { useState, useMemo } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useCalendar } from '../DatePicker';
import { useTheme } from '../../../../themes/theme';
import { Text } from '../../../text';

const Days = () => {
  const { options, state, utils, onDateChange, selected } = useCalendar();
  const [mainState, setMainState] = state;
  const [itemSize, setItemSize] = useState(0);
  const theme = useTheme();
  const style = styles(theme);
  const days = useMemo(() => utils.getMonthDays(mainState.activeDate));

  const onSelectDay = date => {
    const newTime = utils.getDate(mainState.activeDate);
    const newDate = utils.getDate(date);
    newDate.setHours(newTime.getHours());
    newDate.setMinutes(newTime.getMinutes());

    setMainState({
      type: 'set',
      selectedDate: utils.getFormated(newDate, 'dateTimeFormat')
    });
    onDateChange(newDate);
  };

  const changeItemHeight = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout;
    !itemSize && setItemSize((width / 7).toFixed(2) * 1 - 0.5);
  };

  return (
    <View style={[style.container, utils.flexDirection]} onLayout={changeItemHeight}>
      {days.map((day, n) => (
        <View
          key={n}
          style={{
            width: itemSize,
            height: itemSize,
          }}>
          {day && (
            <TouchableOpacity
              style={[
                style.dayItem,
                {
                  borderRadius: itemSize / 2,
                },
                selected && utils.getFormated(selected) === day.date && style.dayItemSelected,
              ]}
              onPress={() => !day.disabled && onSelectDay(day.date)}
              activeOpacity={0.8}>
              <Text
                style={[
                  style.dayText,
                  day.disabled && style.dayTextDisabled,
                ]}>
                {day.dayString}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      ))}
    </View>
  );
};

const styles = theme =>
  StyleSheet.create({
    container: {
      width: '100%',
      flexWrap: 'wrap',
    },
    dayItem: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      margin: 3,
    },
    dayItemSelected: {
      backgroundColor: theme.brandBackgroundColor,
    },
    dayText: {
      textAlign: 'center',
      width: '100%',
    },
    dayTextSelected: {
      color: theme.brandColor,
    },
    dayTextDisabled: {
      opacity: 0.2,
    },
  });

export { Days };
