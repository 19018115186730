import { createTheming } from '@callstack/react-theme-provider';

export type Theme = {
  brandColor: string,
  brandBackgroundColor: string,
  primaryColor: string,
  primaryBackgroundColor: string,
  primaryBackgroundHoverColor: string,
  primaryBorderColor: string,
  secondaryColor: string,
  secondaryBackgroundColor: string,
  popoverColor: string,
  popoverBackgroundColor: string,
  errorBorderColor: string,
  successBorderColor: string,
  overlayBackgroundColor: string,

  // Region colours
  nsw1Color: string,
  nsw1BackgroundColor: string,
  nsw1DarkColor: string,
  qld1Color: string,
  qld1BackgroundColor: string,
  qld1DarkColor: string,
  vic1Color: string,
  vic1BackgroundColor: string,
  vic1DarkColor: string,
  sa1Color: string,
  sa1BackgroundColor: string,
  sa1DarkColor: string,
  tas1Color: string,
  tas1BackgroundColor: string,
  tas1DarkColor: string,

  // Chart colours
  gridLineColor: string,
  gridLabelColor: string,
  cursorLineColor: string,
  cursorLineVisible: boolean,
  chartColors: Array<string>,

  // Fuel colors
  brownCoalColor: string,
  blackCoalColor: string,
  coalColor: string,
  gasColor: string,
  hydroColor: string,
  windColor: string,
  liquidFuelColor: string,
  unknownColor: string,
  batteryColor: string,
  solarColor: string,
  biofuelColor: string,
  dieselColor: string,


}

export const themes: { [key: string]: Theme } = {
  default: {
    brandColor: '#ffffff',
    brandBackgroundColor: '#2962FF',
    primaryColor: '#131722',
    primaryBackgroundColor: '#ffffff',
    primaryBackgroundHoverColor: '#F0F3FA',
    primaryBorderColor: '#e0e3eb',
    secondaryColor: '#131722',
    secondaryBackgroundColor: '#ffffff',
    popoverColor: '#131722',
    popoverBackgroundColor: '#ffffff',
    errorBorderColor: '#e24c4c',
    successBorderColor: '#89d782',
    overlayBackgroundColor: 'rgba(0,0,0,0.2)',

    // Region colours
    nsw1Color: '#1AC9E6',
    nsw1BackgroundColor: 'string',
    nsw1DarkColor: '#1399af',
    qld1Color: '#f44336',
    qld1BackgroundColor: 'string',
    qld1DarkColor: "#d6190b",
    vic1Color: '#ff9800',
    vic1BackgroundColor: 'string',
    vic1DarkColor: '#c17300',
    sa1Color: '#7D3AC1',
    sa1BackgroundColor: 'string',
    sa1DarkColor: '#5e2c92',
    tas1Color: '#1DE48D',
    tas1BackgroundColor: 'string',
    tas1DarkColor: '#14ae6b',

    // Chart colours
    gridLineColor: '#ececec',
    gridLabelColor: '#131722',
    cursorLineColor: '#000000',
    cursorLineVisible: false,
    chartColors: [
      '#2ec7c9',
      '#b6a2de',
      '#5ab1ef',
      '#ffb980',
      '#d87a80',
      '#8d98b3',
      '#e5cf0d',
      '#97b552',
      '#95706d',
      '#dc69aa',
      '#07a2a4',
      '#9a7fd1',
      '#588dd5',
      '#f5994e',
      '#c05050',
      '#59678c',
      '#c9ab00',
      '#7eb00a',
      '#6f5553',
      '#c14089'
    ],

    // Fuel type colors
    blackCoalColor: '#8d98b3',
    brownCoalColor: '#95706d',
    coalColor: '#95706d',
    gasColor: '#f5994e',
    hydroColor: '#2ec7c9',
    windColor: '#97b552',
    liquidFuelColor: '#dc69aa',
    unknownColor: '#b6a2de',
    batteryColor: '#5ab1ef',
    solarColor: '#FFE101',
    biofuelColor: '#e5cf0d',
    dieselColor: '#d87a80',
  },
  dark: {
    brandColor: '#fff',
    brandBackgroundColor: '#2962FF',
    primaryColor: '#b2b5be',
    primaryBackgroundColor: '#1e222d',
    primaryBackgroundHoverColor: '#2A2E39',
    primaryBorderColor: '#2A2E39',
    secondaryColor: '#b2b5be',
    secondaryBackgroundColor: '#181C27',
    popoverColor: '#b2b5be',
    popoverBackgroundColor: '#1e222d',
    errorBorderColor: '#e24c4c',
    successBorderColor: '#89d782',
    overlayBackgroundColor: 'rgba(255,255,255,0.1)',

    // Region colours
    nsw1Color: '#1AC9E6',
    nsw1BackgroundColor: 'string',
    nsw1DarkColor: '#1399af',
    qld1Color: '#f44336',
    qld1BackgroundColor: 'string',
    qld1DarkColor: "#d6190b",
    vic1Color: '#ff9800',
    vic1BackgroundColor: 'string',
    vic1DarkColor: '#c17300',
    sa1Color: '#7D3AC1',
    sa1BackgroundColor: 'string',
    sa1DarkColor: '#5e2c92',
    tas1Color: '#1DE48D',
    tas1BackgroundColor: 'string',
    tas1DarkColor: '#14ae6b',

    // Chart colours
    gridLineColor: '#131722',
    gridLabelColor: '#b2b5be',
    cursorLineColor: '#000000',
    cursorLineVisible: false,
    chartColors: [
      '#2ec7c9',
      '#b6a2de',
      '#5ab1ef',
      '#ffb980',
      '#d87a80',
      '#8d98b3',
      '#e5cf0d',
      '#97b552',
      '#95706d',
      '#dc69aa',
      '#07a2a4',
      '#9a7fd1',
      '#588dd5',
      '#f5994e',
      '#c05050',
      '#59678c',
      '#c9ab00',
      '#7eb00a',
      '#6f5553',
      '#c14089'
    ],

    // Fuel type colors
    blackCoalColor: '#8d98b3',
    brownCoalColor: '#95706d',
    coalColor: '#95706d',
    gasColor: '#f5994e',
    hydroColor: '#2ec7c9',
    windColor: '#97b552',
    liquidFuelColor: '#dc69aa',
    unknownColor: '#b6a2de',
    batteryColor: '#5ab1ef',
    solarColor: '#FFE101',
    biofuelColor: '#e5cf0d',
    dieselColor: '#d87a80',
  }
};

const { ThemeProvider, withTheme, useTheme } = createTheming(themes.default);
export { ThemeProvider, withTheme, useTheme };



