import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyleSheet, View, ViewProps, Text, TouchableWithoutFeedback, GestureResponderEvent, Pressable, StyleProp } from 'react-native'
import { Hoverable } from 'react-native-web-hover'
import Icon from 'react-native-vector-icons/AntDesign';
import { ThemeProvider, useTheme } from '../themes/theme';
import { ViewStyle } from 'react-native-web';
import { color } from 'd3';

export type TextAlign = 'flex-start' | 'flex-end' | 'center';

export type ButtonProps = {
  visible?: boolean
  withText?: boolean
  text?: string
  icon?: string
  branded?: boolean
  tooltip?: string
  withBackground?: boolean,
  withBorder?: boolean,
  selected?: boolean,
  compact?: boolean,
  large?: boolean,
  fillWidth?: boolean,
  textAlign?: TextAlign,
  disabled?: boolean,
  style?: StyleProp<ViewStyle>,
  selectedStyle?: StyleProp<ViewStyle> | (() => {}),
  onPress?: (event: GestureResponderEvent) => void;
};

export const Button = React.forwardRef((props: ButtonProps, ref: any) => {
  const {
    visible = true,
    withText = true,
    text = "",
    icon = "",
    branded = false,
    tooltip = "",
    withBackground = true,
    withBorder = false,
    selected = false,
    compact = false,
    large = false,
    fillWidth = false,
    style,
    selectedStyle,
    textAlign = 'flex-start',
    disabled = false,
    onPress = () => { },
    ...otherProps
  } = props;

  const theme = useTheme();

  return (
    <View
      ref={ref}
      style={[
        styles.group,
        !!fillWidth && styles.fillWidth,
        !!withBackground && { backgroundColor: theme.primaryBackgroundColor },
        !!withBorder && { borderColor: theme.primaryBorderColor, borderWidth: 1 },
        style
      ]}>
      {
        <Hoverable style={[!!fillWidth && styles.fillWidth]}>
          {({ hovered }) => (
            <Pressable
              onPress={onPress}
              disabled={disabled}
              style={[
                styles.button,
                !!fillWidth && styles.fillWidth,
                !!compact && styles.compact,
                !!large && styles.large,
                !!branded && { backgroundColor: theme.brandBackgroundColor },
                !!hovered && !branded && { backgroundColor: theme.primaryBackgroundHoverColor },
                // !!focused && !branded && styles.buttonFocused,
                !!selected && !branded && { backgroundColor: theme.primaryBackgroundHoverColor },
                !!selected && !branded && styles.buttonFocused,
                !!selected && selectedStyle,
                !!disabled && { backgroundColor: theme.primaryBorderColor }
              ]}
            >
              <View
                accessible={true}
                accessibilityRole="button"
                style={[
                  styles.buttonInner,
                  !!fillWidth && styles.fillWidth,
                  { justifyContent: textAlign }
                ]}
              >
                {!!icon && <Icon
                  style={[
                    styles.buttonIcon,
                    !!withText && !!text && styles.buttonIconText,
                  ]}
                  name={icon} size={18} color={theme.primaryColor} />
                }

                {!!withText && !!text &&
                  <Text style={[
                    { color: theme.primaryColor },
                    branded && { color: theme.brandColor },
                    !!large && styles.largeText,
                    !!disabled && { color: theme.secondaryColor }
                  ]}>{text}</Text>
                }
              </View>
            </Pressable>
          )}
        </Hoverable>
      }
    </View>
  )
});

const styles = StyleSheet.create({
  group: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 1,
    height: '100%',
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",

    overflow: "hidden"

  },

  fillWidth: {
    flex: 1,
    width: '100%'
  },

  button: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 9,
    marginRight: 2,
    height: "90%",
    borderRadius: 2,
    flex: 1
  },

  compact: {
    paddingHorizontal: 5,
  },

  large: {
    paddingVertical: 10,
  },

  buttonInner: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
  },

  largeText: {
    fontSize: 16,
  },

  buttonIcon: {

  },
  buttonIconText: {
    marginRight: 8 / 1.5
  },
  buttonHovered: {
    backgroundColor: '#F0F3FA'
  },
  buttonFocused: {

  },
})