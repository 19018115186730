import React, { useRef, useState } from 'react';
import { StyleSheet, View, Text, LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { useTheme } from '../themes/theme';
import { VisualisationContainer, VisualisationContainerProps } from "./visualisation-container";
import { ToolbarButton } from "./toolbar-button";
import { formatDateTime } from '@energynow/core';
import { Button } from './button';
import { MenuButton } from './menu-button';
import { MenuItem } from './menu-item';
import { Loading } from './loading';
import { NoData } from './no-data';

export interface VisualisationProps extends VisualisationContainerProps {
  allowFullscreen?: boolean,
  utcTime?: Date,
  marketTimezone?: string,
  loading?: boolean,
  refreshing?: boolean,
  noData?: boolean,
  onDownload?: () => void,
  onSettings?: () => void,
  onShare?: () => void,
  onLayout?: (layout: LayoutRectangle) => void,
  onTimezone?: (timezone: string, description: string) => void,
  dateFilters?: React.ReactNode,
  filters?: React.ReactNode
};

export const Visualisation = React.forwardRef((props: VisualisationProps, ref) => {
  const {
    allowFullscreen = true,
    loading = false,
    refreshing = false,
    noData = false,
    onDownload,
    onSettings,
    onShare,
    onLayout,
    onTimezone,
    children,
    dateFilters,
    filters,
    utcTime,
    marketTimezone = "+10:00",
    ...otherProps
  } = props;

  const theme = useTheme();
  const [showPopover, setShowPopover] = useState(false);
  const [timezone, setTimezone] = useState(marketTimezone);
  const [timezoneName, setTimezoneName] = useState("Market");
  const menu = useRef() as any;

  const hasTopLeft = filters ? true : false;
  const hasTopRight = allowFullscreen || onDownload || onSettings || onShare;
  const hasBottomLeft = dateFilters ? true : false;
  const hasBottomRight = utcTime ? true : false;

  const updateTimezone = (timezone: string, description: string) => {
    setTimezone(timezone);
    setTimezoneName(description);
    menu.current.hide();
    if (onTimezone) {
      onTimezone(timezone, description);
    }
  };

  const onBodyLayout = (event: LayoutChangeEvent) => {
    if (onLayout) {
      onLayout(event.nativeEvent.layout);
    }
  };

  const renderTopRight = () => {
    if (hasTopRight) {
      return (
        <View style={styles.topRight}>
          {onDownload && <Button icon='export' compact={true}></Button>}
          {onShare && <Button icon='sharealt' compact={true} onPress={onShare}></Button>}
          {/* {allowFullscreen && <Button icon='arrowsalt' compact={true} onPress={onDownload}></Button>} */}
        </View>
      );
    }

    return null;
  }

  let topRight = renderTopRight();

  return (
    <VisualisationContainer
      onBodyLayout={onBodyLayout}
      {...otherProps}
      topLeft={hasTopLeft ? filters : undefined}
      topRight={topRight}
      bottomLeft={hasBottomLeft && dateFilters}
      bottomRight={
        <View style={styles.row}>
          {!loading && !!refreshing && <Loading small={true} />}
          {hasBottomRight && !noData && utcTime && (
            <MenuButton ref={menu} text={`${formatDateTime(utcTime, "dd/MM/yyyy HH:mm", timezone)} (${timezoneName})`} position={"top"}>
              <MenuItem text={"Market Time"} onPress={() => updateTimezone(marketTimezone, "Market")}></MenuItem>
              <MenuItem text={"UTC"} onPress={() => updateTimezone("+00:00", "UTC")}></MenuItem>
              <MenuItem text={"Local Time"} onPress={() => updateTimezone("", "Local")}></MenuItem>
            </MenuButton>
          )}
        </View>
      }

    >
      {!!loading && <Loading />}
      {!!noData && <NoData />}
      {!loading && !noData && children}
    </VisualisationContainer>
  )
});

const styles = StyleSheet.create({
  menu: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: 2,
    minWidth: 250,
    maxWidth: 340,
    paddingVertical: 6,
    shadowColor: '#0003',
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 }
  },

  topRight: {
    flexDirection: 'row'
  },

  row: {
    flexDirection: 'row'
  }
});
