import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, Modal, PanResponder, StyleSheet, View, TouchableWithoutFeedback, useWindowDimensions } from 'react-native';
import { ThemeProvider, useTheme } from '../themes/theme';
import { Button } from './button';

export type ModalSlideProps = {
  visible?: boolean,
  children?: React.ReactNode,
  text?: string,
  slide?: boolean,
  dismissButton?: boolean,
  onDismiss?: () => void,
};

export const ModalSlide = React.forwardRef((props: ModalSlideProps, ref) => {
  const {
    visible = true,
    children,
    text,
    slide = true,
    dismissButton = false,
    onDismiss = () => { },
    ...otherProps
  } = props;

  const theme = useTheme();

  const { height } = useWindowDimensions();
  const panY = useRef(new Animated.Value(height)).current;

  const resetPositionAnim = Animated.timing(panY, {
    toValue: 0,
    duration: 300,
    useNativeDriver: true,
  });

  const closeAnim = Animated.timing(panY, {
    toValue: height,
    duration: 500,
    useNativeDriver: true,
  });

  const translateY = panY.interpolate({
    inputRange: [-1, 0, 1],
    outputRange: [0, 0, 1],
  });

  const handleDismiss = () => closeAnim.start(props.onDismiss);

  useEffect(() => {
    resetPositionAnim.start();
  }, [resetPositionAnim]);

  const panResponders = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => false,
      onPanResponderMove: Animated.event([null, { dy: panY }], {
        useNativeDriver: false,
      }),
      onPanResponderRelease: (_, gs) => {
        if (gs.dy > 0 && gs.vy > 2) {
          return handleDismiss();
        }
        return resetPositionAnim.start();
      },
    }),
  ).current;

  return (
    <Modal
      animated
      animationType="fade"
      visible={props.visible}
      transparent
      onRequestClose={handleDismiss}>
      <TouchableWithoutFeedback onPress={handleDismiss}>
        <View style={[
          styles.overlay,
          { backgroundColor: theme.overlayBackgroundColor }
        ]}>
          <Animated.View
            style={[
              styles.container,
              { maxHeight: height > 700 ? 700 : height - 200 },
              { transform: [{ translateY: translateY }] },
              { backgroundColor: theme.popoverBackgroundColor }
            ]}
            {...(slide ? panResponders.panHandlers : undefined)}
          >
            {!!slide &&
              <View style={styles.sliderIndicatorRow}>
                <View style={[
                  styles.sliderIndicator,
                  { backgroundColor: theme.primaryBorderColor }
                ]} />
              </View>
            }
            {!slide &&
              <View style={styles.closeRow}>
                <Button icon="close" withText={false} onPress={handleDismiss} />
              </View>
            }
            {props.children}
          </Animated.View>
          {!!dismissButton &&
            <View>
              <Button large={true} fillWidth={true} withBorder={true} textAlign={'center'} text='Done' onPress={() => { handleDismiss() }} />
            </View>
          }
        </View>

      </TouchableWithoutFeedback>
    </Modal>
  );
});

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  container: {
    backgroundColor: '#ffffff',
    paddingTop: 12,
    paddingHorizontal: 12,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    minHeight: 200,
    maxHeight: 800
  },
  sliderIndicatorRow: {
    flexDirection: 'row',
    marginBottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderIndicator: {
    height: 4,
    width: 45,
  },
  closeRow: {
    flexDirection: 'row',
    marginBottom: -12,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
})
