// Copied from https://github.com/WrathChaos/react-native-bouncy-checkbox
import * as React from "react";
import {
    Text,
    View,
    Image,
    Easing,
    Animated,
    StyleProp,
    ViewStyle,
    TextStyle,
    TouchableOpacity,
    ImageSourcePropType,
    TouchableWithoutFeedbackProps,
} from "react-native";
import styles, { _textStyle, _iconContainer } from "./bouncy-checkbox-style";

type CustomStyleProp = StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
type CustomTextStyleProp = StyleProp<TextStyle> | Array<StyleProp<TextStyle>>;
type BaseTouchableProps = Pick<
    TouchableWithoutFeedbackProps,
    Exclude<keyof TouchableWithoutFeedbackProps, "onPress">
>;

export interface IBouncyCheckboxProps extends BaseTouchableProps {
    size?: number;
    text?: string;
    iconStyle?: any;
    fillColor?: string;
    isChecked?: boolean;
    unfillColor?: string;
    unfillBorderColor?: string;
    disableText?: boolean;
    ImageComponent?: any;
    bounceEffect?: number;
    bounceFriction?: number;
    useNativeDriver?: boolean;
    disableBuiltInState?: boolean;
    TouchableComponent?: any;
    iconComponent?: React.ReactNode;
    textComponent?: React.ReactNode;
    style?: StyleProp<ViewStyle>;
    textStyle?: CustomTextStyleProp;
    iconImageStyle?: CustomStyleProp;
    textContainerStyle?: CustomStyleProp;
    checkIconImageSource?: ImageSourcePropType;
    onPress?: (checked: boolean) => void;
}

interface IState {
    checked: boolean;
    springValue: Animated.Value;
}

const defaultCheckImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkCQsPHRvlRfSJAAABcUlEQVRo3u2Wuy4FURSG/4nKrVEIoRalgii1GoU3UGi9gUIkJ5n2iE7jOSTEC5xKMaEUUUiEAqHxKUhOzmXua++t2H87s9b3rT3J7CXFxMQETuITxpb2taoPXek0efY8KQkp/Tyy7hvfZTCvbPrEnzCaFzZC4gGeWJ5wj1dXBzkPpzUVbvrfPITFw2dYPGQu8d1SPByGxfeYDInPWIj4iI/4/4UnYYU1Zp3hb5gvarLHPQBfnDPnH58OvHzLkl98Z6TgrpqCzbfvjC2qoGAz/U5uYYmCCV7iuqC4QMEIL/Fe2CBHwQwv8VbSZIyCIV7isrTRkIIpXmKb7zoKxnhJ4qhCwz8FB3hJ4riagsMbL+dnNKxw5mD6WqfgZnpDhbbrRksFi22nhYLVstVQwXLXa6BgvWrWVHCx6dZQyFg0x9dQcIWvqOASX0HBNb5EwQe+QMEXPkfBJ36Mgm+8NLAvNL3vWyvsckGPlJkg+JiYGHf5Abz2pvBsy0kqAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTA5LTExVDE1OjI5OjI3KzAwOjAwWMpYYAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0wOS0xMVQxNToyOToyNyswMDowMCmX4NwAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC";

class BouncyCheckbox extends React.Component<IBouncyCheckboxProps, IState> {
    constructor(props: IBouncyCheckboxProps) {
        super(props);
        this.state = {
            checked: false,
            springValue: new Animated.Value(1),
        };
    }

    componentDidMount() {
        this.setState({ checked: this.props.isChecked || false });
    }

    onPress = () => {
        const {
            disableBuiltInState = false,
            useNativeDriver = true,
            bounceEffect = 1,
            bounceFriction = 3,
        } = this.props;
        const { checked, springValue } = this.state;
        if (!disableBuiltInState) {
            this.setState({ checked: !checked }, () => {
                springValue.setValue(0.7);
                Animated.spring(springValue, {
                    toValue: bounceEffect,
                    friction: bounceFriction,
                    useNativeDriver,
                }).start();
                this.props.onPress && this.props.onPress(this.state.checked);
            });
        } else {
            springValue.setValue(0.7);
            Animated.spring(springValue, {
                toValue: bounceEffect,
                friction: bounceFriction,
                useNativeDriver,
            }).start();
            this.props.onPress && this.props.onPress(this.state.checked);
        }
    };

    renderCheckIcon = () => {
        const { checked, springValue } = this.state;
        const {
            size = 25,
            iconStyle,
            iconComponent,
            iconImageStyle,
            fillColor = "#ffc484",
            ImageComponent = Image,
            unfillColor = "transparent",
            unfillBorderColor = fillColor,
            disableBuiltInState,
            isChecked,
            checkIconImageSource = defaultCheckImage,
        } = this.props;

        const checkStatus = disableBuiltInState ? isChecked! : checked;
        return (
            <Animated.View
                style={[
                    { transform: [{ scale: springValue }] },
                    _iconContainer(size, checkStatus, fillColor, unfillColor, unfillBorderColor),
                    iconStyle,
                ]}
            >
                {iconComponent ||
                    (checkStatus && (
                        <ImageComponent
                            source={checkIconImageSource}
                            style={[styles.iconImageStyle, iconImageStyle]}
                        />
                    ))}
            </Animated.View>
        );
    };

    renderCheckboxText = () => {
        const {
            text,
            textComponent,
            isChecked,
            textStyle,
            textContainerStyle,
            disableBuiltInState,
            disableText = false,
        } = this.props;
        const { checked } = this.state;
        return (
            !disableText &&
            (textComponent || (
                <View style={[styles.textContainer, textContainerStyle]}>
                    <Text
                        style={[
                            _textStyle(disableBuiltInState ? isChecked! : checked),
                            textStyle,
                        ]}
                    >
                        {text}
                    </Text>
                </View>
            ))
        );
    };

    render() {
        const { style, TouchableComponent = TouchableOpacity } = this.props;
        return (
            <TouchableComponent
                {...this.props}
                style={[styles.container, style]}
                onPress={this.onPress.bind(this, Easing.bounce)}
            >
                {this.renderCheckIcon()}
                {this.renderCheckboxText()}
            </TouchableComponent>
        );
    }
}

export default BouncyCheckbox;