import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyleSheet, ScrollView, Dimensions, TouchableWithoutFeedback } from 'react-native'
import { hexToRgb } from '@energynow/core'
import { ThemeProvider, useTheme } from '../../themes/theme'
import * as d3 from 'd3'
import Svg, { G, Path, Polyline } from 'react-native-svg'
import { addMinutes } from 'date-fns';
import {
  useMax,
  useMin,
  useScaleBand,
  useScaleLinear,
  useScaleTime,
  Area,
  Axis,
  Overlay,
  formatNumber
} from '../../common/chart'
import { Rect, Text } from '../svg/';

export type BarPlotProps = {
  width: number
  height: number
  data: any[]
  valueField: string
  leftLabelField: string
  rightLabelField: string
  colorMapping?: (d: any) => string
  onPress?: (d: any) => void
}

export const BarPlot = React.forwardRef(
  (props: BarPlotProps, ref) => {
    const {
      width,
      height,
      data = [],
      valueField,
      leftLabelField,
      rightLabelField,
      colorMapping,
      onPress,
      ...otherProps
    } = props

    const theme = useTheme()
    const svgRef = useRef() as any
    const [opacities, setOpacities] = useState(data.map(() => 1));

    const color = [theme.nsw1Color, theme.qld1Color, theme.vic1Color, theme.sa1Color, theme.tas1Color, 'orange'];
    
    const actualxMin = Math.min(...data.map(x => x[valueField]));
    const actualxMax = Math.max(...data.map(x => x[valueField]));

    const axisHeight = height * 0.1;
    const defaultAxisWidth = width * 0.7;

    const xMin = 0;
    const xMax = Math.ceil(useMax(data, valueField)/100) * 100;

    const rectHeight = 20;
    const rectPadding = 8;
    const rectAreaHeight = Math.max(height - axisHeight, data.length * (rectHeight + rectPadding));

    const axisPosition = {
      x: Math.max(120, (width - defaultAxisWidth) / 2),
      y: rectAreaHeight,
    };
    const axisWidth = width - 2 * axisPosition.x;

    const xScale = useMemo(() => d3.scaleLinear()
      .domain([xMin, xMax])
      .range([0, axisWidth]), [data, width]);

    let topRectPadding = 4;

    //if the number of bars is less than height, center bars
    if (data.length * (rectHeight + rectPadding) < rectAreaHeight) {
      topRectPadding = (rectAreaHeight - data.length * (rectHeight + rectPadding))/2;
    }

    const getElement = (d: any, index: number) => {
      let rgbColor = hexToRgb(colorMapping ? colorMapping(d) : '#69b3a2');
      return (
        <G key={index}>
          <Rect
            key={index}
            x={xScale(0) + axisPosition.x}
            y={topRectPadding + (rectHeight + rectPadding) * index}
            width={xScale(d[valueField])}
            height={rectHeight}
            fill={`rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${opacities[index]})`}
            onMouseOver={() => { 
              if (onPress) {
                const newOpacities = [ ...opacities ];
                newOpacities[index] = 0.7;
                setOpacities(newOpacities);
              }
            }}
            onMouseOut={() => { 
              if (onPress) {
                const newOpacities = [ ...opacities ];
                newOpacities[index] = 1;
                setOpacities(newOpacities);
              }
            }}
            onPress={() => { if (onPress) onPress(d) }}
          />
          <Text
            key={index}
            dx={xScale(0) + axisPosition.x - 10}
            dy={topRectPadding + (rectHeight + rectPadding) * index + rectHeight / 2}
            alignmentBaseline='middle'
            fontSize={12}
            textAnchor='end'
          >
            { d[leftLabelField] }
          </Text>
          <Text
            key={index}
            dx={axisPosition.x + xScale(d[valueField]) + 10}
            dy={topRectPadding + (rectHeight + rectPadding) * index + rectHeight / 2}
            alignmentBaseline='middle'
            fontSize={12}
          >
            { d[rightLabelField] }
          </Text>
        </G>
      )
    }

    return (
      <ScrollView> 
        <Svg width={width} height={rectAreaHeight + axisHeight}>
          <G>
            <G>
              <Axis
                orient='bottom'
                scale={xScale}
                transform={`translate(${axisPosition.x}, ${axisPosition.y})`}
                ticks={width / 100}
                domain={false}
                tickFormat={formatNumber}
                tickSize={height} 
              />
            </G>
            <G>
              { data.map((d, index) => getElement(d, index)) }
            </G>
          </G>
        </Svg>
      </ScrollView>
    )
  },
)

const styles = StyleSheet.create({})
