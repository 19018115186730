import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, GestureResponderEvent, ScrollView, Button, EventEmitter } from 'react-native';
import { ThemeProvider, useTheme } from '../themes/theme';
import { Text } from './text';
import Icon from 'react-native-vector-icons/AntDesign';
import { Pressable } from './pressable';

export type HorizontalScrollProps = {
  children?: React.ReactNode[],
};

export const HorizontalScroll = React.forwardRef((props: HorizontalScrollProps, ref) => {
  const {
    children,
    ...otherProps
  } = props;

  const theme = useTheme();
  const currIndex = useRef(0);
  const [width, setWidth] = React.useState(0);
  const [scrollWidth, setScrollWidth] = React.useState(0);
  const [hovered, setHovered] = React.useState(false);
  const leftButtonRef = useRef() as any;
  const rightButtonRef = useRef() as any;
  const scroll: any = useRef();

  const scrollLeft = () => {
    if (currIndex.current === 0) {
      setHovered(false);
      return;
    }

    currIndex.current -= 1;
    scroll?.current?.scrollTo({ x: (width / 3) * currIndex.current });
  }

  const scrollRight = () => {
    if (Math.ceil(width + scroll?.current?.scrollLeft) < scroll?.current?.scrollWidth) {
      currIndex.current += 1;
    } else {
      setHovered(false);
    }

    const newWidth = (width / 3) * (currIndex.current + 1);

    if (newWidth >= scroll?.current?.scrollWidth) {
      scroll?.current?.scrollToEnd();
    } else {
      scroll?.current?.scrollTo({ x: newWidth });
    }
  }

  const onHoverOut = (event: MouseEvent) => {
    if (!leftButtonRef.current?.contains(event.relatedTarget) && !rightButtonRef.current?.contains(event.relatedTarget)) {
      setHovered(false);
    }
  };

  return (
    <View
      style={styles.container}
      onLayout={e => {
        setWidth(e.nativeEvent.layout.width);
      }}>
      {!!hovered && currIndex.current !== 0 &&
        <Pressable
          ref={leftButtonRef}
          style={[
            styles.leftScrollButton,
            { backgroundColor: theme.primaryBackgroundHoverColor }
          ]}
          onPress={scrollLeft}
          onHoverIn={(event) => setHovered(true)}
          onHoverOut={onHoverOut}
        >
          <Icon name="left" size={12} color={theme.primaryColor} />
        </Pressable>
      }
      {!!hovered && (Math.ceil(width + scroll?.current?.scrollLeft) < scroll?.current?.scrollWidth) &&
        <Pressable
          ref={rightButtonRef}
          style={[
            styles.rightScrollButton,
            { backgroundColor: theme.primaryBackgroundHoverColor }
          ]}
          onPress={scrollRight}
          onHoverIn={(event) => setHovered(true)}
          onHoverOut={onHoverOut}
        >
          <Icon name="right" size={12} color={theme.primaryColor} />
        </Pressable>
      }
      <Pressable
        onHoverIn={(event) => setHovered(true)}
        onHoverOut={onHoverOut}
      >
        <ScrollView
          contentContainerStyle={{ flex: 1 }}
          scrollEnabled={true}
          horizontal={true}
          ref={scroll}
          onLayout={e => {
            setScrollWidth(e.nativeEvent.layout.width);
          }}
        >
          {children}
        </ScrollView>
      </Pressable>
    </View>
  )
});

const styles = StyleSheet.create({
  container: {
    position: 'relative'
  },
  leftScrollButton: {
    position: 'absolute',
    flexDirection: 'column',
    flex: 1,
    top: 6,
    bottom: 0,
    left: 4,
    width: 20,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
    opacity: 0.9
  },
  rightScrollButton: {
    position: 'absolute',
    flexDirection: 'column',
    flex: 1,
    top: 6,
    bottom: 0,
    right: 4,
    width: 20,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
    opacity: 0.9
  },
  smallScreen: {
    paddingLeft: 12
  }
})