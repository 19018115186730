import { RANGE_DISPATCH_SCADA_QUERY, DUDETAIL_SUMMARIES, parseDate, parseDateCustom, formatDateTime, getDispatchHub, DISPATCH_HUB_LATEST_DISPATCH, hexToRgb, formatDateTimeUrl, getCurrentDispatchInterval } from '@energynow/core';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { View, Dimensions, LayoutRectangle, Text, useWindowDimensions, Animated, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { useTheme } from '../../themes/theme';
import { useQuery } from '../../../hooks/apollo';
import { Share } from '../../../utils/share';
import { Select } from '../../common/select';
import { Visualisation } from '../../common/visualisation';
import { PERIODS, REGIONS } from '../constants';
import { PieChart } from '../../common/chart/pie-chart';
import { BarPlot } from '../../common/chart/bar-plot';
import { Loading } from '../../common/loading';
import { ErrorLoading } from '../../common/error-loading';
import { useHubToListen } from '../../../hooks/signalr';
import { addDays } from 'date-fns';
// @ts-ignore
import DatePicker from '../../common/datepicker';
import { DEVICE_WIDTH } from '../../common/constants';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedDate } from '../../layout/layout-slice';

export type GenerationSplitProps = {
  region?: string,
  groupBy?: string,
  dateTime?: Date,
  route?: any
};

export function GenerationSplit(props: GenerationSplitProps) {
  const params = props.route?.params;
  const initialDate = params?.date ? params.date : null;
  const { loading, error, data, refetch } = useQuery(RANGE_DISPATCH_SCADA_QUERY, {
    variables: {
      fromDate: initialDate,
      toDate: initialDate,
      period: null
    },
    notifyOnNetworkStatusChange: true
  });

  const { loading: duLoading, error: duError, data: duData, refetch: duRefetch } = useQuery(DUDETAIL_SUMMARIES);

  const theme = useTheme();
  const globalSelectedDate = useAppSelector(selectSelectedDate);
  const [selectedDate, setSelectedDate] = useState<Date | null>(initialDate);
  const [selectedRegion, setSelectedRegion] = useState(params?.region || 'ALL');
  const [selectedGroupBy, setSelectedGroupBy] = useState(params?.groupBy || props.groupBy || 'Fuel');
  const [selectedPeriod, setSelectedPeriod] = useState(initialDate ? '' : 'Live');
  const [timezone, setTimezone] = useState('+10:00');
  const [filteredData, setFilteredData] = useState([]);
  const scaleAnim = useRef(new Animated.Value(1)).current
  const scaleNewPieAnim = useRef(new Animated.Value(0)).current;
  const translateXAnim = useRef(new Animated.Value(0)).current
  const translateYAnim = useRef(new Animated.Value(0)).current
  const periods = [
    { label: 'Live' }
  ];
  const [selectedData, setSelectedData] = useState<any>(null);
  const [visualisationRectangle, setVisualisationRectangle] = useState<LayoutRectangle>({ width: 0, height: 0, x: 0, y: 0 });
  //const { x, y, height, width } = ;
  const onPeriodChange = (value: any) => {
    setSelectedPeriod(value);
  }

  const onGroupByChange = (value: any) => {
    setSelectedGroupBy(value);
  }

  const latestDispatch = useHubToListen(getDispatchHub(), DISPATCH_HUB_LATEST_DISPATCH);

  useEffect(() => {
    let toDate: Date | null = null;
    let fromDate: Date | null = null;
    let period: string | null = null;
    if (selectedPeriod !== '') {
      if (selectedPeriod === '1D') {
        period = 'P1D';
      } else if (selectedPeriod === '3D') {
        period = 'P3D';
      } else if (selectedPeriod === 'Live') {
        fromDate = null;
        toDate = null;
        period = null;
      }
    } else {
      toDate = selectedDate as Date;
      fromDate = selectedDate as Date;
    }
    refetch({
      fromDate: fromDate?.toISOString() as any || null,
      toDate: toDate?.toISOString() as any || null,
      period: period as any
    })
  }, [selectedPeriod, selectedDate]);

  useEffect(() => {
    if (selectedPeriod === 'Live') {
      refetch({
        fromDate: null,
        toDate: null,
        period: null
      });
    }
  }, [latestDispatch]);

  useEffect(() => {
    if (globalSelectedDate) {
      onDatePicked(globalSelectedDate);
    } else if (globalSelectedDate === null) {
      setSelectedPeriod('Live');
    }
  }, [globalSelectedDate]);

  const onDatePicked = (date: string) => {
    if (timezone != '') {
      setSelectedDate(parseDateCustom(`${date} ${timezone}`, 'yyyy/MM/dd HH:mm XXX'));
    } else {
      setSelectedDate(parseDateCustom(date, 'yyyy/MM/dd HH:mm'));
    }
    setSelectedPeriod('');
  };

  const regions = [{ label: 'NEM', value: 'ALL' }, ...REGIONS];

  const { height, scale, width } = useWindowDimensions();

  const groupBy = [{ label: 'Fuel', value: 'Fuel' }, { label: 'Participant', value: 'Participant' }];

  useEffect(() => {
    if (selectedData) {
      let scaleAmount = 0.25;
      let { width, height } = visualisationRectangle;
      Animated.timing(scaleAnim,
        {
          toValue: 0.25,
          duration: 0,
          useNativeDriver: false
        }).start();
      Animated.timing(translateXAnim,
        {
          toValue: -width / 2 + width * scaleAmount / 2,
          duration: 0,
          useNativeDriver: false
        }).start();
      Animated.timing(translateYAnim,
        {
          toValue: -height / 2 + height * scaleAmount / 2,
          duration: 0,
          useNativeDriver: false
        }).start();
      Animated.timing(scaleNewPieAnim,
        {
          toValue: 1,
          duration: 0,
          useNativeDriver: false
        }).start();
    }
  }, [visualisationRectangle.height, visualisationRectangle.width]);

  let intermediateResults = useMemo(() => processRawData(data, duData, loading, duLoading, selectedPeriod), [data, duData, loading, duLoading, selectedPeriod]);

  let finalResults = useMemo(() => processData(intermediateResults, selectedRegion, selectedGroupBy), [intermediateResults, selectedRegion, selectedGroupBy]);

  const fuelColorMapping = (d: any) => {
    switch (d.fuelType) {
      case 'Brown coal':
        return theme.brownCoalColor;
      case 'Black coal':
        return theme.blackCoalColor;
      case 'Coal':
        return theme.coalColor;
      case 'Coal':
        return theme.coalColor;
      case 'Gas':
        return theme.gasColor;
      case 'Hydro':
        return theme.hydroColor;
      case 'Wind':
        return theme.windColor;
      case 'Battery':
        return theme.batteryColor;
      case 'Solar':
        return theme.solarColor;
      case 'Biofuel':
        return theme.biofuelColor;
      case 'Diesel':
        return theme.dieselColor;
      case 'Liquid fuel':
        return theme.liquidFuelColor;
      default:
        return theme.unknownColor;
    }
  }

  if (!!error || !!duError) {
    return <ErrorLoading onPressReload={() => { if (!!error) refetch(); if (!!duError) duRefetch(); }} />
  }

  //let finalResults = processData(data, loading, selectedRegion, selectedGroupBy);

  let onPressElement = (d: any) => {
    if (d.participantId !== 'OTHERS') {
      setSelectedData(d);
      processChild(d, selectedPeriod);
      let scaleAmount = 0.25;
      let { width, height } = visualisationRectangle;
      Animated.timing(scaleAnim,
        {
          toValue: 0.25,
          duration: 500,
          useNativeDriver: false
        }).start();
      Animated.timing(translateXAnim,
        {
          toValue: -width / 2 + width * scaleAmount / 2,
          duration: 500,
          useNativeDriver: false
        }).start();
      Animated.timing(translateYAnim,
        {
          toValue: -height / 2 + height * scaleAmount / 2,
          duration: 500,
          useNativeDriver: false
        }).start();
      Animated.timing(scaleNewPieAnim,
        {
          toValue: 1,
          duration: 500,
          useNativeDriver: false
        }).start();
    }
  }

  let onBackPress = () => {
    Animated.timing(scaleAnim,
      {
        toValue: 1,
        duration: 500,
        useNativeDriver: false
      }).start();
    Animated.timing(translateXAnim,
      {
        toValue: 0,
        duration: 500,
        useNativeDriver: false
      }).start();
    Animated.timing(translateYAnim,
      {
        toValue: 0,
        duration: 500,
        useNativeDriver: false
      }).start();
    Animated.timing(scaleNewPieAnim,
      {
        toValue: 0,
        duration: 0,
        useNativeDriver: false
      }).start();
    setSelectedData(null);
  }

  let toRGBColor = hexToRgb(theme.primaryBackgroundColor);
  const noData = data?.dispatchKeys?.length === 0;

  return (
    <Visualisation
      allowFullscreen={true}
      aspectRatio={4 / 3}
      border={true}
      utcTime={noData ? undefined : data?.dispatchKeys[0].settlementDate}
      loading={((!!loading || !!duLoading) && finalResults.length < 1 && !data?.dispatchKeys)}
      noData={noData}
      refreshing={(!!loading || !!duLoading)}
      onShare={() => { Share('Generation split', `https://www.energydash.com.au/visualisation/generation-split/${formatDateTimeUrl(data?.dispatchKeys[0].settlementDate)}/${selectedGroupBy}/${selectedRegion}`) }}
      onLayout={(layout) => setVisualisationRectangle(layout)}
      onTimezone={(timezone, description) => setTimezone(timezone)}
      filters={
        <Select
          multiple={false}
          compact={true}
          options={regions}
          onChange={(value) => setSelectedRegion(value)}
          value={selectedRegion}
        ></Select>
      }
      dateFilters={
        <View style={{ flexDirection: 'row', alignContent: 'flex-start' }}>
          <Select
            multiple={false}
            options={PERIODS}
            optionValue='label'
            responsive={false}
            compact={true}
            onChange={(value) => setSelectedPeriod(value)}
            value={selectedPeriod}
            style={{ width: 66 }}
          ></Select>
          <DatePicker style={{ minWidth: 40 }} selected={selectedDate} maximumDate={formatDateTime(latestDispatch?.settlementDate ? parseDate(latestDispatch.settlementDate) : new Date(), 'yyyy/MM/dd HH:mm')} active={selectedPeriod === ''} onSelectedChange={onDatePicked} />
        </View>
      }
    >
      <Animated.View style={[{
        transform: [{ translateX: translateXAnim }, { translateY: translateYAnim }, { scale: scaleAnim }]
      }, { flex: 1 }]}
      >
        {selectedGroupBy === 'Participant' &&
          <React.Fragment>
            <Text style={[styles.title, { color: theme.primaryColor }]}>
              Generation by participant
            </Text>
            <PieChart
              data={finalResults}
              width={visualisationRectangle.width}
              height={Math.min(visualisationRectangle.height - 26, visualisationRectangle.width + 26)}
              labelField='label'
              valueField='generation'
              valueFieldLabel={selectedPeriod !== '1D' ? 'MW' : 'MWh'}
              onPress={onPressElement}
            />
          </React.Fragment>
        }
        {
          selectedGroupBy !== 'Participant' &&
          <React.Fragment>
            <Text style={[styles.title, { color: theme.primaryColor }]}>
              Generation by fuel type
            </Text>
            <PieChart
              data={finalResults}
              width={visualisationRectangle.width}
              height={Math.min(visualisationRectangle.height - 26, visualisationRectangle.width + 26)}
              labelField='label'
              valueField='generation'
              valueFieldLabel={selectedPeriod !== '1D' ? 'MW' : 'MWh'}
              colorMapping={fuelColorMapping}
              onPress={onPressElement}
            />
          </React.Fragment>
        }
      </Animated.View>
      {selectedData &&
        <React.Fragment>
          <TouchableWithoutFeedback onPress={onBackPress}>
            <Animated.View style={[styles.gradient, { backgroundColor: `rgba(${toRGBColor.r}, ${toRGBColor.g}, ${toRGBColor.b}, 0.7)` }, { transform: [{ scale: scaleNewPieAnim }] }]}>
              <Text style={[styles.title, { color: theme.primaryColor }]}>{`${selectedGroupBy === 'Participant' ? selectedData.participantId : selectedData.fuelType} generation`}</Text>
              <BarPlot
                data={selectedData.children}
                width={visualisationRectangle.width}
                height={visualisationRectangle.height - 27}
                leftLabelField='label'
                rightLabelField='otherLabel'
                valueField='generation'
                colorMapping={fuelColorMapping}
              />
            </Animated.View>
          </TouchableWithoutFeedback>
        </React.Fragment>
      }
    </Visualisation>
  )
}

function processRawData(data: any, duData: any, loading: boolean, duLoading: boolean, selectedPeriod: string): any[] {
  if (loading || duLoading || !duData || !data || !data.dispatchKeys[0])
    return [];

  let duidDict: { [duid: string]: any } = {};
  for (let duDetailSummary of duData.duDetailSummaries) {
    let fuelType = findFuelType(duDetailSummary) ?? 'Other';
    duidDict[duDetailSummary.duid] = { ...duDetailSummary, fuelType };
  }

  let resultsDict: { [duid: string]: any } = {};
  for (let interval of data.dispatchKeys) {
    for (let dispatchUnitScada of interval.dispatchUnitScada) {
      let { duid, scadaValue } = dispatchUnitScada;
      let adjustedScadaValue = selectedPeriod !== '1D' ? scadaValue : scadaValue / 12;

      let resultsDictEntry = resultsDict[duid];

      if (!resultsDictEntry) {
        let duidDictEntry = duidDict[duid];
        if (duidDictEntry && duidDictEntry.dispatchType !== 'LOAD') {
          let { participantId, fuelType, regionId } = duidDict[duid];
          resultsDict[duid] = { duid, generation: adjustedScadaValue, participantId, regionId, fuelType };
        }
      } else {
        resultsDict[duid].generation += adjustedScadaValue;
      }
    }
  }

  let results = [];
  for (let entry in resultsDict) {
    results.push(resultsDict[entry]);
  }

  return results;
}

// sets up scada data to be grouped by participantid
function processData(data: any[], regionFilter: string, groupByFilter: string): any[] {
  if (data.length == 0)
    return [];

  if (groupByFilter === 'Participant') {
    return processParticipantData(data, regionFilter);
  } else {
    return processFuelData(data, regionFilter);
  }
}

let styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 20,
  },
  gradient: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  }
});

function processParticipantData(data: any, regionFilter: string) {
  let filteredData;
  if (regionFilter === 'ALL') {
    filteredData = data;
  } else {
    filteredData = data.filter((x: any) => x.regionId === regionFilter);
  }

  let processedData: any = {};
  for (let row of filteredData) {
    if (!!row.participantId) {
      let dictEntry = processedData[row.participantId];
      if (dictEntry && row.generation > 0) {
        dictEntry.children.push(row);
        dictEntry.generation += row.generation;
      } else if (row.generation > 0) {
        processedData[row.participantId] = { generation: row.generation, children: [row] };
      }
    }
  }

  let results: any[] = [];
  for (let participantId in processedData) {
    results.push({ participantId: participantId, children: processedData[participantId].children, generation: processedData[participantId].generation });
  }

  results = results.sort((a, b) => b.generation - a.generation);

  let finalResults = [];
  let otherResult: any = { participantId: 'OTHERS', generation: 0, children: [] };
  for (let [index, result] of results.entries()) {
    if (index < 10) {
      finalResults.push(result);
    } else {
      otherResult.children.push(result);
      otherResult.generation += result.generation
    }
  }
  finalResults.push(otherResult);

  let allMw = finalResults.reduce((i, x) => i + x.generation, 0)
  for (let result of finalResults) {
    result.percentage = result.generation / allMw * 100;
    result.label = result.participantId;
  }

  return finalResults;
}

function processFuelData(data: any, regionFilter: string) {
  let filteredData;
  if (regionFilter === 'ALL') {
    filteredData = data;
  } else {
    filteredData = data.filter((x: any) => x.regionId === regionFilter);
  }

  let processedData: any = {};
  for (let row of filteredData) {
    let { generation, fuelType } = row;
    let dictEntry = processedData[fuelType];
    if (dictEntry && generation > 0) {
      dictEntry.children.push(row);
      dictEntry.generation += generation;
    } else if (row.generation > 0) {
      processedData[fuelType] = { fuelType: fuelType, generation: generation, children: [row] };
    }
  }

  let results: any[] = [];
  for (let fuelType in processedData) {
    results.push({ fuelType: fuelType, children: processedData[fuelType].children, generation: processedData[fuelType].generation });
  }

  results = results.sort((a, b) => b.generation - a.generation);

  let finalResults = results;

  let allMw = finalResults.reduce((i, x) => i + x.generation, 0)
  for (let result of finalResults) {
    result.percentage = result.generation / allMw * 100;
    result.label = `${result.fuelType}`
  }

  return finalResults;
}

function processChild(data: any, selectedPeriod: string) {
  let children = data.children;
  if (children.length > 0 && !children[0].label) {
    let allMw = children.reduce((i: number, x: any) => i + x.generation, 0);
    let newChildren = [];
    for (let child of children) {
      let newChild = { ...child };
      newChild.percentage = child.generation / allMw * 100;
      newChild.label = `${child.duid} - ${newChild.fuelType}`
      newChild.otherLabel = `${Math.round(child.generation)}MW${selectedPeriod !== '1D' ? '' : 'h'} (${Math.round(newChild.percentage)}%)`
      newChildren.push(newChild);
    }
    data.children = newChildren.sort((a, b) => b.generation - a.generation);
  }
}

function findFuelType(duDetailSummary: any) {
  if (duDetailSummary?.genUnit?.length > 0) {
    let fuelTypes: string[] = [...new Set(duDetailSummary.genUnit.map((x: any) => x.fuelType))] as string[];
    let key: string;
    if (fuelTypes.length > 1) {
      return 'Mixed';
    } else {
      return fuelTypes[0];
    }
  }

  return null;
}
