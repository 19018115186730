import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native';
import { useKeycloak } from '../../hooks/keycloak';
import { useAppSelector } from '../../store/hooks';
import { selectProfile } from './profile-slice';
import { Text } from '../common/text';
import { Checkbox } from '../common/checkbox';
/*
email: "jjdikha@gmail.com"
emailVerified: true
firstName: "James"
id: "119dabc5-844e-45c3-9396-b1b541db1f97"
lastName: "Dikha"
userProfileMetadata: {attributes: Array(4)}
username: "jjdikha@gmail.com"
*/
export function Account(props: any) {
  const { keycloak } = useKeycloak();
  const profile = useAppSelector(selectProfile);
  keycloak
  if (!!keycloak?.authenticated) {
    return (
      <View style={styles.container}>
        <View style={styles.formRowLabel}>
          <View >
            <Text h1={true} fontWeight="400">Account</Text>
          </View>
        </View>
        <View style={styles.formRowLabel}>
          <Text>Username</Text>
        </View>
        <View style={styles.formRow}>
          <Text h2={true}>{profile.username}</Text>
        </View>
        <View style={styles.formRowLabel}>
          <Text>Name</Text>
        </View>
        <View style={styles.formRow}>
          <Text h2={true}>{profile.firstName} {profile.lastName}</Text>
        </View>
        <View style={styles.formRowLabel}>
          <Text>Email</Text>
        </View>
        <View style={styles.formRow}>
          <Text h2={true}>{profile.email}</Text>
        </View>
      </View>
    )
  }

  return (
    <View></View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    width: "100%",
    paddingleft: 12,
    paddingBottom: 2
  },

  formRow: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 6
  },

  formRowLabel: {
    flexDirection: "row",
    flex: 1,
    paddingVertical: 6,
    marginleft: 12,
  },

  input: {

  },
});
