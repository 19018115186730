import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyleSheet, View, ViewProps, Text, TouchableWithoutFeedback, GestureResponderEvent, Pressable } from 'react-native'
import { Hoverable, } from 'react-native-web-hover'
import Icon from 'react-native-vector-icons/AntDesign';
import { ThemeProvider, useTheme } from '../themes/theme';

export type ToolbarButtonProps = {
  visible?: boolean
  withText?: boolean
  first?: boolean
  last?: boolean
  fill?: boolean
  spacer?: boolean
  text?: string
  icon?: string
  branded?: boolean
  tooltip?: string
  withBackground?: boolean
  selected?: boolean
  notice?: boolean
  onPress?: (event: GestureResponderEvent) => void;
};

export const ToolbarButton = React.forwardRef((props: ToolbarButtonProps, ref: any) => {
  const {
    visible = true,
    withText = true,
    first = false,
    last = false,
    fill = false,
    spacer = false,
    text = "",
    icon = "",
    branded = false,
    tooltip = "",
    withBackground = true,
    selected = false,
    notice = false,
    onPress = () => { },
    ...otherProps
  } = props;

  const theme = useTheme();

  return (
    <View
      ref={ref}
      style={[
        styles.group,
        !!first && styles.first,
        !!spacer && styles.spacer,
        !!withBackground && { backgroundColor: theme.primaryBackgroundColor }
      ]}>
      {!spacer &&
        <Hoverable>
          {({ hovered }) => (
            <Pressable
              onPress={onPress}
              style={[
                !fill && styles.button,
                !!fill && styles.fillButton,
                !!hovered && !branded && { backgroundColor: theme.primaryBackgroundHoverColor },
                // !!focused && !branded && styles.buttonFocused,
                !!selected && !branded && { backgroundColor: theme.primaryBackgroundHoverColor },
                !!selected && !branded && styles.buttonFocused,
                !!branded && { backgroundColor: theme.brandBackgroundColor },
              ]}
            >

              <View
                accessible={true}
                accessibilityRole="button"
                style={[
                  styles.buttonInner,
                ]}
              >
                {!!icon && <Icon
                  style={[
                    styles.buttonIcon,
                    !!withText && !!text && styles.buttonIconText
                  ]}
                  name={icon} size={18} color={theme.primaryColor} />
                }

                {!!withText && !!text &&
                  <Text style={[
                    { color: theme.primaryColor },
                    branded && { color: theme.brandColor },
                  ]}>{text}</Text>
                }
              </View>
              {!!notice && <View style={[styles.buttonNotice, { backgroundColor: theme.errorBorderColor }]}>
              </View>}
            </Pressable>
          )}

        </Hoverable>
      }
    </View>
  )
});

const styles = StyleSheet.create({
  group: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 1,
    height: '100%',
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 8 * 4.5,
    overflow: "hidden"

  },
  button: {
    position: 'relative',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 9,
    margin: 2,
    borderRadius: 2
  },
  fillButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 9,
    margin: 2,
    borderRadius: 0
  },
  buttonInner: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14
  },

  buttonNotice: {
    borderRadius: 10,
    position: 'absolute',
    top: 6,
    right: 6,
    width: 10,
    height: 10
  },

  buttonIcon: {

  },
  buttonIconText: {
    marginRight: 8 / 1.5
  },
  buttonHovered: {
    backgroundColor: '#F0F3FA'
  },
  buttonFocused: {
    margin: 0,
    borderRadius: 0,
    paddingVertical: 8,
    paddingHorizontal: 11,
  },
  first: {
    marginLeft: 0
  },
  spacer: {
    flex: 1
  }
})