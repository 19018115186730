import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, ViewProps, Image, TouchableOpacity, Linking } from 'react-native';
import { Text } from '../common/text';
import { ToolbarButton } from '../common/toolbar-button';
import { MenuButton, PopoverPosition } from '../common/menu-button';
import { MenuItem } from '../common/menu-item';
import { Login } from '../login';
import { Profile } from '../profile';
import { Switch } from '../common/switch';
import { ThemeProvider, useTheme } from '../themes/theme';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { selectTheme, setTheme, setThemeAsync } from '../themes/theme-slice';
import { SettingsModal } from '../modals/settings-modal';
import { Modal } from '../common/modal';
import { Checkbox } from '../common/checkbox';
import { Panel, selectRightPanel, setRightPanel, selectSettings, setSettings, setSelectedDate } from './layout-slice';
import { selectNotices, setNotices } from '../visualisations/user-notice/user-notice-slice';
import { useKeycloak } from '../../hooks/keycloak';
import { setSignIn } from '../profile/profile-slice';
import nfiniteLogo from '@energynow/components/assets/nfinite_logo.png';
import { EnergyDashIcon } from '../common/icons/energydash';
import { useQuery } from '../../hooks/apollo';
import { apolloClientAuth, GET_NOTICES, parseDateCustom } from '@energynow/core';
// @ts-ignore
import DatePicker from '../common/datepicker';

export type LayoutTopProps = {
  visible?: boolean
  withBorder?: boolean
};

export function LayoutTop(props: LayoutTopProps) {
  const {
    visible = true,
    withBorder,
    ...otherProps
  } = props;

  const theme = useTheme();
  const rightPanel = useAppSelector(selectRightPanel);
  const notices = useAppSelector(selectNotices);
  const settings = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();

  const { loading, error, data, refetch } = useQuery(GET_NOTICES, {
    client: apolloClientAuth
  });

  useEffect(() => {
    if (data) {
      dispatch(setNotices(data.userNotices));
    }
  }, [data]);

  const AuthDelegate = (callback: any) => {
    if (!keycloak?.authenticated) {
      dispatch(setSignIn(true));
      return;
    }

    callback();
  }

  return (
    <View style={styles.layoutTop}>
      {/* <ToolbarButton first={true} icon="linechart" text="Dashboard" /> */}
      <View style={[styles.logoContainer, { backgroundColor: theme.primaryBackgroundColor }]}>
        <View style={[styles.logo]}>
          <EnergyDashIcon></EnergyDashIcon>
        </View>
      </View>
      <ToolbarButton spacer={true} />
      <DatePicker style={{ minWidth: 40 }} onSelectedChange={(date: string) => dispatch(setSelectedDate(date))} onLivePress={() => dispatch(setSelectedDate(null))} />
      <ToolbarButton icon="notification" onPress={() => dispatch(setRightPanel(rightPanel === Panel.marketNotices ? undefined : Panel.marketNotices))} />
      <ToolbarButton icon="bells" notice={notices.length > 0 || !keycloak?.authenticated} onPress={() => AuthDelegate(() => dispatch(setRightPanel(rightPanel === Panel.notifications ? undefined : Panel.notifications)))} />
      <Login />
    </View>
  )
}

const styles = StyleSheet.create({
  layoutTop: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 42,
    height: 8 * 4.5
  },

  logoContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    height: 36,
  },

  logo: {
    flexDirection: 'row',
    width: 90,
    height: 16,
    marginHorizontal: 10,
    marginBottom: 6
  }
})
