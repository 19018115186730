import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, ViewProps, Text, ScrollView, useWindowDimensions } from 'react-native';
import { ToolbarButton } from '../common/toolbar-button';
import { ThemeProvider, useTheme } from '../themes/theme';
import { ReactNativeGridLayout } from '@nfinite-au/react-native-grid-layout';
import { LayoutCenterLeft } from './center-left';
import { LatestDispatch } from '../visualisations/dispatch/latest-dispatch';
import { RangeDispatch } from '../visualisations/dispatch/range-dispatch';
import { FuelMixRange } from '../visualisations/fuel-mix/fuel-mix-range';
import { GenerationSplit } from '../visualisations/dispatch/generation-split';
import { CurrentMarketNotices } from '../visualisations/market-notice/current-market-notices';
import { RegionInterconnectors } from '../visualisations/dispatch/region-interconnectors';
import { Interconnectors } from '../visualisations/dispatch/interconnectors';
import { Visualisation } from '../common/visualisation';
import { LayoutCenterRight } from './center-right';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { Panel, selectRightPanel, setRightPanel } from './layout-slice';
import { DEVICE_WIDTH } from '../common/constants';
import { InterconnectorsMap } from '../visualisations/dispatch/interconnectors-map';

export type LayoutCenterProps = {
  visible?: boolean
  withBorder?: boolean
};

export function LayoutCenter(props: LayoutCenterProps) {
  const {
    visible = true,
    withBorder = false,
    ...otherProps
  } = props;

  const theme = useTheme();
  const { height, scale, width } = useWindowDimensions();
  const rightPanel = useAppSelector(selectRightPanel);
  const dispatch = useAppDispatch();

  const [layouts, setLayouts] = useState({
    lg: [
      {
        w: 12,
        h: 1.5,
        x: 0,
        y: 0,
        i: 'item1',
        z: 1,
      },
      {
        w: 3,
        h: 5,
        x: 0,
        y: 1.5,
        i: 'item2',
        z: 2,
      },
      {
        w: 4.5,
        h: 5,
        x: 3,
        y: 1.5,
        i: 'item3',
        z: 3,
      },
      {
        w: 4.5,
        h: 5,
        x: 7.5,
        y: 1.5,
        i: 'item4',
        z: 4,
      },
      {
        w: 12,
        h: 3.9,
        x: 0,
        y: 6.5,
        i: 'item5',
        z: 4,
      },
      {
        w: 12,
        h: 3.9,
        x: 0,
        y: 10.4,
        i: 'item6',
        z: 5,
      },
    ],
    md: [
      {
        w: 10,
        h: 1.5,
        x: 0,
        y: 0,
        i: 'item1',
        z: 1,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 1.5,
        i: 'item2',
        z: 2,
      },
      {
        w: 3,
        h: 5,
        x: 4,
        y: 1.5,
        i: 'item3',
        z: 3,
      },
      {
        w: 3,
        h: 5,
        x: 7,
        y: 1.5,
        i: 'item4',
        z: 4,
      },
      {
        w: 10,
        h: 5,
        x: 0,
        y: 6.5,
        i: 'item5',
        z: 4,
      },
      {
        w: 10,
        h: 5,
        x: 0,
        y: 11.5,
        i: 'item6',
        z: 5,
      },
    ],
    sm: [
      {
        w: 6,
        h: 1.5,
        x: 0,
        y: 0,
        i: 'item1',
        z: 1,
      },
      {
        w: 3,
        h: 5,
        x: 0,
        y: 1.5,
        i: 'item2',
        z: 2,
      },
      {
        w: 3,
        h: 5,
        x: 3,
        y: 1.5,
        i: 'item3',
        z: 3,
      },
      {
        w: 3,
        h: 5,
        x: 0,
        y: 6.5,
        i: 'item4',
        z: 4,
      },
      {
        w: 3,
        h: 5,
        x: 3,
        y: 6.5,
        i: 'item5',
        z: 5,
      },
      {
        w: 3,
        h: 5,
        x: 0,
        y: 11.5,
        i: 'item6',
        z: 5,
      },
    ],
    xs: [
      {
        w: 4,
        h: 5,
        x: 0,
        y: 0,
        i: 'item1',
        z: 1,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 5,
        i: 'item2',
        z: 2,
      },
      {
        w: 4,
        h: 7,
        x: 0,
        y: 10,
        i: 'item3',
        z: 3,
      },
      {
        w: 4,
        h: 8,
        x: 0,
        y: 17,
        i: 'item4',
        z: 4,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 25,
        i: 'item5',
        z: 5,
      },
      {
        w: 4,
        h: 5,
        x: 0,
        y: 30,
        i: 'item6',
        z: 5,
      },
    ],
  });

  return (
    <View style={styles.layoutCenterContainer}>
      <LayoutCenterLeft visible={false}></LayoutCenterLeft>
      <ScrollView style={[
        styles.layoutCenter,
        { backgroundColor: theme.secondaryBackgroundColor }
      ]}>
        <ReactNativeGridLayout
          layout={layouts}
          cols={12}
          rowHeight={100}
          // responsiveBreakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          onLayoutChange={(layout: any, newLayout: any) => {
            setLayouts(newLayout)
          }}
        >

          <LatestDispatch key={'item1'} />
          <InterconnectorsMap key={'item2'} />
          <GenerationSplit key={'item3'} />
          <GenerationSplit key={'item4'} groupBy="Participant" />
          <RangeDispatch key={'item5'} />
          <FuelMixRange key={'item6'} />
          {/* <GeneratorPie key={'item2'} /> */}
          {/* <CurrentMarketNotices key={'item3'} /> */}
        </ReactNativeGridLayout>
      </ScrollView>
      <LayoutCenterRight width={width < DEVICE_WIDTH.md ? '100%' : 360} withBorder={width >= DEVICE_WIDTH.md} visible={rightPanel !== undefined}></LayoutCenterRight>
    </View>
  )
}

const styles = StyleSheet.create({
  layoutCenterContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1
  },
  layoutCenter: {
    flex: 1,
    backgroundColor: "#ffffff",
    marginTop: 1
  },
  childItem1: {
    flex: 1,
    backgroundColor: 'yellow',
  },
  childItem2: {
    flex: 1,
    backgroundColor: 'green',
  },
  childItem3: {
    flex: 1,
    backgroundColor: 'orange',
  }
})
