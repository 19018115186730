import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { ThemeProvider, useTheme } from '../themes/theme';
import BouncyCheckbox, { IBouncyCheckboxProps } from "./bouncy-checkbox/bouncy-checkbox";
import { Text } from './text';

export interface CheckboxProps extends IBouncyCheckboxProps {
  fillColor?: string
};

export const Checkbox = React.forwardRef((props: CheckboxProps, ref) => {
  const {
    fillColor,
    children,
    ...otherProps
  } = props;

  const theme = useTheme();
  const menu = useRef() as any;

  return (
    <BouncyCheckbox
      size={22}
      fillColor={fillColor ? fillColor : theme.brandBackgroundColor}
      unfillBorderColor={theme.secondaryColor}
      textStyle={{
        textDecorationLine: "none",
        color: theme.primaryColor,
        fontSize: 14,
      }}
      {...otherProps}
    />
  )
});

const styles = StyleSheet.create({
})