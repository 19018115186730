import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle, Pressable, GestureResponderEvent, Text } from 'react-native';
import { ThemeProvider, useTheme } from '../themes/theme';

export type MenuItemProps = {
  visible?: boolean,
  children?: React.ReactNode,
  text?: string,
  onPress?: (event: GestureResponderEvent) => void,
  separator?: boolean
};

export const MenuItem = React.forwardRef((props: MenuItemProps, ref) => {
  const {
    visible = true,
    children,
    text,
    onPress,
    separator = false,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [showPopover, setShowPopover] = useState(false);
  const menu = useRef() as any;

  if (!separator) {
    return (
      <View style={[
        styles.menuItem
      ]}

      >
        <View style={styles.menuItemRow}>
          {!!onPress && <Pressable onPress={onPress} style={[styles.menuItemRowLeft]}>
            <Text style={[{ color: theme.popoverColor }]}>{text}</Text>
          </Pressable>
          }
          {!onPress && <View style={[styles.menuItemRowLeft]}>
            <Text style={[{ color: theme.popoverColor }]}>{text}</Text>
          </View>
          }
          <View style={styles.menuItemRowRight}>{children}</View>
        </View>
      </View>
    )
  } else {
    return (
      <View style={[
        styles.separator,
        { backgroundColor: theme.primaryBorderColor }
      ]}>

      </View>
    )
  }
});

const styles = StyleSheet.create({
  menuItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 6,
    paddingHorizontal: 16
  },

  menuItemRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },

  menuItemRowLeft: {
    flex: 1,
    justifyContent: 'center'
  },

  menuItemRowRight: {
  },

  separator: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexShrink: 0,
    marginVertical: 6,
    minHeight: 1,
    maxHeight: 1
  },
})