import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View, Animated, FlatList } from 'react-native';
import { formatDateTimeString, GET_MARKET_NOTICES_BY_OFFSET, parseDate } from '@energynow/core';
import { SingleMarketNotice } from './single-market-notice';
import { useQuery } from '../../../hooks/apollo';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { Visualisation } from '../../common/visualisation';
import { Select } from '../../common/select';
import { Loading } from '../../common/loading';
import { ErrorLoading } from '../../common/error-loading';
import { useHubToListen } from '../../../hooks/signalr';
import { MARKET_NOTICE_TYPES } from '../constants';

export function CurrentMarketNotices(props: any) {
  const marketNoticeVariables: any = {
    variables: {
      offset: 0,
      number: 15,
      marketNoticeTypes: null
    }
  };
  const marketTimezone = '+10:00';
  const theme = useTheme();

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_MARKET_NOTICES_BY_OFFSET, marketNoticeVariables);
  const [marketNoticeTextHeights, setMarketNoticeTextHeights] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const regions = [
    { value: 'NSW1', label: 'NSW' },
    { value: 'QLD1', label: 'QLD' },
    { value: 'VIC1', label: 'VIC' },
    { value: 'SA1', label: 'SA' },
    { value: 'TAS1', label: 'TAS' }
  ];

  const periods = [
    { label: '1D' },
    { label: '5D' }
  ];

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('5D');
  const [timezone, setTimezone] = useState(marketTimezone);

  useEffect(() => {
    // Refetch data when panel is opened
    if (data) {
      refetch();
    }
  }, []);

  if (!!loading) {
    return <Loading />
  }

  if (!!error) {
    return <ErrorLoading onPressReload={() => { refetch() }} />
  }

  const onTimezone = (timezone: string, description: string) => {
    setTimezone(timezone);
  };

  const processMarketNotice = ({ item }: any) => {
    return <SingleMarketNotice marketNotice={item} timezone={timezone} />
  }

  return (
    <Visualisation
      allowFullscreen={false}
      aspectRatio={4 / 3}
      border={false}
      onTimezone={onTimezone}
      filters={
        <Select
          dropDown={true}
          multiple={true}
          options={MARKET_NOTICE_TYPES}
          onChange={(value) => {
            setSelectedItems(value);
            refetch({
              ...marketNoticeVariables.variables,
              marketNoticeTypes: value.length > 0 ? value : null
            });
          }}
          value={selectedItems}
          maxSelectedLabels={1}
          placeholder='Market notice type'
          title='Market notice type'
        ></Select>
      }
    >

      {!!data?.marketNoticesByOffset && <FlatList
        data={data?.marketNoticesByOffset}
        renderItem={processMarketNotice}
        keyExtractor={marketNotice => marketNotice.noticeId}
        refreshing={true}
        onRefresh={() => refetch()}
        onEndReachedThreshold={1}
        onEndReached={(info: { distanceFromEnd: number }) => {
          fetchMore({
            variables: {
              offset: data?.marketNoticesByOffset?.length,
              number: 10,
              marketNoticeTypes: selectedItems.length > 0 ? selectedItems : null
            }
          });
        }}
      >
      </FlatList>
      }
    </Visualisation>
  )
}

const styles = StyleSheet.create({
  marketNoticeParent: {
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 8,
    borderRadius: 8,
    padding: 8,
    height: 120,
  },
  layoutTop: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8
  },
  title: {
    flex: 1,
    justifyContent: 'center'
  },
  titleText: {
    fontSize: 20,
  },
  date: {
    //justifyContent: 'center'
  },
  dateText: {
  },
  reasonText: {
    fontWeight: '100'
  },
  filterRow: {
    flexDirection: "row",
    flex: 1,
    paddingBottom: 20
  },
});
