import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { Popover } from '../popover';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { PopoverPosition } from '../popover/types';
import * as d3 from 'd3';
import { ScaleBand } from 'd3';
import { isFunction, parseDate, resolveFieldData } from '@energynow/core';
import { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

export type AreaProps = {
  xScale: any,
  yScale: any,
  data: any,
  color?: string,
  disableAnimation?: boolean,
  valueField: ((item: any) => any) | string,
  categoryField: string
};

export const Area = React.forwardRef((props: AreaProps, ref) => {
  const {
    xScale,
    yScale,
    data = [],
    color = "#45B29D",
    disableAnimation = true,
    valueField,
    categoryField,
    ...otherProps
  } = props;

  const theme = useTheme();
  const [showPopover, setShowPopover] = useState(false);
  const svgRef = useRef() as any;


  // React.useEffect(() => {
  //   if (disableAnimation) {
  //     d3.select(svgRef.current).attr("opacity", 1);
  //     return;
  //   }
  //   d3.select(svgRef.current)
  //     .transition()
  //     .duration(300)
  //     .ease(d3.easeBackIn)
  //     .attr("opacity", 1);
  // }, [disableAnimation]);

  const d = React.useMemo(() => {
    const area = d3
      .area()
      .x((item: any) => xScale(resolveFieldData(item, categoryField)))
      .y1((item: any) => yScale(isFunction(valueField) ? (valueField as any)(item) : resolveFieldData(item, valueField)))
      .y0(() => yScale(yScale.domain()[0]));
    return area(data);
  }, [xScale, yScale, data]) as any;

  return (
    <>
      <Path
        ref={svgRef}
        d={d}
        fill={`url(#gradient-${color})`}
        opacity={1}
      />
      <Defs>
        <LinearGradient
          id={`gradient-${color}`}
          x1="0%"
          x2="0%"
          y1="0%"
          y2="100%"
        >
          <Stop offset="0%" stopColor={color} stopOpacity={0.35} />
          <Stop offset="100%" stopColor={color} stopOpacity={0} />
        </LinearGradient>
      </Defs>
    </>
  )
});

const styles = StyleSheet.create({
})