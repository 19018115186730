import React, { useEffect } from 'react';
import { View, StyleSheet, Animated } from 'react-native';

import { Header, Days } from '.';
import { useCalendar } from '../DatePicker';
import { useTheme } from '../../../../themes/theme';
import { Text } from '../../../text';

const Calendar = () => {
  const { options, state, utils, onSelectedChange, show } = useCalendar();

  const [mainState] = state;
  const theme = useTheme();
  const style = styles(theme);
  const [{ shownAnimation }, changeMonthAnimation] = utils.useMonthAnimation(
    mainState.activeDate,
    options.daysAnimationDistance,
  );

  useEffect(() => {
    mainState.selectedDate && onSelectedChange(mainState.selectedDate);
    mainState.selectedDate && show(false);
    mainState.selectedDate = null;
  }, [mainState.selectedDate, onSelectedChange]);

  return (
    <View style={style.container}>
      <Header changeMonth={changeMonthAnimation} />
      <View style={[style.daysName, utils.flexDirection]}>
        {utils.config.dayNamesShort.map(item => (
          <Text key={item}>
            {item}
          </Text>
        ))}
      </View>
      <View style={style.daysContainer}>
        <Animated.View style={[style.days, shownAnimation]}>
          <Days />
        </Animated.View>
      </View>
    </View>
  );
};

const styles = theme =>
  StyleSheet.create({
    container: {
      flexDirection: 'column',
      flex: 1,
    },
    daysName: {
      paddingBottom: 10,
      marginBottom: 0,
      alignItems: 'center',
      justifyContent: 'space-around',
      borderBottomColor: theme.primaryBorderColor,
      borderBottomWidth: 1,
      marginHorizontal: 15,
    },
    daysNameText: {
    },
    daysContainer: {
      margin: 15,
      marginTop: 5,
      marginBottom: 0,
    },
    days: {
      width: '100%',
      top: 0,
      right: 0,
    },
  });

export { Calendar };
