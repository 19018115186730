import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    StyleSheet, View, Dimensions, Switch as SwitchComponent,
    SwitchProps as SwitchPropsOriginal,
    TouchableWithoutFeedback
} from 'react-native';
import { Popover } from './popover';
import { ThemeProvider, useTheme } from '../themes/theme';
import { PopoverPosition } from './popover/types';
import { Platform } from '../../libs/platform';
import { mutedOpacity } from '../../styles/variables';

export interface SwitchProps extends SwitchPropsOriginal {
};

export const Switch = React.forwardRef((props: SwitchProps, ref) => {
    const {
        onValueChange,
        ...otherProps
    } = props;

    const theme = useTheme();
    const [showPopover, setShowPopover] = useState(false);
    const switchRef = useRef() as any;

    return (
        <TouchableWithoutFeedback>
            <SwitchComponent
                activeThumbColor={theme.brandColor}
                data-switch
                data-switch-disabled={!!props.disabled}
                onValueChange={onValueChange}
                style={[
                    Platform.OS === 'android' && {
                        opacity: otherProps.disabled ? mutedOpacity : 1,
                    },
                ]}
                thumbColor={theme.popoverColor}
                trackColor={{
                    false: theme.primaryBorderColor,
                    true: theme.brandBackgroundColor,
                }}
                {...otherProps}
            />
        </TouchableWithoutFeedback>
    )
});

const styles = StyleSheet.create({
})