import React, { useState, useEffect } from 'react'
import {
  View,
  Text
} from 'react-native';

import { useKeycloak } from '../../hooks/keycloak';
import { useAppSelector } from '../../store/hooks';
import { MenuItem } from '../common/menu-item';
import { selectProfile } from './profile-slice';

export function Profile(props: any) {
  const { keycloak } = useKeycloak();
  const profile = useAppSelector(selectProfile);

  if (!!keycloak?.authenticated) {
    return (
      <MenuItem text={profile.username}></MenuItem>
    )
  }

  return (
    <View></View>
  )
}
