import { equals } from '@energynow/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions, useWindowDimensions, Animated } from 'react-native';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { Button } from '../button';
import { DEVICE_WIDTH } from '../constants';
import { Modal, ModalProps } from '../modal';
import { SettingsMenuItem } from './settings-menu-item';

export interface SettingsPanelProps extends ModalProps {
    children?: React.ReactNode,
    menuItemValue: any,
    menuItems?: Array<any>,
    onMenuItemChange: (value: any) => void;
};

export const SettingsPanel = React.forwardRef((props: SettingsPanelProps, ref) => {
    const {
        children,
        menuItems,
        menuItemValue,
        onMenuItemChange,
        ...otherProps
    } = props;

    const theme = useTheme();
    const menu = useRef() as any;
    const { width } = useWindowDimensions();
    const [showMenu, setShowMenu] = useState(true);
    const [smallScreen, setSmallScreen] = useState(false);
    const animatedValue = useRef(new Animated.Value(width)).current;

    const animateView = (toValue: number) => {
        Animated.timing(animatedValue, {
            toValue: toValue,
            duration: 300,
            useNativeDriver: false
        }).start();
    };

    const onMenuItemClick = (menuItem: any) => {
        if (onMenuItemChange) {
            onMenuItemChange(menuItem);
            setShowMenu(false);
        }
    };

    const onBackPress = () => {
        // Slide out
        Animated.timing(animatedValue, {
            toValue: width,
            duration: 300,
            useNativeDriver: false
        }).start(() => {
            setShowMenu(true);
        });
    }

    const isSelected = (menuItem: any) => {
        return equals(menuItemValue.text, menuItem.text, undefined);
    }


    const renderMenuItems = () => {
        if (menuItems && menuItems.length) {
            return menuItems.map((menuItem, index) => {
                return <SettingsMenuItem key={`${menuItem.text}_${index}`} text={menuItem.text} selected={isSelected(menuItem)} smallScreen={smallScreen} onPress={() => onMenuItemClick(menuItem)} ></SettingsMenuItem>
            });
        }

        return null;
    }

    let items = renderMenuItems();

    useEffect(() => {
        setSmallScreen(width < DEVICE_WIDTH.md);
    }, [width]);

    useEffect(() => {
        animateView(0);
    }, [menuItemValue]);

    useEffect(() => {
        animateView(width);
    }, []);

    return (
        <Modal title={!smallScreen || showMenu ? "Settings" : menuItemValue.text} {...otherProps} header={
            !!smallScreen && !showMenu && (
                <View>
                    <Button icon='arrowleft' withText={false} onPress={() => onBackPress()} />
                </View>
            )
        }
            animationType="fade"
        >
            <View style={styles.container}>
                {(!smallScreen || !!showMenu) &&
                    (<View style={[
                        styles.menuItems,
                        smallScreen && styles.menuItemsFill
                    ]}>
                        {items}
                    </View>)
                }
                {(!smallScreen) &&
                    (<View style={styles.selection}>
                        {menuItemValue.children}
                    </View>)
                }
                {(!!smallScreen && !showMenu) &&
                    (<Animated.View
                        style={[
                            styles.selectionSmallScreen,
                            {
                                transform: [
                                    {
                                        translateX: animatedValue
                                    },
                                ],
                            },
                        ]}

                    >
                        {menuItemValue.children}
                    </Animated.View>)
                }
            </View>
        </Modal>
    )
});

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        marginTop: 6,
        // height: '100%',
        //backgroundColor: "transparent",
        //flex: 1,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        //backgroundColor: "#000000",
        overflow: "hidden"

    },

    menuItems: {
        width: 200,
        flexDirection: "column",
        // height: '100%',
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignSelf: "flex-start",
    },

    menuItemsFill: {
        width: '100%'
    },

    selection: {
        flexDirection: "column",
        marginTop: 6,
        marginLeft: 40,
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flex: 1,
        width: "100%"
    },

    selectionSmallScreen: {
        flexDirection: "column",
        marginTop: 6,
        paddingHorizontal: 12,
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flex: 1,
        width: "100%"
    }
})