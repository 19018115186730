import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import themeReducer from '../components/themes/theme-slice';
import settingsReducer from '../components/modals/settings-slice';
import layoutReducer from '../components/layout/layout-slice';
import userNoticeReducer from '../components/visualisations/user-notice/user-notice-slice';
import profileReducer from '../components/profile/profile-slice';

export const store = configureStore({
    reducer: {
        theme: themeReducer,
        settings: settingsReducer,
        layout: layoutReducer,
        notices: userNoticeReducer,
        profile: profileReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;