import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View, Animated } from 'react-native';
import { apolloClientAuth, formatDateTimeString, GET_NOTICES, parseDate } from '@energynow/core';
import { SingleUserNotice } from './single-user-notice';
import { useQuery } from '../../../hooks/apollo';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { Visualisation } from '../../common/visualisation';
import { Select } from '../../common/select';
import { Loading } from '../../common/loading';
import { ErrorLoading } from '../../common/error-loading';
import { useHubToListen } from '../../../hooks/signalr';
import { MARKET_NOTICE_TYPES } from '../constants';
import { Button } from '../../common/button';
import { style } from 'd3';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { setNotices, selectNotices, deleteNoticeAsync } from './user-notice-slice';
import { NotAuthenticated } from '../../common/not-authenticated';
import { useKeycloak } from '../../../hooks/keycloak';

export function UserNotices(props: any) {
  const marketTimezone = '+10:00';
  const theme = useTheme();

  const { loading, error, data, refetch } = useQuery(GET_NOTICES, {
    client: apolloClientAuth
  });

  const [timezone, setTimezone] = useState(marketTimezone);

  const notices = useAppSelector(selectNotices);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setNotices(data.userNotices));
    }
  }, [data]);

  useEffect(() => {
    // Refetch data when panel is opened
    if (data) {
      refetch();
    }
  }, []);

  if (!!loading) {
    return <Loading />
  }

  if (!!error) {
    return <ErrorLoading onPressReload={() => { refetch() }} />
  }

  const onTimezone = (timezone: string, description: string) => {
    setTimezone(timezone);
  };

  const processUserNotice = (userNotice: any, index: number) => {
    return <SingleUserNotice userNotice={userNotice} timezone={timezone} key={index} />
  }

  return (
    <Visualisation
      allowFullscreen={false}
      aspectRatio={4 / 3}
      border={false}
      onTimezone={onTimezone}
      filters={
        notices?.length > 0 && (<View style={styles.filterRow}>
          <Button icon='bells' text='Clear All' onPress={() => dispatch(deleteNoticeAsync())} />
        </View>)
      }
    >
      <ScrollView style={styles.notices}>
        {notices?.length === 0 && (<View style={styles.placeHolder}><Text>No notifications</Text></View>)}
        {notices?.map((x: any, index: number) => processUserNotice(x, index))}
      </ScrollView>
    </Visualisation>
  )
}

const styles = StyleSheet.create({
  notices: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1
  },
  filterRow: {
    alignItems: 'flex-end',
    flex: 1,
  },
  placeHolder: {
    alignItems: 'center'
  }
});
