import React from 'react';
import { StyleSheet } from 'react-native';
import { useTheme } from '../../themes/theme';

export function Text(props: any) {
  const {
    style,
    ...otherProps
  } = props;

  const theme = useTheme();
  return <text
    fill={theme.primaryColor}
    fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
    {...props} />
}