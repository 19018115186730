import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { Popover } from '../popover';
import { ThemeProvider, useTheme } from '../../themes/theme';
import { PopoverPosition } from '../popover/types';
import * as d3 from 'd3';
import { ScaleBand } from 'd3';
import { isFunction, parseDate, resolveFieldData } from '@energynow/core';
import { G, Line, Path, Rect, Text } from 'react-native-svg';
import { CursorPosition } from './overlay/cursor';
import { formatDateTime } from './utils';

export type CursorProps = {
  xScale: any,
  yScale: any,
  data: any,
  valueField: ((item: any) => any) | string,
  categoryField: string,
  cursorPosition?: CursorPosition,
  height: number,
  width: number,
  axisTooltipWidth?: number,
  axisTooltipHeight?: number,
  useXTick?: boolean,
  xTickFormatter: ((d: any) => string),
  yTickFormatter: ((d: any) => string),
};

export const Cursor = React.forwardRef((props: CursorProps, ref) => {
  const {
    xScale,
    yScale,
    data = [],
    valueField,
    categoryField,
    cursorPosition,
    height,
    width,
    axisTooltipWidth = 80,
    axisTooltipHeight = 30,
    useXTick = true,
    xTickFormatter,
    yTickFormatter
  } = props;

  const theme = useTheme();
  const svgRef = useRef() as any;

  const [xData, setXData] = useState<any>();
  const [xPosition, setXPosition] = useState<number>();
  const [yData, setYData] = useState<any>();
  const [yPosition, setYPosition] = useState<number>();

  //var x = d3.time.scale().range([0, width]);

  useEffect(() => {
    if (cursorPosition?.x && xScale.invert) {
      const tempXData = xScale.invert(cursorPosition.x);

      if (tempXData && tempXData.toString() !== 'Invalid Date') {
        const bisectDate = d3.bisector((item) => resolveFieldData(item, categoryField)).left;
        const i = bisectDate(data, tempXData, 1);
        const d0 = data[i - 1];
        const d1 = data[i];
        const d = tempXData - resolveFieldData(d0, categoryField) > resolveFieldData(d1, categoryField) - tempXData ? d1 : d0;
        setXData(resolveFieldData(d, categoryField));
      }
      setXPosition(xScale(xData));
    } else {
      setXPosition(0);
    }

    if (cursorPosition?.y && yScale.invert) {
      setYData(Math.max(yScale.invert(cursorPosition?.y)));
      setYPosition(yScale(yData));
    } else if (cursorPosition?.y) {
      setYData(yScale.domain()[Math.round((cursorPosition.y / yScale.step()))]);
      setYPosition(yScale(yData));
    } else {
      setYPosition(0);
    }

  }, [cursorPosition, xScale])

  if (xPosition && yPosition && xData?.toString() !== 'Invalid Date') {
    return (
      <G>
        {!!theme.cursorLineVisible && <Line
          ref={svgRef}
          x1={xPosition}
          x2={xPosition}
          y1={0}
          y2={height}
          stroke={theme.cursorLineColor}
          strokeWidth={1}
          fill="none"
          opacity={0.4}
        />
        }
        {!!theme.cursorLineVisible && <Line
          ref={svgRef}
          x1={0}
          x2={width}
          y1={yPosition}
          y2={yPosition}
          stroke={theme.cursorLineColor}
          strokeWidth={1}
          fill="none"
          opacity={0.4}
        />
        }
        { useXTick && <G transform={`translate(${(xPosition + (axisTooltipWidth / 2)) > width ? width - (axisTooltipWidth / 2) :
          (xPosition - (axisTooltipWidth / 2)) < 0 ? 0 + (axisTooltipWidth / 2) :
            xPosition
          }, ${height - axisTooltipHeight + 1})`}>
          <Rect x={-(axisTooltipWidth / 2)} y={0} height={axisTooltipHeight} width={axisTooltipWidth} fill={theme.cursorLineColor}>
          </Rect>
          <Text
            x={0}
            y={axisTooltipHeight / 1.5}
            textAnchor={"middle"}
            fontSize={14}
            fill={"#ffffff"}
            fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif">{xTickFormatter(xData)}</Text>
        </G>
        }

        <G transform={`translate(0,${yPosition})`}>
          <Rect x={0} y={-(axisTooltipHeight / 2)} height={axisTooltipHeight} width={axisTooltipWidth} fill={theme.cursorLineColor}>
          </Rect>
          <Text
            x={axisTooltipWidth / 2}
            y={axisTooltipHeight / 6}
            textAnchor={"middle"}
            fontSize={14}
            fill={"#ffffff"}
            fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif">{yTickFormatter(yData)}</Text>
        </G>
      </G>
    )
  } else {
    return (<G></G>);
  }

});

const styles = StyleSheet.create({
})
